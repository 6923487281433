import { FC } from "react";
import QRCode from "react-qr-code";

interface IQRCode {
  url: string;
}

const QRCodeImg: FC<IQRCode> = ({ url }) => {
  return (
    <div style={{margin:"10px"}}>
      <QRCode value={url} size={100} />
    </div>
  );
};

export default QRCodeImg;
