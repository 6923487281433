import styled from "styled-components";
import { media } from "../../respnsive";
import { theme } from "../../theme";

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  // gap: 20px;
  width: 100%;

  ${media.tablet} {
    gap: 30px;
  }
  ${media.phone} {
    gap: 20px;
  }
`;

export const SectionTitle = styled.p`
  width: 200px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  background: ${theme.colors.textBackground};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  ${media.phone} {
    font-size: 18px;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 20px 0px;
  // margin-bottom: 20px;

  ${media.tablet} {
    flex-direction: column;
  }
`;

export const SummaryCardContainer = styled.div`
  display: flex;

  justifycontent: flex-end;
  margin: 20px;
  width: 50%;

  ${media.phone} {
    width: 100%;
  }
`;
