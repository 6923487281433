import { useAppSelector } from "../../Redux/app/hooks";
import { getTotalBalanceSeriesByNetwork } from "../../utils/chatSeries";
import { getMyBalanceChartOptions } from "../../utils/data";
import AreaChart from "./AreaChart";
import { useEffect, useState } from "react";
import { getSupportedNetworks } from "../../Apis/AnalyticsApiCalls/walletApis";

const NetworkChart = () => {
  const { depositNetworkBalance } = useAppSelector((state) => state.wallet);
  const [networks, serNetworks] = useState();
  const { GroupByPeriod } = useAppSelector((state) => state.form);
  const depositBalanceChartOptions = getMyBalanceChartOptions(
    depositNetworkBalance,
    GroupByPeriod,
    "network",
    "Wallet Deposit (Network)"
  );
  const depositBalanaceChatSeries = getTotalBalanceSeriesByNetwork(
    depositNetworkBalance,
    GroupByPeriod,
    networks
  );

  useEffect(() => {
    getSupportedNetworks(serNetworks, true);
  }, []);

  return (
    <AreaChart
      series={depositBalanaceChatSeries}
      options={depositBalanceChartOptions}
      // title="Wallet Deposit (Network)"
    />
  );
};

export default NetworkChart;
