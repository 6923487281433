import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
/* eslint-disable no-param-reassign */

export interface settlementState {
  settledStalls: any;
  gettingSettledStalls: boolean;
  creatingSettleStalls: boolean;
  switchWalletTransactionReference: string | null;

  // states
  withdrawalIds: string[];
  destinationAddress: string;
  merchantReference: string;
  singleWithdrawalId: string;
  errorMessage: string;
}

const initialState: settlementState = {
  settledStalls: null,
  gettingSettledStalls: false,
  creatingSettleStalls: false,
  switchWalletTransactionReference: null,

  // states
  withdrawalIds: [],
  destinationAddress: "",
  merchantReference: "",
  singleWithdrawalId: "",
  errorMessage: "",
};

export const settlementSlice = createSlice({
  name: "settlement",
  initialState,
  reducers: {
    setGettingSettleStalls: (state, action) => {
      state.gettingSettledStalls = action.payload;
    },
    setCreatingSettleStalls: (state, action) => {
      state.creatingSettleStalls = action.payload;
    },
    setSettledStalls: (state, action) => {
      state.settledStalls = action.payload;
    },
    AddWithdrawalID: (state, action) => {
      const olArray = state.withdrawalIds;
      const findOut = olArray.find((x: any) => x === action.payload);
      if (!findOut) {
        state.errorMessage = "";
        state.withdrawalIds = [...olArray, action.payload];
      } else {
        state.errorMessage = "Id Already Exists";
      }
    },
    getSettlementsFormDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
  },
});

export const {
  setGettingSettleStalls,
  setSettledStalls,
  getSettlementsFormDetails,
  AddWithdrawalID,
  setCreatingSettleStalls,
} = settlementSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectToggle = (state: RootState) => state.settlement;

export default settlementSlice.reducer;
