import moment from "moment";
import { copyHandle, roundton, shortenAddress } from "../Helpers/generals";
import { numberWithCommaswithoutdecimals } from "./../Helpers/generals";
import { currencyList } from "./currencyList";
import {
  ActionButton,
  Coin,
  CurrencyAndNetwork,
  CurrencyContainer,
  CurrencyText,
  NetworkText,
} from "../components/Tables/styles";
import { handleModal } from "../Redux/Reducers/navReducer";
import { getFormDetails } from "../Redux/Reducers/formReducer";
import { InitiateDeposit } from "./../Apis/transactions";
import { currencies } from "./filterSelect";

export const getDepositTransactionRows = (transactionMetrics: any) => {
  const depositRows = transactionMetrics?.deposits?.currencies?.map(
    (row: any) => {
      return {
        network: row?.currency ? row?.networkChain : "N/A",
        currency: row.currency ? row.currency : "N/A",
        volume: row.volume
          ? numberWithCommaswithoutdecimals(parseInt(row.volume))
          : "N/A",
        count: row.count ? numberWithCommaswithoutdecimals(row.count) : "N/A",
      };
    }
  );
  return depositRows;
};
export const getWithdrawalsTransactionRows = (transactionMetrics: any) => {
  const depositRows = transactionMetrics?.withdrawals?.currencies?.map(
    (row: any) => {
      return {
        network: row?.networkChain ? row?.networkChain : "N/A",
        currency: row.currency ? row.currency : "N/A",
        volume: row.volume
          ? numberWithCommaswithoutdecimals(parseInt(row.volume))
          : "N/A",
        count: row.count ? numberWithCommaswithoutdecimals(row.count) : "N/A",
      };
    }
  );
  return depositRows;
};
export const getRemittanceFeeMetricsRows = (remittanceFeeMetrics: any) => {
  const depositRows = remittanceFeeMetrics?.fees?.map((row: any) => {
    return {
      network: row?.networkChain ? row?.networkChain : "N/A",
      highestGasFee: row?.highest ? row?.highest : "N/A",
      lowestGasFee: row?.lowest ? row?.lowest : "N/A",
      totalFees: row?.total ? row?.total : "N/A",
      averageGasFee: row?.average ? row?.average : "N/A",
    };
  });
  return depositRows;
};

export const getRecentTransactionRows = (recentTransactions: any) => {
  const result = recentTransactions?.records?.map((transc: any) => {
    return {
      from: (
        <>
          {shortenAddress(transc?.fromAddress)}{" "}
          <img
            src="/assets/icons/Table/copy-icon-blue.svg"
            alt="copy"
            onClick={() => copyHandle(transc.fromAddress)}
            style={{ cursor: "pointer" }}
          />
        </>
      ),
      to: (
        <>
          {shortenAddress(transc?.toAddress)}{" "}
          <img
            src="/assets/icons/Table/copy-icon-blue.svg"
            alt="copy"
            onClick={() => copyHandle(transc.toAddress)}
            style={{ cursor: "pointer" }}
          />
        </>
      ),
      amount: transc?.amount ? (
        <CurrencyContainer style={{ gap: "10px" }}>
          {currencyList?.map((item, index) => (
            <>
              {transc?.currency === item?.name && (
                <Coin
                  key={index}
                  src={item?.Symbol}
                  alt={item?.name}
                  style={{ width: "20px" }}
                />
              )}
            </>
          ))}
          <CurrencyAndNetwork style={{ gap: "5px" }}>
            <CurrencyText style={{ fontSize: "14px" }}>
              {transc?.currency}
            </CurrencyText>
            <NetworkText>{roundton(transc?.amount, 5)}</NetworkText>
          </CurrencyAndNetwork>
        </CurrencyContainer>
      ) : (
        "N/A"
      ),
      date: transc?.createdAt ? (
        <CurrencyAndNetwork style={{ gap: "5px" }}>
          <CurrencyText style={{ fontSize: "14px" }}>
            {moment(transc?.createdAt).format("MMM Do YYYY")}
          </CurrencyText>
          <NetworkText>{moment(transc?.createdAt).format("LT")}</NetworkText>
        </CurrencyAndNetwork>
      ) : (
        "N/A"
      ),
      time: transc?.transactionTimeStamp ? (
        <p style={{ width: "100px" }}>
          {moment(transc?.transactionTimeStamp).format("LT")}
        </p>
      ) : (
        "N/A"
      ),
      transactionType:
        transc?.transactionType === "Deposit" ? (
          <img src="/assets/icons/Table/credit.svg" width="20px" />
        ) : (
          <img src="/assets/icons/Table/debit.svg" width="20px" />
        ),
    };
  });
  return result;
};

export const getWalletBalanceRows = (
  WalletBalances: any,
  dispatch: any,
  currencies: any,
  networks: any
) => {
  const result = WalletBalances?.balances?.map((bal: any) => {
    return {
      currency: (
        <CurrencyContainer>
          {currencyList?.map((item, index) => (
            <>
              {bal?.currency === item?.name && (
                <Coin key={index} src={item?.Symbol} alt={item?.name} />
              )}
            </>
          ))}
          <CurrencyAndNetwork>
            <CurrencyText>{bal?.currency}</CurrencyText>
            <NetworkText>{bal?.network}</NetworkText>
          </CurrencyAndNetwork>
        </CurrencyContainer>
      ),
      total: (
        <CurrencyAndNetwork>
          <CurrencyText>{roundton(bal?.amount, 5)}</CurrencyText>
          <NetworkText>$ {roundton(bal?.amountInUSD, 5)}</NetworkText>
        </CurrencyAndNetwork>
      ),
      network: <CurrencyText>{bal?.network}</CurrencyText>,
      deposit: (
        <ActionButton
          onClick={(e) => {
            e.preventDefault();
            let depositCurrency = 0;
            let depositNetwork = 0;
            dispatch(handleModal(3));
            currencies.map((currency: any) => {
              if (currency.label === bal?.currency) {
                dispatch(
                  getFormDetails({
                    name: "depositCurrency",
                    value: { label: bal?.currency, value: currency?.value },
                  })
                );
                depositCurrency = currency?.value;
              }
            });
            networks.map((network: any) => {
              if (network.label === bal?.network) {
                dispatch(
                  getFormDetails({
                    name: "depositNetwork",
                    value: { label: bal?.network, value: network?.value },
                  })
                );
                depositNetwork = network?.value;
              }
            });
            InitiateDeposit(depositCurrency, depositNetwork);
          }}
        >
          Deposit
        </ActionButton>
      ),
      withdraw: (
        <ActionButton
          onClick={() => {
            dispatch(handleModal(4));
            currencies?.map((currency: any) => {
              if (currency?.label === bal?.currency) {
                dispatch(
                  getFormDetails({
                    name: "withdrawCurrency",
                    value: { label: bal?.currency, value: currency?.value },
                  })
                );
              }
            });
            networks.map((network: any) => {
              if (network?.label === bal?.network) {
                dispatch(
                  getFormDetails({
                    name: "withdrawNetwork",
                    value: { label: bal?.network, value: network?.value },
                  })
                );
              }
            });
          }}
        >
          Withdraw
        </ActionButton>
      ),
    };
  });
  return result;
};
