import {
  colourStyles,
  Form,
  InputContainer,
  SelectBar,
} from "../Generals/LoginStyles";
import CustomModal from "./CustomModal";
import { Label } from "./../Generals/LoginStyles";
import { useAppDispatch, useAppSelector } from "./../../Redux/app/hooks";
import { getFormDetails, sending } from "../../Redux/Reducers/formReducer";
import { InitiateDeposit } from "../../Apis/transactions";
import { copyHandle } from "../../Helpers/generals";
import { Addresscontainer } from "./ModalStyles";
import { DotLoader } from "react-spinners";
import { useEffect, useState } from "react";
import api from "../../Apis/baseURL";
import QRCodeImg from "./../Reusables/Others/QRCodeImg";
import { getSupportedNetworks } from "../../Apis/AnalyticsApiCalls/walletApis";

const DepositModal = () => {
  const dispatch = useAppDispatch();
  const { depositCurrency, depositNetwork } = useAppSelector(
    (state) => state.form
  );
  const { loading } = useAppSelector((state) => state.form);
  const { depositAddress } = useAppSelector((state) => state.user);
  const [currencies, setCurrencies] = useState([{ label: "--", value: "--" }]);
  const [networks, setNetworks] = useState([]);

  const handleSelectChange = async (option: any, name: string) => {
    if (name === "depositNetwork") {
      await api
        .get(
          `v1/merchant/Get/CurrencyByNetwork?networkChain=${option.value}`,
          {}
        )
        .then((res: any) => {
          const result = res?.data?.data.map((item: any) => {
            return {
              label: item?.name,
              value: item?.symbol,
            };
          });
          setCurrencies(result);
        });
    }
    if (option.value === "--") dispatch(getFormDetails({ name }));
    else
      dispatch(
        getFormDetails({
          name,
          value: { label: option?.label, value: option.value },
        })
      );
  };

  useEffect(() => {
    InitiateDeposit(depositCurrency?.value, depositNetwork?.value);
  }, [depositCurrency, depositNetwork]);

  useEffect(() => {
    dispatch(sending(false));
    getSupportedNetworks(setNetworks);
  }, []);

  return (
    <CustomModal
      id={3}
      content={
        <Form>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              //   marginBottom: "30px",
              width: "100%",
            }}
          >
            <InputContainer>
              <Label>Network</Label>
              <SelectBar
                defaultValue={depositNetwork}
                onChange={(data: any) =>
                  handleSelectChange(data, "depositNetwork")
                }
                options={networks}
                styles={colourStyles}
              />
            </InputContainer>
            <InputContainer>
              <Label>Currency</Label>
              <SelectBar
                defaultValue={depositCurrency}
                onChange={(data: any) =>
                  handleSelectChange(data, "depositCurrency")
                }
                options={currencies}
                styles={colourStyles}
              />
            </InputContainer>
          </div>

          {!loading ? (
            <>
              {depositAddress && (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "space-around",
                  }}
                >
                  <span
                    style={{
                      margin: "5% 0",
                      fontWeight: "500",
                      fontSize: "80%",
                    }}
                  >
                    Make your deposit to the address below
                  </span>
                  {<QRCodeImg url={depositAddress} />}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Addresscontainer>{depositAddress}</Addresscontainer>
                    <img
                      src="/assets/icons/Table/copy-icon-blue.svg"
                      alt="copy"
                      onClick={() => copyHandle(depositAddress)}
                      style={{ cursor: "pointer", width: "20%" }}
                    />
                  </div>
                </div>
              )}
            </>
          ) : (
            <DotLoader color="#ff6600" size={20} />
          )}
        </Form>
      }
    />
  );
};

export default DepositModal;
