import { getSupportedNetworks } from "../../Apis/AnalyticsApiCalls/walletApis";
import { useAppSelector } from "../../Redux/app/hooks";
import { getTotalBalanceSeriesByNetwork } from "../../utils/chatSeries";
import { getMyBalanceChartOptions } from "../../utils/data";
import BarChart from "./BarChart";
import { useEffect, useState } from "react";

const RemittanceNetworkChart = () => {
  const { remittanceNetworkTimeMetrics } = useAppSelector(
    (state) => state.analytics
  );
  const [networks, setNetworks] = useState();

  const { GroupByPeriod } = useAppSelector((state) => state.form);
  const remittacneNetworkChartOptions = getMyBalanceChartOptions(
    remittanceNetworkTimeMetrics,
    GroupByPeriod,
    "network",
    "Network"
  );
  const remittacneNetworkChatSeries = getTotalBalanceSeriesByNetwork(
    remittanceNetworkTimeMetrics,
    GroupByPeriod,
    networks
  );

  useEffect(() => {
    getSupportedNetworks(setNetworks, true);
  }, []);

  return (
    <BarChart
      series={remittacneNetworkChatSeries}
      options={remittacneNetworkChartOptions}
      // title="Network"
    />
  );
};

export default RemittanceNetworkChart;
