export const recentTransactionsCols = [
  { label: "", field: "transactionType", sort: "asc" },
  { label: "From", field: "from", sort: "asc" },
  { label: "To", field: "to", sort: "asc" },
  { label: "Amount", field: "amount", sort: "asc" },
  { label: "Date", field: "date", sort: "asc" },
];

export const walletCols = [
  { label: "", field: "transactionType", sort: "asc" },
  { label: "Wallet Address", field: "address", sort: "asc", width: "100px" },
  { label: "Network", field: "network", sort: "asc", width: "100px" },
  { label: "Amount", field: "balance", sort: "asc" },
  { label: "Date", field: "date", sort: "asc", width: "100px" },
  { label: "Status", field: "status", sort: "asc", width: "100px" },
  { label: "Reference", field: "reference", sort: "asc", width: "100px" },
  {
    label: "TransactionHash",
    field: "transactionHash",
    sort: "asc",
    width: "100px",
  },
  { label: "Callback", field: "callback", sort: "asc", width: "100px" },
];
export const walletExportCols = [
  {
    label: "Wallet Address",
    key: "addressToCSV",
  },
  { label: "Network", key: "networkToCSV" },
  { label: "Currency", key: "currencyToCSV" },
  { label: "Amount", key: "balanceToCSV" },
  { label: "Date", key: "dateToCSV" },
  { label: "Status", key: "statusToCSV" },
  { label: "Reference", key: "referenceToCSV" },
  {
    label: "TransactionHash",
    key: "transactionHashToCSV",
  },
  { label: "TransactionType", key: "transactionTypeToCSV" },
];

export const unRemittedCols = [
  { label: "Wallet Address", field: "address", sort: "asc" },
  { label: "Network", field: "network", sort: "asc" },
  { label: "Amount", field: "balance", sort: "asc" },
  { label: "Action", field: "callback", sort: "asc" },
  { label: "", field: "view", sort: "asc" },
];

export const unRemittedExportCols = [
  { label: "Wallet Address", key: "addressToCSV", sort: "asc" },
  { label: "Network", key: "networkToCSV", sort: "asc" },
  { label: "Currency", key: "currencyToCSV", sort: "asc" },
  { label: "Amount", key: "balanceToCSV", sort: "asc" },
];

export const remittanceCols = [
  { label: "Wallet Address", field: "address", sort: "asc", width: "100px" },
  { label: "Network", field: "network", sort: "asc", width: "100px" },
  { label: "Amount", field: "balance", sort: "asc" },
  { label: "Date", field: "date", sort: "asc", width: "100px" },
  { label: "Status", field: "status", sort: "asc", width: "100px" },
  {
    label: "TransactionHash",
    field: "transactionHash",
    sort: "asc",
    width: "100px",
  },
  { label: "Enquiry", field: "equiry", sort: "asc", width: "100px" },
];
export const remittanceExportCols = [
  {
    label: "Wallet Address",
    key: "addressToCSV",
  },
  { label: "Network", key: "networkToCSV" },
  { label: "Currency", key: "currencyToCSV" },
  { label: "Amount", key: "balanceToCSV" },
  { label: "Date", key: "dateToCSV" },
  { label: "Status", key: "statusToCSV" },
  { label: "Failure Reason", key: "failureReasonToCSV" },
  {
    label: "TransactionHash",
    key: "transactionHashToCSV",
  },
];

export const TransactionHistoryCols = [
  { label: "Date", field: "date", sort: "asc" },
  { label: "Time", field: "time", sort: "asc" },
  { label: "Wallet Address", field: "address", sort: "asc" },
  { label: "Destination Address", field: "destintion", sort: "asc" },
  { label: "Amount", field: "amount", sort: "asc" },
  { label: "Network", field: "network", sort: "asc" },
  { label: "Status", field: "status", sort: "asc" },
];

export const UnRemittanceDetailsCol = [
  { label: "Wallet Address", field: "address", sort: "asc" },
  { label: "Network", field: "network", sort: "asc" },
  { label: "Currency", field: "currency", sort: "asc" },
  { label: "Transaction Type", field: "type", sort: "asc" },
  { label: "Amount", field: "amount", sort: "asc" },
  { label: "Gas Price", field: "gasPrice", sort: "asc" },
  { label: "Gas Limit", field: "gasLimit", sort: "asc" },
  { label: "Gas Used", field: "gasUsed", sort: "asc" },
  { label: "Date", field: "date", sort: "asc" },
  { label: "Time", field: "time", sort: "asc" },
  { label: "Transaction Hash", field: "transactionHash", sort: "asc" },
];
export const UnRemittanceDetailsExportCol = [
  { label: "Wallet Address", key: "addressToCSV" },
  { label: "Network", key: "networkToCSV" },
  { label: "Currency", key: "currencyToCSV" },
  { label: "Transaction Type", key: "type" },
  { label: "Amount", key: "amount" },
  { label: "Gas Price", key: "gasPrice" },
  { label: "Gas Limit", key: "gasLimit" },
  { label: "Gas Used", key: "gasUsed" },
  { label: "Date", key: "date" },
  { label: "Time", key: "time" },
  { label: "Transaction Hash", key: "transactionHashToCSV" },
];

export const RemittanceAnalysisCols = [
  { label: "Network", field: "network", sort: "asc", width: "100px" },
  {
    label: "Highest Gas Fees",
    field: "highestGasFee",
    sort: "asc",
    width: "100px",
  },
  {
    label: "Lowest Gas Fees",
    field: "lowestGasFee",
    sort: "asc",
    width: "100px",
  },
  {
    label: "Average Gas Fees",
    field: "averageGasFee",
    sort: "asc",
    width: "100px",
  },
  { label: "Total Fees", field: "totalFees", sort: "asc", width: "100px" },
];

export const CurrencytrasactionAnalysis = [
  { label: "Network", field: "network", sort: "asc" },
  { label: "Currency", field: "currency", sort: "asc" },
  { label: "Volume", field: "volume", sort: "asc" },
  { label: "Count", field: "count", sort: "asc" },
];

export const walletBalancesCols = [
  { label: "Currency", field: "currency", sort: "asc" },
  { label: "Total", field: "total", sort: "asc" },
  { label: "Network", field: "network", sort: "asc" },
  { label: "Action", field: "deposit", sort: "asc" },
  { label: "", field: "withdraw", sort: "asc" },
];
