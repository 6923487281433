import { toast } from "react-toastify";
import {
  setCreatingSettleStalls,
  setGettingSettleStalls,
  setSettledStalls,
} from "../Redux/Reducers/settlementReducer";
import { store } from "../Redux/app/store";
import { v4 as uuidv4 } from "uuid";
import api from "./baseURL";

export const getSettledStalls = async (
  switchWalletTransactionReference?: string | null
) => {
  store.dispatch(setGettingSettleStalls(true));

  const params: any = {
    switchWalletTransactionReference,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === (null || "" || " ")) {
      delete params[key];
    }
  });

  await api
    .get("v2/merchant/stall_settlement_validation", { params })
    .then((res) => {
      store.dispatch(setGettingSettleStalls(false));
      const { data } = res?.data;
      if (data) {
        store.dispatch(setSettledStalls(data));
      }
    })
    .catch(() => store.dispatch(setGettingSettleStalls(false)));
};

export const createSettlement = async (
  withdrawalIds: string[],
  destinationAddress: string
) => {
  store.dispatch(setCreatingSettleStalls(true));

  const body: any = {
    withdrawalIds,
    destinationAddress,
    merchantReference: uuidv4().concat("-settlement"),
  };

  Object.keys(body)?.forEach((key) => {
    if (body[key] === (null || "" || " ")) {
      delete body[key];
    }
  });
  await api
    .post("v2/merchant/settle_stalled_Transaction", body)
    .then((res) => {
      store.dispatch(setCreatingSettleStalls(false));
      const { data } = res?.data;
      if (data) {
        toast.success(data?.message || "Successful");
      }
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setCreatingSettleStalls(false));
    });
};
