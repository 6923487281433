import React, { useState } from "react";
import { Container, FlexBetween, FormContainer, ProfilePic } from "./styles";
import { Input, InputContainer, Label } from "../Generals/LoginStyles";
import { uploadProfileImage } from "../../Apis/accountProcesing";
import { useAppDispatch } from "../../Redux/app/hooks";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const [img, setImg] = useState<any>("/assets/icons/avatar.svg");
  const merchantDetails = JSON.parse(
    localStorage.getItem("merchantDetails") || "{}"
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e?.target;
    if (files !== null) uploadProfileImage(files[0], navigate);
  };

  return (
    <Container>
      <FormContainer>
        <FlexBetween>
          <InputContainer>
            <Label>FirstName</Label>
            <Input value={merchantDetails?.firstName || "N/A"} />
          </InputContainer>
          <InputContainer>
            <Label>LastName</Label>
            <Input value={merchantDetails?.lastName || "N/A"} />
          </InputContainer>
        </FlexBetween>
        <InputContainer>
          <Label>Email address</Label>
          <Input value={merchantDetails?.email || "N/A"} />
        </InputContainer>
        <InputContainer>
          <Label>Phone Number</Label>
          <Input value={merchantDetails?.phoneNumber || "N/A"} />
        </InputContainer>
        {/* <ProfilePic htmlFor="profile-img">
          <Input
            type="file"
            id="profile-img"
            onChange={onFileChange}
            accept=".png,.svg,.jpeg,.webp,.jpg"
          />
          <img
            src={merchantDetails?.businessLogoURL || img}
            alt="profile_img"
          />
        </ProfilePic> */}
      </FormContainer>
    </Container>
  );
};

export default Profile;
