import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { toggleInnerSidebar } from "../../Redux/Reducers/navReducer";
import { calRemittanceSummary } from "../../utils/calculateSummary";
import { CloseInner } from "../Forms/style";
import {
  FlexAutoContainer,
  InnerPageSideBar,
  LeftContent,
  RightContent,
} from "../Generals/RouteStyles";
import SummaryCard from "../Reusables/Cards/SummaryCard";
import RemittanceTable from "../Tables/RemittanceTable";
import { useEffect } from "react";

const RemittanceTab = () => {
  const dispatch = useAppDispatch();
  const { remittanceTransactions } = useAppSelector((state) => state.wallet);
  const remittanceSummary = calRemittanceSummary(remittanceTransactions);
  const { openInnerSidebar } = useAppSelector((state) => state.nav);
  const { gettingRemittanceTransactions } = useAppSelector(
    (state) => state.wallet
  );

  useEffect(() => {
    dispatch(toggleInnerSidebar(false));
  }, []);


  return (
    <FlexAutoContainer>
      <LeftContent flex="0.8" padding="0 0" style={{ overflowX: "auto" }}>
        <RemittanceTable />
      </LeftContent>
      <RightContent flex="0.2" padding="0 0">
        <InnerPageSideBar toggle={openInnerSidebar} style={{ padding: "0" }}>
          <CloseInner onClick={() => dispatch(toggleInnerSidebar(false))}>
            x
          </CloseInner>
          <SummaryCard
            gasSummaryData={remittanceSummary?.completeGasFilter}
            remittanceSummaryData={remittanceSummary?.completeRemitaFilter}
            startdate={remittanceTransactions?.records[0]?.transactionTimeStamp}
            endDate={
              remittanceTransactions?.records[
                remittanceTransactions?.records?.length - 1
              ]?.transactionTimeStamp
            }
            loading={gettingRemittanceTransactions}
          />
        </InnerPageSideBar>
      </RightContent>
    </FlexAutoContainer>
  );
};

export default RemittanceTab;
