import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
/* eslint-disable no-param-reassign */

export interface overviewState {
  merchantDetails: any;
  gettingMerchantBalances: Boolean;
  merchantBalances: any;
  totalBalance: number;
  recentTransactions: any;
  gettingRecentTransactions: Boolean;
  depositBalance: any;
  gettingDepositBalance: Boolean;
  withdrawalBalance: any;
  gettingWithdrawalBalance: Boolean;
  walletsBalance: any;
  gettingWalletBalances: Boolean;
  lockedBalance: any;
}

const initialState: overviewState = {
  merchantDetails: null,
  gettingMerchantBalances: false,
  merchantBalances: null,
  totalBalance: 0.0,
  recentTransactions: null,
  gettingRecentTransactions: false,
  depositBalance: null,
  gettingDepositBalance: false,
  withdrawalBalance: null,
  gettingWithdrawalBalance: false,
  walletsBalance: null,
  gettingWalletBalances: false,
  lockedBalance: null,
};

export const overviewSlice = createSlice({
  name: "overview",
  initialState,
  reducers: {
    setGettingRecentTransactions: (state, action) => {
      state.gettingRecentTransactions = action.payload;
    },
    setGettingMerchantBalances: (state, action) => {
      state.gettingMerchantBalances = action.payload;
    },
    setMerchant: (state, action) => {
      localStorage.setItem(
        "merchantDetails",
        JSON.stringify(action?.payload?.data)
      );
      state.merchantDetails = action?.payload?.data;
    },
    setMerchantBalances: (state, action) => {
      state.merchantBalances = action?.payload;
      const total = action?.payload?.balances
        ?.map((item: any) => item.amountInUSD)
        .reduce((prev: string, curr: string) => Number(prev) + Number(curr), 0);
      state.totalBalance = total;
    },
    setRecentTransactions: (state, action) => {
      state.recentTransactions = action?.payload;
    },
    setDepositBalance: (state, action) => {
      state.depositBalance = action?.payload;
    },
    setGettingWalletBalance: (state, action) => {
      state.gettingWalletBalances = action?.payload;
    },
    setWalletsBalance: (state, action) => {
      state.walletsBalance = action?.payload;
    },

    setGettingDepositBalance: (state, action) => {
      state.gettingDepositBalance = action?.payload;
    },

    setWithdrawalBalance: (state, action) => {
      state.withdrawalBalance = action?.payload;
    },
    setGettingWithdrawalBalance: (state, action) => {
      state.gettingWithdrawalBalance = action?.payload;
    },
    restoreMerchantDetails: (state) => {
      const details = JSON.parse(
        localStorage.getItem("merchantDetails") || "{}"
      );
      state.merchantDetails = details ? details : null;
    },
    restoreMerchantBalances: (state) => {
      const balances = JSON.parse(localStorage.getItem("Balances") || "{}");
      state.merchantBalances = balances || null;
    },
  },
});

export const {
  setMerchant,
  setMerchantBalances,
  restoreMerchantDetails,
  restoreMerchantBalances,
  setRecentTransactions,
  setDepositBalance,
  setWithdrawalBalance,
  setGettingRecentTransactions,
  setGettingMerchantBalances,
  setWalletsBalance,
  setGettingWalletBalance,
  setGettingDepositBalance,
  setGettingWithdrawalBalance,
} = overviewSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectToggle = (state: RootState) => state.overview;

export default overviewSlice.reducer;
