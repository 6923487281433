import mem from "mem";
import api from "./baseURL";

const refreshTokenFn = async () => {
  const user = JSON.parse(localStorage.getItem("currentUser") || "{}");
  const params = { refreshToken: user?.refreshToken };

  try {
    const response = await api.get("v2/auth/refreshToken", {
      params,
    });

    const { updatedUser } = response.data;

    // if (!updatedUser?.token) {
    //   // localStorage.removeItem("currentUser");
    // }
    // localStorage.setItem("currentUser", JSON.stringify(updatedUser));
    return updatedUser;
  } catch (error) {
    // console.log(error);
    localStorage.clear();
  }
};

const maxAge = 3000;

export const memoizedRefreshToken = mem(refreshTokenFn, {
  maxAge,
});
