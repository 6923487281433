import { useEffect } from "react";
import GradientCard from "../Reusables/Cards/GradientCard";
import { CardContainer, SectionContainer, SectionTitle } from "./styles";
// import { RemittanceAnalysisCols } from "../../utils/tablecols";
import { FlexContainer } from "../Generals/RouteStyles";
import { LeftContent, RightContent } from "../styles";
import ScrollToTop from "../ScrollToTop";
import {
  getMerchatnUnremittedBalance,
  getRemitanceCurrencyTimeMetrics,
  getRemitanceFeeMetrics,
  getRemitanceNetworkTimeMetrics,
} from "../../Apis/AnalyticsApiCalls/remittanceApis";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import RemittanceCurrencyChart from "../Charts/RemittanceCurrencyChart";
import RemittanceNetworkChart from "../Charts/RemittanceNetworkChart";
// import { getRemittanceFeeMetricsRows } from "../../utils/tableRows";
import { clearFilterForm, filter } from "../../Redux/Reducers/formReducer";

const Remittance = () => {
  const dispatch = useAppDispatch();
  const { totalBalance } = useAppSelector((state) => state.overview);
  const { filterMode } = useAppSelector((state) => state.form);
  const {
    merchantUnremitedBal,
    gettingMerchantUnremitedBal,
    totalUnremittedBalance,
  } = useAppSelector((state) => state.analytics);
  // const { remittanceFeeMetrics } = useAppSelector((state) => state.analytics);
  const {
    StartTimeStamp,
    EndTimeStamp,
    GroupByPeriod,
    Currency,
    NetworkChain,
    Status,
    TransactionHash,
    WalletAddress,
    Page,
    PageSize,
  } = useAppSelector((state) => state.form);

  // const data = {
  //   columns: RemittanceAnalysisCols,
  //   rows: getRemittanceFeeMetricsRows(remittanceFeeMetrics),
  // };

  useEffect(() => {
    const params = {
      Currency,
      NetworkChain,
      Status,
      TransactionHash,
      WalletAddress,
      Page,
      PageSize,
      StartTimeStamp,
      EndTimeStamp,
    };
    const CurrencyParams = {
      StartTimeStamp,
      EndTimeStamp,
      GroupByPeriod,
      CurrencyOrNetwork: 1,
    };
    const networkParams = {
      StartTimeStamp,
      EndTimeStamp,
      GroupByPeriod,
      CurrencyOrNetwork: 2,
    };
    getRemitanceNetworkTimeMetrics(networkParams);
    getRemitanceCurrencyTimeMetrics(CurrencyParams);
    getRemitanceFeeMetrics(params);
  }, [filterMode, Page]);

  useEffect(() => {
    dispatch(clearFilterForm());
    getMerchatnUnremittedBalance();
    dispatch(filter(true));
  }, []);

  return (
    <div>
      <ScrollToTop />
      <SectionContainer>
        <SectionTitle>Remittance</SectionTitle>
        <CardContainer>
          <GradientCard
            title="Unremitted Balance"
            // subTitle="PREMIUM ACCOUNT"
            balance={totalUnremittedBalance.toFixed(2) + " " + "USD"}
            // icon="/assets/icons/Table/busd.svg"
            fullSize={true}
            loading={gettingMerchantUnremitedBal}
          />
        </CardContainer>
        <FlexContainer>
          <LeftContent>
            <RemittanceCurrencyChart />
          </LeftContent>
          <RightContent>
            <RemittanceNetworkChart />
          </RightContent>
        </FlexContainer>
        {/* <CustomTable data={data} loading={gettingRemittanceFeeMetrics} /> */}
      </SectionContainer>
    </div>
  );
};

export default Remittance;
