import React, { FC } from "react";
import { DotLoader } from "react-spinners";
import styled from "styled-components";
import { numberWithCommaswithoutdecimals } from "./../../../Helpers/generals";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
  width: 100%;
  // height: 50%;
  background: #ffffff;
  border-radius: 12px;
  padding: 10px;
`;

const Title = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 90%;
  line-height: 18px;
  align-self: flex-start;
  color: #000000;
  width: 100%;
  font-weight: bold;
  margin-left: 30px;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const Property = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 80%;
  line-height: 18px;
  color: #000000;
`;

const Divider = styled.div`
  width: 23px;
  height: 0px;
  left: 1227px;
  top: 398px;
  border: 1px solid rgba(32, 66, 184, 0.25);
  transform: rotate(90deg);
`;
const Value = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 90%;
  line-height: 23px;
  color: #000000;
`;

const Data = styled.div`
  display: block;
  text-align: center;
`;

interface ISummary {
  title?: string;
  vol?: string;
  count?: string;
  loading?: Boolean;
  key?: any;
  network?: any;
  currency?: string;
}

const SmallSummaryCard: FC<ISummary> = ({
  title,
  vol,
  count,
  loading,
  key,
  network,
  currency,
}) => (
  <Container key={key}>
    <Title>
      Total {title} {network && `(${network})`}
    </Title>
    <Content>
      <Data>
        {loading ? (
          <DotLoader size={10} color={"#ff6600"} />
        ) : (
          <Value>
            {currency && currency}{" "}
            {vol
              ? numberWithCommaswithoutdecimals(Math.ceil(Number(vol)))
              : "0"}
          </Value>
        )}

        <Property>Vol</Property>
      </Data>
      <Divider />
      <Data>
        {loading ? (
          <DotLoader size={10} color={"#ff6600"} loading={true} />
        ) : (
          <Value>{count ? count : "0"}</Value>
        )}

        <Property>Count</Property>
      </Data>
    </Content>
  </Container>
);

export default SmallSummaryCard;
