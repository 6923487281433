import React from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { getFormDetails } from "../../Redux/Reducers/formReducer";
import { Input } from "../Generals/LoginStyles";
import "antd/dist/antd";
import { media } from "../../respnsive";
import { getSettlementsFormDetails } from "../../Redux/Reducers/settlementReducer";

const SearchbarContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  // flex-wrap: wrap;
  // justify-content: space-around;
  gap: 10px;
  // overflow-x: auto;

  ${media.tablet} {
    flex-wrap: wrap;
  }
`;

const SettlementFilter = () => {
  const { switchWalletTransactionReference } = useAppSelector(
    (state) => state.settlement
  );
  const dispatch = useAppDispatch();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getSettlementsFormDetails({ name, value }));
  };

  return (
    <Input
      type="text"
      name="switchWalletTransactionReference"
      onChange={handleInputChange}
      value={switchWalletTransactionReference || ""}
      style={{ maxWidth: "300px" }}
      placeholder="Transaction Reference"
    />
  );
};

export default SettlementFilter;
