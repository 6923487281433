import { useAppSelector } from "../../Redux/app/hooks";
import { getTotalBalanceSeries } from "../../utils/chatSeries";
import { getMyBalanceChartOptions } from "../../utils/data";
import AreaChart from "./AreaChart";
import { useEffect,useState } from "react";
import { getSupportedCurrencies } from "../../Apis/AnalyticsApiCalls/walletApis";

const RemittanceCurrencyChart = () => {
  const { remittanceCurrencyTimeMetrics } = useAppSelector(
    (state) => state.analytics
  );
  const [currencies, setCurrencies] = useState();

  const { GroupByPeriod } = useAppSelector((state) => state.form);
  const remittanceCurrencyChartOptions = getMyBalanceChartOptions(
    remittanceCurrencyTimeMetrics,
    GroupByPeriod,
    "",
    "Currency"
  );
  const remittanceCurrencyChatSeries = getTotalBalanceSeries(
    remittanceCurrencyTimeMetrics,
    GroupByPeriod,
    currencies
  );

  useEffect(() => {
    getSupportedCurrencies(setCurrencies,true);
  }, []);

  return (
    <AreaChart
      series={remittanceCurrencyChatSeries}
      options={remittanceCurrencyChartOptions}
      // title="Currency"
    />
  );
};

export default RemittanceCurrencyChart;
