import React from "react";
import { useNavigate } from "react-router-dom";
import { changePassword, ResetUserPassword } from "../../Apis/accountProcesing";
import { validateemail } from "../../Helpers/generals";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { compileOtp, errorMessage } from "../../Redux/Reducers/formReducer";
import {
  Desc,
  FooterText,
  Form,
  FormFooter,
  FormLink,
  InputContainer,
} from "../Generals/LoginStyles";
import Filledbutton from "../Reusables/Buttons/Filledbutton";
import UnFilledbutton from "../Reusables/Buttons/PrimaryButton";
import OTPInputs from "../Reusables/Inputs/OTPInputs";
import { Title } from "../styles";
import CustomModal from "./CustomModal";

const ConfirmOTPModal = () => {
  const { otp, error, loading, userOtp, password, confirmPassword } =
    useAppSelector((state) => state.form);
  const user = JSON.parse(localStorage.getItem("currentUser") || "{}");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const email = user?.email;

  const handleSubmit = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const filteredOTP = otp.filter((x) => x.length !== 0);
    if (filteredOTP.length !== 6) {
      dispatch(errorMessage("OTP is not complete"));
    } else {
      dispatch(compileOtp(filteredOTP.join("")));
      changePassword(
        {
          email,
          userOtp,
          password,
          confirmPassword,
        },
        navigate,
        "security"
      );
    }
  };
  const handleResend = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!user?.email || !validateemail(user?.email)) {
      dispatch(errorMessage("Please fill in a valid email"));
    } else {
      ResetUserPassword(user?.email, navigate, "security");
    }
  };
  return (
    <CustomModal
      id={1}
      content={
        <Form>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "30px",
            }}
            
          >
            <Title>Confirm Mail</Title>
            <Desc>We have sent you a One Time Password to email</Desc>
          </div>

          <Desc style={{ marginBottom: "20px" }}>Please Enter OTP</Desc>
          <InputContainer>
            <OTPInputs />
          </InputContainer>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              margin: "20px",
            }}
          >
            <UnFilledbutton
              text="Resend OTP"
              loading={loading}
              handleClick={handleResend}
            />
            <Filledbutton
              text="Verify OTP"
              loading={loading}
              handleClick={handleSubmit}
            />
          </div>
          {/* <FormFooter>
            <FooterText>Already have an account?</FooterText>
            <FormLink to="/">Login</FormLink>
          </FormFooter> */}
          <div className="text-red" style={{ marginTop: "10px" }}>
            {error}
          </div>
        </Form>
      }
    />
  );
};

export default ConfirmOTPModal;
