import api from "./baseURL";
import { sending } from "../Redux/Reducers/formReducer";
import { toast } from "react-toastify";
import { setIs2FAEnabled, setQrObject } from "../Redux/Reducers/userReducer";
import { handleModal } from "../Redux/Reducers/navReducer";
import { checkNetworkChainSupport } from "./AnalyticsApiCalls/walletApis";
import { store } from "../Redux/app/store";

export const saveCurrencies = async (
  currenciesToAccept: any[],
  setCurrenciesToAccept: any,
  networkChain?: number,
  publicKey?: string,
  nvmType?: number
) => {
  store.dispatch(sending(true));
  await checkNetworkChainSupport(nvmType, publicKey, networkChain).then(
    (res) => {
      api
        .post(`v1/merchant/Currencies/set`, currenciesToAccept, {})
        .then((res) => {
          store.dispatch(sending(false));
          if (res.data) {
            toast.success(res?.data?.message);
            setCurrenciesToAccept([]);
          }
        })
        .catch((err) => {
          store.dispatch(sending(false));
          if (err?.response) toast.error(err?.response?.data?.message);
          if (err?.message === "Network Error") toast.error(err?.message);
        });
    }
  );
};

export const getAcceptedCoins = async (
  setAcceptedCoins: any,
  setGettingAcceptedCoins: any
) => {
  setGettingAcceptedCoins(true);
  await api
    .get(`v1/merchant/SupportedCurrencies`, {})
    .then((res) => {
      setAcceptedCoins(res?.data?.data);
      setGettingAcceptedCoins(false);
    })
    .catch(() => setGettingAcceptedCoins(false));
};
export const getSupportedCoins = async (
  setsupportedCoins: any,
  setGettingsupportedCoins: any
) => {
  setGettingsupportedCoins(true);
  await api
    .get(`v1/merchant/GetActiveCurrencies`, {})
    .then((res) => {
      setsupportedCoins(res?.data?.data);
      setGettingsupportedCoins(false);
    })
    .catch(() => setGettingsupportedCoins(false));
};

export const getCurrenciesByNetwork = async (
  network: number,
  setSupportedCoin: any
) => {
  await api
    .get(`v1/merchant/Get/CurrencyByNetwork?networkChain=${network}`, {})
    .then((res: any) => {
      setSupportedCoin(res?.data?.data);
    });
};

export const Check2FAEnabled = async (email: string) => {
  const params = { email };
  await api
    .get("v2/auth/is2FAEnabled", { params })
    .then((res) => {
      store.dispatch(sending(false));
      if (res?.data?.data?.isEnabled)
        store.dispatch(setIs2FAEnabled(res?.data?.data));
    })
    .catch((err) => {
      store.dispatch(sending(false));
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(sending(false));
    });
};

export const Enabled2FA = async (email: string) => {
  store.dispatch(sending(true));
  store.dispatch(store.dispatch(handleModal(2)));
  const params = { email };
  await api
    .post(`v2/auth/Setup/2FA`, null, { params })
    .then((res) => {
      store.dispatch(setQrObject(res?.data?.data));
      store.dispatch(sending(false));
    })
    .catch((err) => {
      store.dispatch(store.dispatch(handleModal(0)));
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(sending(false));
    });
};

export const ResetSecretKey = async (setState: any, setVerified?: any) => {
  store.dispatch(sending(true));
  const body = {
    resetSecret: true,
  };
  await api
    .post(`v1/merchant/IntegrationDetails/Set`, body)
    .then((res) => {
      setState(res?.data?.data);
      setVerified(true);
      toast.success(res?.data?.message);
      store.dispatch(sending(false));
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(sending(false));
    });
};

export const UpdateWebhook = async (
  callbackWebHookUrl?: string,
  verificationUrl?: string,
  remittanceWebHookUrl?: string,
  ips?: string
) => {
  store.dispatch(sending(true));
  const body: any = {
    callbackWebHookUrl,
    remittanceWebHookUrl,
    verificationUrl,
    resetSecret: false,
    ips,
  };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === (null || "" || " ")) {
      delete body[key];
    }
  });
  await api
    .post(`v1/merchant/IntegrationDetails/Set`, body)
    .then((res) => {
      toast.success(res?.data?.message);
      store.dispatch(sending(false));
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(sending(false));
    });
};
