import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { remittanceStatus } from "../../utils/filterSelect";
import { remittanceCols } from "../../utils/tablecols";
import SearchOptions from "../Forms/SearchOptions";
import CustomTable from "../Reusables/Tables/CustomTable";
import { getSettledStalls } from "../../Apis/settlement";
import SettlementFilter from "../Forms/SettlementFilter";

const SettledStallsTable = () => {
  const {
    gettingSettledStalls,
    settledStalls,
    switchWalletTransactionReference,
  } = useAppSelector((state) => state.settlement);
  const dispatch = useAppDispatch();

  const reimittancedata = {
    columns: remittanceCols,
    rows: [],
  };

  useEffect(() => {
    getSettledStalls(switchWalletTransactionReference);
  }, [switchWalletTransactionReference]);
  return (
    <>
      <CustomTable
        data={reimittancedata}
        pageCount={settledStalls?.totalPages}
        currentPage={settledStalls?.currentPage}
        loading={gettingSettledStalls}
        page="Settlement Page"
        title={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div>Settled Stalls</div>
            <SettlementFilter />
          </div>
        }
        // searchOptions={}
        noRecordText="Records not available"
      />
    </>
  );
};

export default SettledStallsTable;
