export const overviewCharts = [
  {
    label: "Balance",
    value: 0,
  },
  {
    label: "Deposit",
    value: 1,
  },
  {
    label: "Withdrawal",
    value: 2,
  },
];
