import moment from "moment";
import { copyHandle, roundton, shortenAddress } from "../../Helpers/generals";
import { numberWithCommaswithoutdecimals } from "../../Helpers/generals";
import SqaureFilledbutton from "../../components/Reusables/Buttons/SqaureFilledButton";
import {
  checkHashAndRetry,
  checkReferenceAndRetry,
} from "../../Apis/transactions";
import { currencyList } from "../currencyList";
import {
  Coin,
  CurrencyAndNetwork,
  CurrencyContainer,
  CurrencyText,
  NetworkText,
} from "../../components/Tables/styles";

export const getRows = (
  walletsTransactions: any,
  showAddress?: boolean,
  setShowAddress?: any,
  selectedRow?: number,
  setSelectedRow?: any,
  loading?: boolean
) => {
  let rows: any = [];

  walletsTransactions?.records?.map((row: any, index: number) => {
    rows.push({
      transactionHash: row?.transactionHashUrl ? (
        <div style={{ display: "flex" }}>
          {" "}
          <a
            href={row?.transactionHashUrl}
            style={{ color: "blue", textDecoration: "none" }}
            target="_blank"
          >
            {shortenAddress(row?.transactionHash)}
          </a>
          <img
            src="/assets/icons/Table/copy-icon-blue.svg"
            alt="copy"
            onClick={() => copyHandle(row?.transactionHash)}
            style={{ cursor: "pointer", width: "16px" }}
          />
        </div>
      ) : (
        "N/A"
      ),
      transactionHashToCSV: row?.transactionHash,
      address: row?.toAddress ? (
        <div style={{ display: "flex", gap: "5px" }}>
          <input
            type="text"
            value={
              showAddress && selectedRow === index
                ? row?.toAddress
                : shortenAddress(row?.toAddress)
            }
            style={{
              border: "none",
              outline: "none",
              width: "100px",
              color: "#52575C",
            }}
            onFocus={() => {
              setShowAddress(true);
              setSelectedRow(index);
            }}
            onBlur={() => setShowAddress(false)}
          />
          <img
            src="/assets/icons/Table/copy-icon-blue.svg"
            alt="copy"
            onClick={() => copyHandle(row?.toAddress)}
            style={{ cursor: "pointer", width: "16px" }}
          />
        </div>
      ) : (
        "N/A"
      ),
      addressToCSV: row?.toAddress,
      balance: row?.amount ? (
        <CurrencyContainer style={{ gap: "10px" }}>
          {currencyList?.map((item, index) => (
            <>
              {row?.currency === item?.name && (
                <Coin
                  key={index}
                  src={item?.Symbol}
                  alt={item?.name}
                  style={{ width: "20px" }}
                />
              )}
            </>
          ))}
          <CurrencyAndNetwork style={{ gap: "5px" }}>
            <CurrencyText style={{ fontSize: "14px" }}>
              {row?.currency}
            </CurrencyText>
            <NetworkText>{roundton(row?.amount, 5)}</NetworkText>
          </CurrencyAndNetwork>
        </CurrencyContainer>
      ) : (
        "N/A"
      ),
      currencyToCSV: row?.currency,
      balanceToCSV: row?.amount,
      network: row?.network ? <>{row?.network}</> : "N/A",
      networkToCSV: row?.network,
      reference: row?.reference ? <>{row?.reference}</> : "N/A",
      referenceToCSV: row?.reference,
      date: row?.createdAt ? (
        <CurrencyAndNetwork style={{ gap: "5px" }}>
          <CurrencyText style={{ fontSize: "14px" }}>
            {moment(row?.createdAt).format("MMM Do YYYY")}
          </CurrencyText>
          <NetworkText>{moment(row?.createdAt).format("LT")}</NetworkText>
        </CurrencyAndNetwork>
      ) : (
        "N/A"
      ),
      dateToCSV:
        moment(row?.createdAt).format("MMM Do YYYY") +
        " " +
        moment(row?.createdAt).format("LT"),
      status: row?.transactionStatus ? (
        <p
          className={`${
            row?.transactionStatus === "Successful"
              ? "text-green"
              : row?.transactionStatus === "CONFIRMED"
              ? "text-green"
              : row?.transactionStatus === "Pending"
              ? "text-yellow"
              : "text-red"
          }`}
        >
          {row?.transactionStatus}
        </p>
      ) : (
        "N/A"
      ),
      statusToCSV: row?.transactionStatus,
      callback: (
        <SqaureFilledbutton
          text="Resend"
          // icon="/assets/icons/Table/filter.svg"
          handleClick={() => {
            if (row?.transactionHash && !row?.reference) {
              checkHashAndRetry(row?.transactionHash, row?.reference);
            }
            if (row?.reference) {
              checkReferenceAndRetry(row?.transactionHash, row?.reference);
            }
            setSelectedRow(index);
          }}
          loading={selectedRow === index && loading}
        />
      ),
      transactionType:
        row?.transactionType === "Deposit" ? (
          <img src="/assets/icons/Table/credit.svg" width="20px" />
        ) : (
          <img src="/assets/icons/Table/debit.svg" width="20px" />
        ),
      transactionTypeToCSV: row?.transactionType,
    });
  });
  return rows;
};
