import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { clearTableFilterForm } from "../../Redux/Reducers/formReducer";
import {
  handleMainnav,
  handleSubnav,
  toggleMobileNav,
} from "../../Redux/Reducers/navReducer";
import { Logout } from "../../Redux/Reducers/userReducer";
import { BsArrowRight, BsBagDash } from "react-icons/bs";
import {
  ExternalLink,
  NavItem,
  SideBarContainer,
  SidebarFooter,
  SidebarHeader,
  SidebarNav,
  SubNavContainer,
  SubNavItem,
} from "./styles";
import { HiOutlineCreditCard, HiOutlineLogout } from "react-icons/hi";
import { FiSettings } from "react-icons/fi";
import { MdOutlineAnalytics } from "react-icons/md";
import { AiOutlineLineChart } from "react-icons/ai";
import { TfiWallet } from "react-icons/tfi";
import { CiGrid42 } from "react-icons/ci";
import SwitchEnv from "../User/SwitchEnv";
import {FaHandshake} from "react-icons/fa"

const Sidebar = () => {
  const { pathname } = useLocation();
  const { toggle, currentSubnav } = useAppSelector((state) => state.nav);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <SideBarContainer toggle={toggle}>
      <SidebarHeader>
        <img src="/assets/images/Brands/logo.svg" alt="logo" />
        <p>
          Dashboard{" "}
          {process.env.REACT_APP_API_URL ===
            "https://mainnet.switchwallet.io/api/" && (
            <span className="text-green" style={{ fontSize: "12px" }}>
              Production
            </span>
          )}
          {process.env.REACT_APP_API_URL ===
            "https://testnet.switchwallet.io/api/" && (
            <span className="text-yellow" style={{ fontSize: "12px" }}>
              Sandbox
            </span>
          )}
          {process.env.REACT_APP_API_URL ===
            "https://development.switchwallet.io/api/" && (
            <span className="text-red" style={{ fontSize: "12px" }}>
              Staging
            </span>
          )}
        </p>
      </SidebarHeader>
      <SidebarNav>
        <NavItem
          to="/"
          className={`${pathname === "/" && "active"}`}
          onClick={() => {
            dispatch(toggleMobileNav());
            dispatch(clearTableFilterForm());
            dispatch(handleMainnav(1));
          }}
        >
          <CiGrid42 />
          <span>Overview</span>
        </NavItem>
        <NavItem
          to="/wallet"
          className={`${pathname === "/wallet" && "active"}`}
          onClick={() => {
            {
              dispatch(toggleMobileNav());
              dispatch(clearTableFilterForm());
              dispatch(handleMainnav(1));
            }
          }}
        >
          <TfiWallet />
          <span>Wallet</span>
        </NavItem>
        <NavItem
          to="/transactions"
          className={`${pathname === "/transactions" && "active"}`}
          onClick={() => {
            dispatch(toggleMobileNav());
            dispatch(clearTableFilterForm());
            dispatch(handleMainnav(1));
          }}
        >
          <AiOutlineLineChart />
          <span>Transactions</span>
        </NavItem>
        <NavItem
          to="/remittance"
          className={`${pathname === "/remittance" && "active"}`}
          onClick={() => {
            dispatch(toggleMobileNav());
            dispatch(clearTableFilterForm());
            dispatch(handleMainnav(1));
          }}
        >
          <HiOutlineCreditCard />
          <span>Remittance</span>
        </NavItem>
        <NavItem
          to="/settlement"
          className={`${pathname === "/settlement" && "active"}`}
          onClick={() => {
            dispatch(toggleMobileNav());
            dispatch(clearTableFilterForm());
            dispatch(handleMainnav(1));
          }}
        >
          <FaHandshake />
          <span>Settlement</span>
        </NavItem>
        <NavItem
          to="/analytics"
          className={`${pathname === "/analytics" && "active"}`}
          onClick={() => {
            dispatch(toggleMobileNav());
            dispatch(handleMainnav(1));
          }}
        >
          <MdOutlineAnalytics />
          <span>Analytics</span>
        </NavItem>
        {pathname === "/analytics" && (
          <SubNavContainer>
            <SubNavItem
              className={`${currentSubnav === 1 && "active"}`}
              onClick={() => {
                dispatch(handleSubnav(1));
                dispatch(toggleMobileNav());
                dispatch(handleMainnav(1));
              }}
            >
              Wallet
            </SubNavItem>
            <SubNavItem
              className={`${currentSubnav === 2 && "active"}`}
              onClick={() => {
                dispatch(handleSubnav(2));
                dispatch(toggleMobileNav());
                dispatch(handleMainnav(1));
              }}
            >
              Transaction
            </SubNavItem>
            <SubNavItem
              className={`${currentSubnav === 3 && "active"}`}
              onClick={() => {
                dispatch(handleSubnav(3));
                dispatch(toggleMobileNav());
                dispatch(handleMainnav(1));
              }}
            >
              Remittance
            </SubNavItem>
          </SubNavContainer>
        )}
        <ExternalLink
          href="https://doc.switchwallet.io/"
          className={`${pathname === "/documentation" && "active"}`}
          onClick={() => {
            dispatch(toggleMobileNav());
            dispatch(handleMainnav(1));
          }}
          target="_blank"
        >
          <BsBagDash />
          <span>Documentation</span>
        </ExternalLink>
        {/* <NavItem
          to="/sessions"
          className={`${pathname === "/sessions" && "active"}`}
          onClick={() => dispatch(toggleMobileNav())}
        >
          {pathname !== "/sessions" ? (
            <img src="/assets/icons/InActive/session.svg" alt="sessions" />
          ) : (
            <img src="/assets/icons/Active/sessions.svg" alt="overview" />
          )}
          <span>Sessions</span>
        </NavItem> */}
        <NavItem
          to="/settings"
          className={`${pathname === "/settings" && "active"}`}
          onClick={() => {
            dispatch(toggleMobileNav());
            dispatch(handleMainnav(1));
          }}
        >
          <FiSettings />
          <span>Settings</span>
        </NavItem>
      </SidebarNav>
      <SidebarFooter
        onClick={() => {
          dispatch(Logout());
          dispatch(toggleMobileNav());
          dispatch(handleMainnav(1));
          navigate("/");
        }}
      >
        <HiOutlineLogout /> <span>Logout</span>
      </SidebarFooter>
      <SwitchEnv />
    </SideBarContainer>
  );
};

export default Sidebar;
