import React from "react";
import { Title } from "../components/styles";
import {
  AuthPageBanner,
  AuthPageContainer,
  Form,
  FormContainer,
  FormFooter,
  FooterText,
  FormLeftContainer,
  Desc,
  Agreement,
} from "../components/Generals/LoginStyles";

const SuccessPage = () => (
  <AuthPageContainer>
    <FormLeftContainer href="https://switchwallet.io">
      <AuthPageBanner src="/assets/images/Forms/Login-banner.png" />
    </FormLeftContainer>
    <FormContainer>
      <Form>
        <div style={{ marginBottom: "30px" }}>
          <Title>SignUp successful</Title>
        </div>
        <Desc style={{ lineHeight: "26px" }}>
          Thank you for registering your account. Your Account is currently
          under review. We will get back to you with your account details
        </Desc>
        <FormFooter>
          <FooterText style={{ marginRight: "10px" }}>Go Back to </FooterText>
          <Agreement href="https://switchwallet.io"> Home Page</Agreement>
        </FormFooter>
      </Form>
    </FormContainer>
  </AuthPageContainer>
);

export default SuccessPage;
