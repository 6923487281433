import api from "./baseURL";
import { filter } from "../Redux/Reducers/formReducer";
import {
  setDepositBalance,
  setGettingDepositBalance,
  setGettingMerchantBalances,
  setGettingRecentTransactions,
  setGettingWalletBalance,
  setGettingWithdrawalBalance,
  setMerchantBalances,
  setRecentTransactions,
  setWalletsBalance,
  setWithdrawalBalance,
} from "../Redux/Reducers/overviewReducer";
import { store } from "../Redux/app/store";

export const getMerchantBalance = async () => {
  store.dispatch(setGettingMerchantBalances(true));
  await api
    .get("v1/walletBalance/merchant-wallet-balances", {})
    .then((res) => {
      store.dispatch(setGettingMerchantBalances(false));
      const { data } = res?.data;
      if (data) {
        store.dispatch(setMerchantBalances(data));
      }
    })
    .catch(() => store.dispatch(setGettingMerchantBalances(false)));
};
export const getRecentTransactions = async (
  params?: any
) => {
  store.dispatch(setGettingRecentTransactions(true));
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get(`v2/analytics/timed-transaction-record`, { params })
    .then((res) => {
      const { data } = res?.data;
      if (data) {
        store.dispatch(setRecentTransactions(data));
        store.dispatch(setGettingRecentTransactions(false));
      }
    })
    .catch((err) => {
      store.dispatch(setGettingRecentTransactions(false));
    });
};

export const getDepositBalance = async (
  parameters?: any
) => {
  store.dispatch(setGettingDepositBalance(true));
  const params = parameters;
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get(`v2/analytics/deposit-time-series-metrics`, {
      params,
    })
    .then((res) => {
      const { data } = res?.data;
      if (data) {
        store.dispatch(setDepositBalance(data));
        store.dispatch(setGettingDepositBalance(false));
      }
      store.dispatch(filter(false));
      store.dispatch(setGettingDepositBalance(false));
    })
    .catch(() => {
      store.dispatch(filter(false));
      store.dispatch(setGettingDepositBalance(false));
    });
};

export const getWithdrawalBalance = async (
  parameters?: any
) => {
  store.dispatch(setGettingWithdrawalBalance(true));
  const params = parameters;
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get(`v2/analytics/withdrawal-time-series-metrics`, {
      params,
    })
    .then((res) => {
      const { data } = res?.data;
      if (data) {
        store.dispatch(setWithdrawalBalance(data));
        store.dispatch(setGettingWithdrawalBalance(false));
      }
      store.dispatch(filter(false));
      store.dispatch(setGettingWithdrawalBalance(false));
    })
    .catch(() => {
      store.dispatch(filter(false));
      store.dispatch(setGettingWithdrawalBalance(false));
    });
};
export const getWalletsBalance = async (
  parameters?: any
) => {
  store.dispatch(setGettingWalletBalance(true));
  const params = parameters;
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get(`v2/analytics/wallets-balance-time-series-metrics`, {
      params,
    })
    .then((res) => {
      const { data } = res?.data;
      if (data) {
        store.dispatch(setWalletsBalance(data));
        store.dispatch(setGettingWalletBalance(false));
      }
      store.dispatch(filter(false));
      store.dispatch(setGettingWalletBalance(false));
    })
    .catch(() => {
      store.dispatch(filter(false));
      store.dispatch(setGettingWalletBalance(false));
    });
};
