import api from "./baseURL";
import { toast } from "react-toastify";
import { clearForm, sending } from "../Redux/Reducers/formReducer";
import { handleModal } from "../Redux/Reducers/navReducer";
import { setDepositAddress } from "../Redux/Reducers/userReducer";
import { store } from "../Redux/app/store";

export const checkHashAndRetry = async (
  transactionHash: string,
  transactionReference: string,
) => {
  store.dispatch(sending(true));
  const body = { transactionHash, transactionReference, retry: true };
  await api
    .post("v2/merchant/webhook/Check-Hash-AndRetry", body, {
      params: {
        "api-version": 2,
      },
    })
    .then((res: any) => {
      toast.success("Successful");
      store.dispatch(sending(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(sending(false));
    });
};

export const checkReferenceAndRetry = async (
  transactionHash: string,
  transactionReference: string,
) => {
  store.dispatch(sending(true));
  const body = { transactionHash, transactionReference, retry: true };
  await api
    .post("v2/merchant/webhook/Check-Reference-AndRetry", body, {
      params: {
        "api-version": 2,
      },
      headers: {},
    })
    .then((res: any) => {
      toast.success("Successful");
      store.dispatch(sending(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(sending(false));
    });
};

export const InitiateDeposit = async (
  currency: number | undefined,
  networkChain: number | undefined,
) => {
  store.dispatch(sending(true));
  store.dispatch(setDepositAddress(""));
  const body: any = { currency, networkChain };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === (null || "--" || " ")) {
      delete body[key];
    }
  });
  await api
    .post("v1/merchant/Get-Currency-Origin-WalletAddress", body, {
      params: {
        "api-version": 1,
      },
    })
    .then((res: any) => {
      // toast.success("Success");
      store.dispatch(sending(false));
      store.dispatch(setDepositAddress(res?.data?.data));
    })
    .catch((err: any) => {
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(sending(false));
    });
};

export const InitiateWithdrawal = async (
  destinationAddress: string,
  amount: number,
  currency: number,
  networkChain: number,
) => {
  store.dispatch(sending(true));
  const body = { destinationAddress, amount, currency, networkChain };
  await api
    .post("merchantClientWithdrawal/Merchant/Withdraw", body, {})
    .then((res: any) => {
      toast.success("Withdrawal Successful");
      store.dispatch(handleModal(0));
      store.dispatch(clearForm());
      store.dispatch(sending(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(sending(false));
    });
};

export const RetryRemittance = async (
  walletAddress: string,
  uniqueReference: string,
  currencySymbol: any,
  networkChain: any,
) => {
  store.dispatch(sending(true));
  await api.get("v1/merchant/IntegrationDetails/Get", {}).then((res: any) => {
    const body = {
      walletAddress,
      uniqueReference,
      publicKey: res?.data?.data?.publicKey,
      currencySymbol,
      networkChain,
    };
    api
      .post("v1/remittanceRequest", body, {})
      .then((res: any) => {
        toast.success("Successful");
        store.dispatch(sending(false));
      })
      .catch((err: any) => {
        if (err?.response) toast.error(err?.response?.data?.message);
        if (err?.message === "Network Error") toast.error(err?.message);
        store.dispatch(sending(false));
      });
  });
};
