import { getMerchatnUnremittedBalance } from "../../Apis/AnalyticsApiCalls/remittanceApis";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { toggleInnerSidebar } from "../../Redux/Reducers/navReducer";
import { CloseInner } from "../Forms/style";
import {
  FlexAutoContainer,
  InnerPageSideBar,
  LeftContent,
  RightContent,
} from "../Generals/RouteStyles";
import SummaryCard from "../Reusables/Cards/SummaryCard";
import UnRemittanceTable from "../Tables/UnRemittanceTable";
import { useEffect } from "react";

const UnremittanceTab = () => {
  const { openInnerSidebar } = useAppSelector((state) => state.nav);
  const dispatch = useAppDispatch();
  const { merchantUnremitedBal, gettingMerchantUnremitedBal } = useAppSelector(
    (state) => state.analytics
  );

  useEffect(() => {
    getMerchatnUnremittedBalance();
    dispatch(toggleInnerSidebar(false));
  }, []);


  return (
    <FlexAutoContainer>
      <LeftContent flex="0.8" padding="0 0" style={{ overflowX: "auto" }}>
        <UnRemittanceTable />
      </LeftContent>
      <RightContent flex="0.2" padding="0 0">
        <InnerPageSideBar toggle={openInnerSidebar} style={{ padding: "0" }}>
          <CloseInner onClick={() => dispatch(toggleInnerSidebar(false))}>
            x
          </CloseInner>
          <SummaryCard
            unremittanceData={merchantUnremitedBal?.balances}
            titleOne="Unremitted"
            loading={gettingMerchantUnremitedBal}
          />
        </InnerPageSideBar>
      </RightContent>
    </FlexAutoContainer>
  );
};

export default UnremittanceTab;
