import { useAppSelector } from "../../Redux/app/hooks";
import { getWalletGeneratedSeries } from "../../utils/chatSeries";
import { getMyBalanceChartOptions } from "../../utils/data";
import AreaChart from "./AreaChart";

const WalletGeneratedChart = () => {
  const { walletsGenerated } = useAppSelector((state) => state.analytics);
  const { GroupByPeriod } = useAppSelector((state) => state.form);
  const depositBalanceChartOptions = getMyBalanceChartOptions(
    walletsGenerated,
    GroupByPeriod,
    "",
    "Wallet Generated"
  );
  const walletsGeneratedSeries = getWalletGeneratedSeries(
    walletsGenerated,
    GroupByPeriod
  );

  return (
    <AreaChart
      series={walletsGeneratedSeries}
      options={depositBalanceChartOptions}
      // title="Wallet Generated"
    />
  );
};

export default WalletGeneratedChart;
