import { removeDuplicates } from "../Helpers/generals";
import { sortByDay, sortByMonths, sortByYear } from "./sorting";
// this function return the total balance chart for each month
export const getTotalBalanceSeries = (
  data: any,
  GroupByPeriod: any,
  currencies: any
) => {
  const removedDuplicated = removeDuplicates(currencies, "label");
  const myBalanaceChatSeries = removedDuplicated?.map((currency: any) => {
    return {
      name: currency?.label,
      data:
        GroupByPeriod && GroupByPeriod == 1
          ? sortByDay(data, currency?.label)
          : GroupByPeriod == 2
          ? sortByMonths(data, currency?.label)
          : GroupByPeriod == 3
          ? sortByYear(data, currency?.label)
          : sortByMonths(data, currency?.label),
    };
  });
  return myBalanaceChatSeries;
};

export const getTotalBalanceSeriesByNetwork = (
  data: any,
  GroupByPeriod: any,
  networks: any
) => {
  const removedDuplicated = removeDuplicates(networks, "label");
  const myBalanaceChatSeries = removedDuplicated?.map((networks: any) => {
    return {
      name: networks?.label,
      data:
        GroupByPeriod && GroupByPeriod == 1
          ? sortByDay(data, networks?.label)
          : GroupByPeriod == 2
          ? sortByMonths(data, networks?.label)
          : GroupByPeriod == 3
          ? sortByYear(data, networks?.label)
          : sortByMonths(data, networks?.label),
    };
  });
  return myBalanaceChatSeries;
};

// analytics
export const getWalletGeneratedSeries = (data: any, GroupByPeriod: any) => {
  const walletGeneratedChatSeries = [
    {
      data:
        GroupByPeriod && GroupByPeriod == 1
          ? sortByDay(data)
          : GroupByPeriod == 2
          ? sortByMonths(data)
          : GroupByPeriod == 3
          ? sortByYear(data)
          : sortByMonths(data),
    },
  ];
  return walletGeneratedChatSeries;
};
