import { useAppSelector } from "../../Redux/app/hooks";
import { getMyBalanceChartOptions } from "../../utils/data";
import BarChart from "./BarChart";
import { getTotalBalanceSeries } from "./../../utils/chatSeries";
import { useState, useEffect } from "react";
import { getSupportedCurrencies } from './../../Apis/AnalyticsApiCalls/walletApis';

const UnRemittanceChart = () => {
  const { unRemittanceTrans } = useAppSelector((state) => state.analytics);
  const [currencies, setCurrencies] = useState();

  const { GroupByPeriod } = useAppSelector((state) => state.form);
  const unRemittanceChartOptions = getMyBalanceChartOptions(
    unRemittanceTrans,
    GroupByPeriod,
    "",
    "UnRemittance (Currency)"
  );
  const unRemittanceChartSeries = getTotalBalanceSeries(
    unRemittanceTrans,
    GroupByPeriod,
    currencies
  );

  useEffect(() => {
    getSupportedCurrencies(setCurrencies,true);
  }, []);

  return (
    <BarChart
      series={unRemittanceChartSeries}
      options={unRemittanceChartOptions}
      // title="UnRemittance Balance (Currency)"
    />
  );
};

export default UnRemittanceChart;
