import {
  AddWithdrawalID,
  getSettlementsFormDetails,
} from "../../Redux/Reducers/settlementReducer";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  Desc,
  Form,
  FormContainer,
  Input,
  InputContainer,
} from "../Generals/LoginStyles";
import SqaureFilledbutton from "../Reusables/Buttons/SqaureFilledButton";
import styled from "styled-components";
import { useEffect } from "react";
import { createSettlement } from "../../Apis/settlement";

const WithdrwalIdsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const StallsToSettleTable = () => {
  const {
    withdrawalIds,
    singleWithdrawalId,
    destinationAddress,
    merchantReference,
    errorMessage,
    creatingSettleStalls,
  } = useAppSelector((state) => state.settlement);
  const dispatch = useAppDispatch();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getSettlementsFormDetails({ name, value }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (withdrawalIds.length === 0 || !destinationAddress) {
      dispatch(
        getSettlementsFormDetails({
          name: "errorMessage",
          value: "Please Fill All Fields",
        })
      );
    } else {
      createSettlement(withdrawalIds, destinationAddress);
    }
  };

  useEffect(() => {}, [withdrawalIds]);

  return (
    <FormContainer>
      <Form style={{ boxShadow: "none", width: "50%" }}>
        <p className="text-yellow">
          {withdrawalIds?.length === 0 && "Please Add WithdrawalID(s)"}
        </p>
        <InputContainer
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "20px",
          }}
        >
          <Input
            type="text"
            name="singleWithdrawalId"
            onChange={handleInputChange}
            value={singleWithdrawalId}
            style={{ maxWidth: "300px" }}
            placeholder="Add Withdrawal ID"
          />
          {singleWithdrawalId && (
            <SqaureFilledbutton
              text="Add ID"
              handleClick={(e: any) => {
                e.preventDefault();
                dispatch(AddWithdrawalID(singleWithdrawalId));
              }}
            />
          )}
        </InputContainer>
        <InputContainer>
          <WithdrwalIdsContainer>
            {withdrawalIds?.map((item: any) => (
              <Desc>{item}</Desc>
            ))}
          </WithdrwalIdsContainer>
        </InputContainer>
        {/* 
        <InputContainer>
          <Input
            type="text"
            name="merchantReference"
            onChange={handleInputChange}
            value={merchantReference}
            style={{ maxWidth: "300px" }}
            placeholder="Merchant Reference"
          />
        </InputContainer> */}
        <InputContainer>
          <Input
            type="text"
            name="destinationAddress"
            onChange={handleInputChange}
            value={destinationAddress}
            style={{ maxWidth: "300px" }}
            placeholder="Destination Address"
          />
        </InputContainer>
        <InputContainer>
          {errorMessage && <span className="text-red">{errorMessage}</span>}
        </InputContainer>
        <SqaureFilledbutton
          text="Create Settlement"
          handleClick={handleSubmit}
          loading={creatingSettleStalls}
        />
      </Form>
    </FormContainer>
  );
};

export default StallsToSettleTable;
