import Filledbutton from "../components/Reusables/Buttons/Filledbutton";
import React, { useEffect } from "react";
import { Desc } from "../components/Reusables/Cards/IconCard";
import { errorMessage, getFormDetails } from "../Redux/Reducers/formReducer";
import { signup } from "../Apis/accountProcesing";
import { Title } from "../components/styles";
import { useAppDispatch, useAppSelector } from "../Redux/app/hooks";
import { useNavigate } from "react-router-dom";
import { validatePassword } from "../Helpers/generals";
/* eslint-disable no-unused-vars */
import {
  AuthPageBanner,
  AuthPageContainer,
  ExtraInput,
  FormLink,
  Form,
  FormContainer,
  Input,
  InputContainer,
  Label,
  Agreement,
  FormFooter,
  FooterText,
  FormLeftContainer,
  RememberMe,
} from "../components/Generals/LoginStyles";

const SignUp = () => {
  const navigate = useNavigate();
  const {
    error,
    email,
    userOtp,
    firstName,
    lastName,
    businessName,
    password,
    confirmPassword,
    loading,
  } = useAppSelector((state) => state.form);
  const dispatch = useAppDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    dispatch(errorMessage(""));
    const { name, value } = e.target;
    dispatch(getFormDetails({ name, value }));
  };

  const handleSubmit = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    dispatch(errorMessage(""));
    if (
      !email ||
      !firstName ||
      !lastName ||
      !businessName ||
      !password ||
      !confirmPassword
    ) {
      dispatch(errorMessage("Please fill all fields"));
    } else if (password !== confirmPassword) {
      dispatch(errorMessage("passwords doesn't match"));
    } else if (!validatePassword(password)) {
      dispatch(
        errorMessage(
          "* min 8 letter password, with at least a symbol, upper and lower case letters and a number"
        )
      );
    } else {
      dispatch(errorMessage(""));
      signup(
        {
          firstName,
          lastName,
          businessName,
          email,
          userOtp,
          password,
          confirmPassword,
        },
        navigate
      );
    }
  };

  useEffect(() => {
    if (!email || !userOtp) navigate("/create-account");
  });
  return (
    <AuthPageContainer style={{ position: "relative", top: "30%" }}>
      <FormLeftContainer href="https://switchwallet.io">
        <AuthPageBanner src="/assets/images/Forms/Login-banner.png" />
      </FormLeftContainer>
      <FormContainer>
        <Form>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "30px",
            }}
          >
            <Title>Sign up</Title>
            <Desc>
              Create an account to start accepting crypto payments from
              customers globally
            </Desc>
          </div>
          <InputContainer>
            <Label>Email</Label>
            <Input
              type="text"
              placeholder="Enter email"
              name="email"
              onChange={handleChange}
            />
          </InputContainer>
          <InputContainer>
            <Label>First Name</Label>
            <Input
              type="text"
              placeholder="Enter firstname"
              name="firstName"
              onChange={handleChange}
            />
          </InputContainer>
          <InputContainer>
            <Label>Last Name</Label>
            <Input
              type="text"
              placeholder="Enter lastname"
              name="lastName"
              onChange={handleChange}
            />
          </InputContainer>
          <InputContainer>
            <Label>Business Name</Label>
            <Input
              type="text"
              placeholder="Enter business name"
              name="businessName"
              onChange={handleChange}
            />
          </InputContainer>
          <InputContainer>
            <Label>Password</Label>
            <Input
              type="password"
              placeholder="Enter password"
              name="password"
              onChange={handleChange}
            />
          </InputContainer>
          <InputContainer>
            <Label>Confirm Password</Label>
            <Input
              type="password"
              placeholder="Confirm password"
              name="confirmPassword"
              onChange={handleChange}
            />
          </InputContainer>
          <ExtraInput>
            <input type="checkbox" name="" id="" />
            <div>
              <RememberMe>
                I have read, understand and agree to SwitchWallet
              </RememberMe>
              <Agreement
                href="https://docs.google.com/document/d/1WSmKzwB1okpZXnTjYJEVR_ps2Rm8z8Ke/edit?usp=sharing&ouid=109597754607874098935&rtpof=true&sd=true"
                target="_blank"
              >
                Policy and Terms and Conditions
              </Agreement>
            </div>
          </ExtraInput>

          <Filledbutton
            text="Sign Up"
            handleClick={handleSubmit}
            loading={loading}
          />
          <FormFooter>
            <FooterText>Already have an account? </FooterText>
            <FormLink to="/"> Log in</FormLink>
          </FormFooter>
          <div className="text-red" style={{ marginTop: "10px" }}>
            {error}
          </div>
        </Form>
      </FormContainer>
    </AuthPageContainer>
  );
};

export default SignUp;
