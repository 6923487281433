import {
  setGettingTransactionMetrics,
  setTransactionMetrics,
} from "../../Redux/Reducers/analyticsReducer";
import { filter } from "../../Redux/Reducers/formReducer";
import { store } from "../../Redux/app/store";
import api from "../baseURL";

export const getWalletsGenerated = async () => {
  await api
    .get("v1/walletBalance/merchant-wallet-balances", {})
    .then((res: any) => {
      const { data } = res?.data;
      if (data) {
        // store.dispatch(setMerchantBalances(data));
      }
    });
};

export const getTransactionMetrics = async (
  parameters?: any
) => {
  store.dispatch(setGettingTransactionMetrics(true));

  const params = parameters;
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get(`v2/analytics/transaction-metrics`, {
      params,
    })
    .then((res: any) => {
      const { data } = res?.data;
      if (data) {
        store.dispatch(setTransactionMetrics(data));
        store.dispatch(setGettingTransactionMetrics(false));
      }
      store.dispatch(filter(false));
    })
    .catch(() => {
      store.dispatch(filter(false));
      store.dispatch(setGettingTransactionMetrics(false));
    });
};
