import styled from "styled-components";
import { media } from "../../respnsive";
import { theme } from "./../../theme";

export const OverviewConainer = styled.div`
  width: 100%;
  height: 128px;
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.phone} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
  }
`;

export const OverviewLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  ${media.phone} {
    flex: 0.4;
  }
`;
export const OverviewRight = styled.div`
  display: flex;
  align-items: center;
  // flex-wrap: wrap;
  gap: 20px;

  ${media.phone} {
    gap: 10px;
  }
`;

export const OverviewBalanceTitle = styled.div`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #646464;
  gap: 10px;

  svg {
    font-size: 16px;
  }
`;

export const OverviewBalanceValue = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 34.4997px;
  line-height: 41px;
  color: #000000;

  ${media.phone} {
    font-size: 20px;
  }
`;

export const ActionButton = styled.button`
  min-width: 180px;
  height: 44px;
  background: rgba(32, 66, 184, 0.11);
  border-radius: 42.3344px;
  font-style: normal;
  font-weight: 500;
  font-size: 14.3506px;
  line-height: 17px;
  color: ${theme.colors.primary};
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease-out;

  &:hover {
    background: ${theme.colors.bgGradient};
    color: #ffffff;
  }

  ${media.phone} {
    min-width: auto;
    height: auto;
    font-size: 12px;
    align-self: flex-end;
    padding: 5px 10px;
  }
`;
