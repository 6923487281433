import axios from "axios";
import { memoizedRefreshToken } from "./refreshToken";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(
  (config: any) => {
    const user = JSON.parse(localStorage.getItem("currentUser") || "{}");
    const token = user.token;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error?.config;

    if (error?.response?.status === 401 && !config?.sent) {
      config.sent = true;

      const result = await memoizedRefreshToken();

      if (result?.token) {
        config.headers["Authorization"] = `Bearer ${result?.token}`;
      } else {
        localStorage.clear();
      }
      return axios(config);
    }
    return Promise.reject(error);
  }
);

export default api;
