import { useState } from "react";
import {
  BoxContainer,
  CardButton,
  ContentContainer,
  CardDescription,
  CardImage,
  CardWrapper,
  CardTitle,
  CardContainer,
  ImageContainer,
  RouteContainer,
  ButtonContainer,
} from "../components/Generals/RouteStyles";
import { ModalButton } from "../components/Modals/ModalStyles";
import Revoke from "../components/Modals/Revoke";
import Terminate from "../components/Modals/Terminate";
import { sessions } from "../utils/sessions";

const Sessions = () => {
  const [active, setActive] = useState(false);
  const [open, setOpen] = useState(false);

  const handleActive = (id: number) => {
    setActive(!false);
  };

  const handleOpen = (id: number) => {
    setOpen(!false);
  };
  return (
    <RouteContainer>
      {active ? (
        <Revoke
          active={active}
          image="assets/images/Modal/emojione-monotone_exclamation-mark.png"
          title="Are you sure"
          desc="You would not be able to revert this action"
          revoke={
            <ModalButton background="#2042B8" color="#fff">
              Revoke
            </ModalButton>
          }
          cancel={
            <ModalButton
              background="#fff"
              color="#000"
              onClick={() => setActive(false)}
            >
              Cancel
            </ModalButton>
          }
        />
      ) : open ? (
        <Terminate
          open={open}
          image="assets/images/Sessions/windows-large.png"
          title="Window Pc"
          desc="Signed in Tue 29 April 2022 08:00:13 GMT"
          bodySubTitle="Session is active"
          bodyTitle="192.345.67:3748"
          bodyDesc="IP Address"
          bodyLocation="Lagos, Nigeria"
          location="Location"
          button={
            <ModalButton
              background="#2042B8"
              color="#fff"
              onClick={() => setOpen(false)}
            >
              Terminate
            </ModalButton>
          }
        />
      ) : (
        <BoxContainer>
          <ContentContainer>
            <h1>Manage Sessions</h1>
            <p>
              This is a list of devices that have logged into your account.
              Revoke any session that you do not Recognize
            </p>
          </ContentContainer>
          {sessions?.map((session) => (
            <CardContainer key={session.id}>
              <ImageContainer>
                <CardImage src={session.image} />
              </ImageContainer>
              <CardWrapper onClick={() => handleOpen(session.id)}>
                <CardTitle>{session.title}</CardTitle>
                <CardDescription>{session.description}</CardDescription>
              </CardWrapper>
              <ButtonContainer>
                <CardButton
                  type="button"
                  onClick={() => handleActive(session.id)}
                >
                  Revoke
                </CardButton>
              </ButtonContainer>
            </CardContainer>
          ))}
        </BoxContainer>
      )}
    </RouteContainer>
  );
};

export default Sessions;
