import React, { useEffect } from "react";
import { getRecentTransactions } from "../../Apis/overview";
import { useAppSelector } from "../../Redux/app/hooks";
import { recentTransactionsCols } from "../../utils/tablecols";
import { getRecentTransactionRows } from "../../utils/tableRows";
import CustomTable from "../Reusables/Tables/CustomTable";
import { useAppDispatch } from "./../../Redux/app/hooks";
import { GrTransaction } from "react-icons/gr";

const RecentTransTable = () => {
  const { recentTransactions, gettingRecentTransactions } = useAppSelector(
    (state) => state.overview
  );
  const { Page, PageSize } = useAppSelector((state) => state.form);
  const rows = getRecentTransactionRows(recentTransactions);
  const dispatch = useAppDispatch();

  const data = {
    columns: recentTransactionsCols,
    rows,
  };

  useEffect(() => {
    const query = { Page, PageSize };
    getRecentTransactions( query);
  }, []);
  return (
    <>
      <CustomTable
        data={data}
        title="Recent Transaction"
        loading={gettingRecentTransactions}
        pageCount={recentTransactions?.totalPages}
        noRecordText="You have no recent transactions"
      />
    </>
  );
};

export default RecentTransTable;
