import { useNavigate } from "react-router-dom";
import { RouteContainer, SeeMore } from "../components/Generals/RouteStyles";
import UnremittedDetailsModal from "../components/Modals/UnremittedDetailsModal";
import MainNav from "../components/NavBars/MainNav";
import { useAppDispatch, useAppSelector } from "../Redux/app/hooks";
import {
  handleMainnav,
  toggleInnerSidebar,
} from "../Redux/Reducers/navReducer";
import { remittanceSubnavs } from "../utils/subNavs";
import RemittanceTab from "./../components/Remittance/RemittanceTab";
import UnremittanceTab from "./../components/Remittance/UnremittanceTab";
import { useEffect } from "react";
import { LoggedInCheck2FAEnabled } from "../Apis/accountProcesing";

const Remittance = () => {
  const { currentMainBodyNav } = useAppSelector((state) => state.nav);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const merchantDetails = JSON.parse(
    localStorage.getItem("merchantDetails") || "{}"
  );

  useEffect(() => {
    LoggedInCheck2FAEnabled(merchantDetails?.businessEmail, navigate);
  }, []);
  
  useEffect(() => {
    dispatch(toggleInnerSidebar(false));
    dispatch(handleMainnav(1));
  }, []);

  return (
    <>
      <MainNav listItem={remittanceSubnavs} />
      <RouteContainer>
        <SeeMore onClick={() => dispatch(toggleInnerSidebar(true))}>
          <img src="/assets/icons/right-icon.svg" alt="right" />
          <span>view summary</span>
        </SeeMore>
        {currentMainBodyNav === 1 ? <RemittanceTab /> : <UnremittanceTab />}
        <UnremittedDetailsModal />
      </RouteContainer>
    </>
  );
};
export default Remittance;
