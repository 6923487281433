import React, { useState } from 'react';
import {
	ContentDesc,
	ContentDescContainer,
	ContentTitle,
	ContentTitlesContainer,
	SingleTitleContainer,
} from '../components/Documentation/styles';
import {
	FlexContainer,
	LeftContent,
	RightContent,
	RouteContainer,
} from '../components/Generals/RouteStyles';
import { documentations } from '../utils/documentations';

const Documentation = () => {
	const [currentDoc, setCurrentDoc] = useState(documentations[0]);
	return (
		<RouteContainer>
			<FlexContainer direction="row">
				<LeftContent flex="0.2" padding="0" width="20px" tabWidth="0px">
					<ContentTitle>{currentDoc.title}</ContentTitle>
					<ContentTitlesContainer>
						{documentations?.map((doc) => (
							<SingleTitleContainer onClick={() => setCurrentDoc(doc)}>
								{doc.title}
							</SingleTitleContainer>
						))}
					</ContentTitlesContainer>
				</LeftContent>
				<RightContent flex="0.8" padding="0" width="90%" tabWidth="80%">
					<ContentDescContainer>
						<ContentDesc>{currentDoc.content}</ContentDesc>
					</ContentDescContainer>
				</RightContent>
			</FlexContainer>
		</RouteContainer>
	);
};

export default Documentation;
