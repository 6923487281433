import { initializeKYCVerification } from "../../Apis/accountProcesing";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { Form } from "../Generals/LoginStyles";
import SqaureFilledbutton from "../Reusables/Buttons/SqaureFilledButton";
import CustomModal from "./CustomModal";

const ProductionSwitchModal = () => {
  const { kycStatusMessage } = useAppSelector((state) => state.user);
  const { loading } = useAppSelector((state) => state.form);
  const email = localStorage.getItem("email") || "{}";
  const dispatch = useAppDispatch();

  return (
    <CustomModal
      id={6}
      content={
        <Form
          style={{
            width: "50%",
            height: "50%",
            overflowY: "auto",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "20px",
          }}
        >
          <div className="switch-desc">
            <div>{kycStatusMessage}</div>
            <SqaureFilledbutton
              text={"Complete KYB"}
              handleClick={() => initializeKYCVerification(email)}
              loading={loading}
            />
          </div>
        </Form>
      }
    />
  );
};

export default ProductionSwitchModal;
