import React from "react";
import StallsToSettleTable from "../Tables/StallsToSettleTable";

const StallToSettle = () => {
  return (
    <div>
      <StallsToSettleTable />
    </div>
  );
};

export default StallToSettle;
