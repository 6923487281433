import { useAppSelector } from "../../Redux/app/hooks";
import { getTotalBalanceSeries } from "../../utils/chatSeries";
import { getMyBalanceChartOptions } from "../../utils/data";
import AreaChart from "./AreaChart";
import { useEffect, useState } from "react";
import { getWalletsBalance } from "../../Apis/overview";
import { getSupportedCurrencies } from "./../../Apis/AnalyticsApiCalls/walletApis";
import ChartFilter from "./../Forms/ChartFilter";

const MyBalanceChart = () => {
  const { walletsBalance, gettingWalletBalances } = useAppSelector(
    (state) => state.overview
  );
  const [currencies, setCurrencies] = useState();
  const [showFilter, setShowfilters] = useState(false);
  const { StartTimeStamp, EndTimeStamp, filterMode, GroupByPeriod } =
    useAppSelector((state) => state.form);
  const monthlyTotalBalanceSeries = getTotalBalanceSeries(
    walletsBalance,
    GroupByPeriod,
    currencies
  );
  const handleFilterClick = () => {
    setShowfilters(!showFilter);
  };
  const myBalanceChartOptions = getMyBalanceChartOptions(
    walletsBalance,
    GroupByPeriod,
    "",
    "Balance",
    handleFilterClick
  );

  useEffect(() => {
    const params = {
      StartTimeStamp,
      EndTimeStamp,
      GroupByPeriod,
    };
    getWalletsBalance(params);
  }, [filterMode, GroupByPeriod, EndTimeStamp]);

  useEffect(() => {
    getSupportedCurrencies(setCurrencies, true);
  }, []);

  return (
    <AreaChart
      series={monthlyTotalBalanceSeries}
      options={myBalanceChartOptions}
      // title={"My Balance"}
      loading={gettingWalletBalances}
      filterOptions={showFilter && <ChartFilter />}
    />
  );
};

export default MyBalanceChart;
