import { useState } from "react";
import AccountCard from "../components/Overview/AccountCard";
import DepositBalanceChart from "../components/Charts/DepositBalanceChart";
import MyBalanceChart from "../components/Charts/MyBalanceChart";
import WithdrawalBalanceChart from "../components/Charts/WithdrawalBalanceChart";
import { clearFilterForm, filter } from "../Redux/Reducers/formReducer";
import { useAppDispatch } from "../Redux/app/hooks";
import { useEffect } from "react";
import { getMerchantBalance } from "../Apis/overview";
import {
  FlexContainer,
  LeftContent,
  RightContent,
  RouteContainer,
} from "../components/Generals/RouteStyles";
import RecentTransTable from "../components/Tables/RecentTransTable";
import { ChartSelectBar } from "../components/Generals/LoginStyles";
import { overviewCharts } from "../utils/ChartDropdown";
import {
  getRemittanceTransactions,
  getWalletsTransactions,
} from "../Apis/AnalyticsApiCalls/walletApis";
import { useNavigate } from "react-router-dom";
import { LoggedInCheck2FAEnabled } from "../Apis/accountProcesing";

const Overview = () => {
  const [currentChart, setCurrentChart] = useState(0);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const merchantDetails = JSON.parse(
    localStorage.getItem("merchantDetails") || "{}"
  );

  useEffect(() => {
    LoggedInCheck2FAEnabled(merchantDetails?.businessEmail, navigate);
  }, []);

  useEffect(() => {
    const params = {};
    dispatch(clearFilterForm());
    dispatch(filter(true));
    getMerchantBalance();
    getWalletsTransactions(params);
    getRemittanceTransactions(params);
  }, []);

  return (
    <RouteContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "10px",
          gap: "10px",
        }}
      >
        <ChartSelectBar
          value={overviewCharts[currentChart]}
          onChange={(option: any) => {
            setCurrentChart(option?.value);
            dispatch(clearFilterForm());
          }}
          options={overviewCharts}
          // styles={colourStyles}
        />
      </div>
      <FlexContainer height="45vh">
        <RightContent flex="0.45" padding="0 0">
          <AccountCard />
        </RightContent>
        <LeftContent flex="0.55" padding="0 0">
          {currentChart === 0 ? (
            <MyBalanceChart />
          ) : currentChart === 1 ? (
            <DepositBalanceChart />
          ) : (
            <WithdrawalBalanceChart />
          )}
        </LeftContent>
      </FlexContainer>
      <RecentTransTable />
    </RouteContainer>
  );
};
export default Overview;
