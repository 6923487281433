import styled from "styled-components";
import { Link } from "react-router-dom";
import { media } from "../../respnsive";
import { theme } from "../../theme";
import DatePicker from "react-datepicker";
import Select from "react-select";

export const AuthPageContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #e5e5e5;
  ${media.phone} {
    flex-direction: column;
    padding: 50px;
  }
  ${media.tablet} {
    background: #ffffff;
  }
`;

export const AuthPageBanner = styled.img`
  width: 100%;
  height: 100%;

  ${media.tablet} {
    display: none;
    flex: 0;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  flex: 0.5;
  height: 100%;

  ${media.tablet} {
    flex: 1;
  }
`;

export const FormLeftContainer = styled.a`
  width: 100%;
  height: 100%;
  flex: 0.5;
  ${media.phone} {
    display: none;
  }
`;
export const Form = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 506px;
  padding: 30px 20px;
  // gap: 20px;
  background: #ffffff;
  box-shadow: 0px 23px 21px rgba(0, 0, 0, 0.05);

  .switch-desc {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  ${media.phone} {
    width: 306px;
    padding: 30px 10px;

    .switch-desc {
      font-size: 15px;
    }
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-bottom: 20px;
`;

export const Label = styled.label`
  font-weight: 400;
  font-size: 90%;
  line-height: 19px;
  color: #52575c;
`;
export const Input = styled.input`
  width: 100%;
  height: 40px;
  background: rgba(32, 66, 184, 0.03);
  border: 1px solid rgba(32, 66, 184, 0.15);
  border-radius: 5px;
  outline: none;
  padding: 10px;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: #52575c;
  margin-top: 5px;

  &:focus {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  ${media.phone} {
    height: 35px;
  }
`;
export const DatePick = styled(DatePicker)`
  width: 100%;
  height: 42px;
  background: rgba(32, 66, 184, 0.03);
  border: 1px solid rgba(32, 66, 184, 0.15);
  border-radius: 5px;
  outline: none;
  padding: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #52575c;
  margin-top: 5px;

  &:focus {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  ${media.phone} {
    height: 35px;
  }
`;

export const SelectBar = styled(Select)`
  width: 100%;
  height: 42px;
  font-size: 13px;

  &:focus {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  &.css-2rndtr-control {
    height: 42px;
  }
  ${media.tablet} {
  }
`;

export const ChartSelectBar = styled(Select)`
  width: auto;
  font-size: 13px;
  z-index: 99;
`;

export const SelectContainer = styled.select`
  width: 100%;
  height: 42px;
  background: rgba(32, 66, 184, 0.03);
  border: 1px solid rgba(32, 66, 184, 0.15);
  border-radius: 5px;
  outline: none;
  padding: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #52575c;
  margin-top: 5px;

  ${media.tablet} {
  }
`;

export const Option = styled.option``;
export const TextArea = styled.textarea`
  width: 100%;
  height: 200px;
  background: rgba(32, 66, 184, 0.03);
  border: 1px solid rgba(32, 66, 184, 0.15);
  border-radius: 5px;
  outline: none;
  padding: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #52575c;
`;

export const Desc = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 18px;
  text-align: center;
  color: #757575;
  margin-bottom: 30px;

  ${media.tablet} {
    font-size: 14px;
  }
`;

export const ExtraInput = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 90%;
  margin-bottom: 30px;

  input {
    width: 24px;
    height: 24px;
    background: rgba(32, 66, 184, 0.03);
    border: 1px solid rgba(32, 66, 184, 0.11);

    ${media.phone} {
      width: 20px;
      height: 20px;
    }
  }
  ${media.phone} {
    flex-direction: column;
  }
`;
export const DoubleButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 90%;
  margin-bottom: 30px;

  input {
    width: 24px;
    height: 24px;
    background: rgba(32, 66, 184, 0.03);
    border: 1px solid rgba(32, 66, 184, 0.11);

    ${media.phone} {
      width: 20px;
      height: 20px;
    }
  }
`;

export const RememberMe = styled.span`
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;

  /* Text/secondary */

  color: #52575c;

  ${media.tablet} {
    font-size: 16px;
  }

  ${media.phone} {
    font-size: 14px;
  }
`;

export const FormLink = styled(Link)`
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  cursor: pointer;

  color: ${theme.colors.primary};

  ${media.tablet} {
    font-size: 16px;
  }
  ${media.phone} {
    font-size: 14px;
  }
`;
export const Agreement = styled.a`
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  cursor: pointer;

  color: ${theme.colors.primary};

  ${media.tablet} {
    font-size: 16px;
  }
  ${media.phone} {
    font-size: 14px;
  }
`;

export const FormFooter = styled.div`
  display: flex;
  align-items: center;
  jusify-content: center;
  margin-top: 30px;
  gap: 5px;
`;

export const FooterText = styled.span`
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #000000;

  ${media.tablet} {
    font-size: 16px;
  }
  ${media.phone} {
    font-size: 14px;
  }
`;

export const colourStyles = {
  control: (base: any, state: any) => ({
    ...base,
    background: "rgba(32, 66, 184, 0.03)",
  }),
};
