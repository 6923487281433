import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { toggleMobileNav } from "../../Redux/Reducers/navReducer";
import SearchInput from "../Reusables/Inputs/SearchInput";
import {
  HeaderContainer,
  HeaderRight,
  HeaderTitle,
  Message,
  Username,
  WelcomeMessage,
} from "./styles";

const Header = () => {
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector((state) => state.user);
  const { pathname } = useLocation();
  const [view, setView] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 50) {
        setView(true);
      } else {
        setView(false);
      }
    });
  }, []);
  return (
    <HeaderContainer className={`${view && "view"}`}>
      <WelcomeMessage>
        {pathname === "/" ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Username>Hi, {currentUser?.businessName} 😊</Username>
            <Message>
              Let’s have a look at what’s happenining on your account today.
            </Message>
          </div>
        ) : (
          <HeaderTitle>{pathname.substring(1, pathname.length)}</HeaderTitle>
        )}
      </WelcomeMessage>
      <HeaderRight>
        {/* <SearchInput />
        <img src="/assets/icons/notification.svg" alt="notifiction" /> */}
        <img
          src="/assets/icons/menu-icon.svg"
          alt="menu"
          onClick={() => dispatch(toggleMobileNav())}
        />
      </HeaderRight>
    </HeaderContainer>
  );
};

export default Header;
