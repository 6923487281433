export const privatePathname = [
  "/",
  "/wallet",
  "/transactions",
  "/remittance",
  "/settlement",
  "/analytics",
  "/Documentation",
  "/sessions",
  "/settings",
];

export const authPathname = [
  "/",
  "/login",
  "/upload-doc",
  "/signup",
  "/change-password",
  "/create-account",
  "/create-otp",
  "/confirm-reset-otp",
  "/confirm-2FA-otp",
  "/reset-password",
  "/success",
];
