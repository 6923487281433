export const settingSubnavs = [
  {
    navId: 1,
    navTitle: "Profile",
  },
  {
    navId: 2,
    navTitle: "Business Detials",
  },
  {
    navId: 3,
    navTitle: "Security",
  },
  {
    navId: 4,
    navTitle: "ApiKey & Webhook",
  },
  {
    navId: 5,
    navTitle: "Account Settings",
  },
  {
    navId: 6,
    navTitle: "IP Addresses",
  },
];

export const remittanceSubnavs = [
  {
    navId: 1,
    navTitle: "Remitted TX",
  },
  {
    navId: 2,
    navTitle: "Unremitted TX",
  },
];

export const settlementTabs = [
  {
    navId: 1,
    navTitle: "All Settlements",
  },
  {
    navId: 2,
    navTitle: "Settle Stall TX",
  },
];
