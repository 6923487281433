import Filledbutton from "../components/Reusables/Buttons/Filledbutton";
import React from "react";
import { changePassword } from "../Apis/accountProcesing";
import { Desc } from "../components/Reusables/Cards/IconCard";
import { errorMessage, getFormDetails } from "../Redux/Reducers/formReducer";
import { Title } from "../components/styles";
import { useAppDispatch, useAppSelector } from "../Redux/app/hooks";
import { useNavigate } from "react-router-dom";
import { validatePassword } from "../Helpers/generals";
/* eslint-disable no-unused-vars */
import {
  AuthPageBanner,
  AuthPageContainer,
  FormLink,
  Form,
  FormContainer,
  Input,
  InputContainer,
  Label,
  FormFooter,
  FooterText,
  FormLeftContainer,
} from "../components/Generals/LoginStyles";

const ChangePassword = () => {
  const navigate = useNavigate();
  const { error, userOtp, password, confirmPassword, loading } = useAppSelector(
    (state) => state.form
  );
  const email = localStorage.getItem("email");

  const dispatch = useAppDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    dispatch(errorMessage(""));
    const { name, value } = e.target;
    dispatch(getFormDetails({ name, value }));
  };

  const handleSubmit = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    dispatch(errorMessage(""));
    if (!email || !password || !confirmPassword) {
      dispatch(errorMessage("Please fill all fields"));
    } else if (password !== confirmPassword) {
      dispatch(errorMessage("passwords doesn't match"));
    } else if (!validatePassword(password)) {
      dispatch(
        errorMessage(
          "* min 8 letter password, with at least a symbol, upper and lower case letters and a number"
        )
      );
    } else {
      dispatch(errorMessage(""));
      changePassword(
        {
          email,
          userOtp,
          password,
          confirmPassword,
        },
        navigate
      );
    }
  };
  return (
    <AuthPageContainer style={{ position: "relative", top: "30%" }}>
      <FormLeftContainer href="https://switchwallet.io">
        <AuthPageBanner src="/assets/images/Forms/Login-banner.png" />
      </FormLeftContainer>
      <FormContainer>
        <Form>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "30px",
            }}
          >
            <Title>Change Password</Title>
            <Desc>Fill in the new password</Desc>
          </div>
          <InputContainer>
            <Label>Password</Label>
            <Input
              type="password"
              placeholder="Enter new password"
              name="password"
              onChange={handleChange}
            />
          </InputContainer>
          <InputContainer>
            <Label>Confirm Password</Label>
            <Input
              type="password"
              placeholder="Confirm new Password"
              name="confirmPassword"
              onChange={handleChange}
            />
          </InputContainer>
          <Filledbutton
            text="Reset"
            handleClick={handleSubmit}
            loading={loading}
          />
          <FormFooter>
            <FooterText>Already have an account?</FooterText>
            <FormLink to="/">Login</FormLink>
          </FormFooter>
          <div className="text-red" style={{ marginTop: "10px" }}>
            {error}
          </div>
        </Form>
      </FormContainer>
    </AuthPageContainer>
  );
};

export default ChangePassword;
