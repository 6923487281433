export const userData = [
  { id: 1, balance: "$1000", month: "Jan" },
  { id: 2, balance: "$2000", month: "Feb" },
  { id: 3, balance: "$3000", month: "Mar" },
  { id: 4, balance: "$4000", month: "Apr" },
  { id: 5, balance: "$15000", month: "May" },
  { id: 6, balance: "$6000", month: "June" },
  { id: 7, balance: "$17000", month: "July" },
  { id: 8, balance: "$10800", month: "Aug" },
  { id: 9, balance: "$10090", month: "Sept" },
  { id: 10, balance: "$10500", month: "Oct" },
  { id: 11, balance: "$10660", month: "Nov" },
  { id: 12, balance: "$1030", month: "Dec" },
];

export const getMyBalanceChartOptions = (
  data: any,
  GroupByPeriod: any,
  currencyOrNetwork?: string,
  text?: string,
  handleClick?: any
) => {
  // let total = 0;
  // data.records.map((item: any) => total + item.yAxis);
  const myBalanceChartOptions = {
    legend: {
      horizontalAlign: "center",
      fontSize: "10px",
    },
    title: {
      text: text,
      align: "left",
    },
    dataLabels: {
      enabled: false,
    },
    colors:
      currencyOrNetwork === "network"
        ? [
            "#7B61FF",
            "#FF6600",
            "#2042B8",
            "#D4A017",
            "#038cfd",
            "#020e6a",
            "#fdec03",
            "#ae03fd",
          ]
        : [
            "#7B61FF",
            "#FF6600",
            "#2042B8",
            "#D4A017",
            "#038cfd",
            "#020e6a",
            "#fdec03",
            "#ae03fd",
          ],
    stroke: { width: 2, curve: "smooth" },
    // fill: { opacity: 1, type: 'solid' },
    xaxis: {
      title: {
        // text: 'commit in year',
        // style: { fontSize: 20, color: '#857666' },
      },
      labels: {
        style: {
          // colors: ["#ae03fd"],
          fontSize: "12px",
        },
      },
      categories:
        GroupByPeriod == 1
          ? [
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "10",
              "11",
              "12",
              "13",
              "14",
              "15",
              "16",
              "17",
              "18",
              "19",
              "20",
              "21",
              "22",
              "23",
              "24",
              "25",
              "26",
              "27",
              "28",
              "29",
              "30",
              "31",
            ]
          : GroupByPeriod == 2
          ? [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "may",
              "Jun",
              "Jul",
              "Aug",
              "Sept",
              "Oct",
              "Nov",
              "Dec",
            ]
          : GroupByPeriod == 3
          ? [
              "2020",
              "2021",
              "2022",
              "2023",
              "2024",
              "2025",
              "2026",
              "2027",
              "2028",
              "2029",
              "2030",
            ]
          : [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "may",
              "Jun",
              "Jul",
              "Aug",
              "Sept",
              "Oct",
              "Nov",
              "Dec",
            ],
    },
    chart: {
      toolbar: {
        tools: {
          download: true,
          customIcons: handleClick && [
            {
              icon: '<img src="/assets/icons/Table/filter.svg" width="15">',
              // index: 4,
              title: "filter",
              class: "custom-icon",
              click: handleClick,
            },
          ],
        },
      },
    },
    yaxis: {
      // title: {
      // 	text: 'commit in year',
      // 	// style: { fontSize: 20, color: '#857666' },
      // },
      // categories: ["0", "1001", "2002", "3003", "4004", "5005"],
      labels: {
        formatter: function (value: any) {
          let val: string | number = Math.abs(value);

          if (val >= 10 ** 3 && val < 10 ** 6) {
            val = (val / 1000).toFixed(0) + " K";
          } else if (val >= 10 ** 6) {
            val = (val / 1000000).toFixed(0) + " M";
          } else {
            val = val;
          }

          return val;
        },
      },
    },
  };

  return myBalanceChartOptions;
};

export const currentOptions = {
  legend: {
    horizontalAlign: "center",
    fontSize: "10px",
  },
  title: {
    text: "Fundamental Analysis of Stocks",
    align: "left",
  },
  dataLabels: {
    enabled: false,
  },
  colors: ["#2042B8", "#FF6600"],
  stroke: { width: 2, curve: "smooth" },
  // fill: { opacity: 1, type: 'solid' },
  xaxis: {
    title: {
      // text: 'commit in year',
      // style: { fontSize: 20, color: '#857666' },
    },
    labels: {
      style: {
        fontSize: "12px",
      },
    },
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "may",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
  yaxis: {
    // title: {
    // 	text: 'commit in year',
    // 	// style: { fontSize: 20, color: '#857666' },
    // },
    // categories: ["0", "1001", "2002", "3003", "4004", "5005"],
    labels: {
      formatter: function (value: any) {
        let val: string | number = Math.abs(value);

        if (val >= 10 ** 3 && val < 10 ** 6) {
          val = (val / 1000).toFixed(0) + " K";
        } else if (val >= 10 ** 6) {
          val = (val / 1000000).toFixed(0) + " M";
        } else {
          val = val;
        }

        return val;
      },
    },
  },
};

export const walletAnalysisOptions = {
  legend: {
    horizontalAlign: "center",
    fontSize: "10px",
  },
  title: {
    text: "Fundamental Analysis of Stocks",
    align: "left",
  },
  dataLabels: {
    enabled: false,
  },
  colors: ["#2042B8"],
  stroke: { width: 2, curve: "smooth" },
  // fill: { opacity: 1, type: 'solid' },
  xaxis: {
    title: {
      // text: 'commit in year',
      // style: { fontSize: 20, color: '#857666' },
    },
    labels: {
      style: {
        colors: [],
        fontSize: "12px",
      },
    },
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "may",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
  yaxis: {
    // title: {
    // 	text: 'commit in year',
    // 	// style: { fontSize: 20, color: '#857666' },
    // },
    // categories: ["0", "1001", "2002", "3003", "4004", "5005"],
    labels: {
      formatter: function (value: any) {
        let val: string | number = Math.abs(value);

        if (val >= 10 ** 3 && val < 10 ** 6) {
          val = (val / 1000).toFixed(0) + " K";
        } else if (val >= 10 ** 6) {
          val = (val / 1000000).toFixed(0) + " M";
        } else {
          val = val;
        }

        return val;
      },
    },
  },
};
