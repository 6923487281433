import moment from "moment";
import { copyHandle, shortenAddress } from "../../Helpers/generals";
import { numberWithCommaswithoutdecimals } from "../../Helpers/generals";
import SqaureFilledbutton from "../../components/Reusables/Buttons/SqaureFilledButton";
import {
  checkHashAndRetry,
  checkReferenceAndRetry,
  RetryRemittance,
} from "../../Apis/transactions";
import {
  checkTransReceiptByHash,
  getUnRemittanceDetails,
} from "../../Apis/AnalyticsApiCalls/remittanceApis";
import { currencyList } from "../currencyList";
import {
  Coin,
  CurrencyAndNetwork,
  CurrencyContainer,
  CurrencyText,
  NetworkText,
} from "../../components/Tables/styles";
import { FailureConatainer, ReasonConatainer } from "./styles";
import { BsInfoCircle } from "react-icons/bs";
import { roundton } from "./../../Helpers/generals";

export const getRemittanceRows = (
  walletsTransactions: any,
  showAddress?: boolean,
  setShowAddress?: any,
  selectedRow?: number,
  setSelectedRow?: any,
  loading?: boolean
) => {
  let rows: any = [];

  walletsTransactions?.records?.map((row: any, index: number) => {
    rows.push({
      transactionHash: row?.remittancePayment?.transactionHash ? (
        <div style={{ display: "flex" }}>
          {row?.remittancePayment?.transactionHash && (
            <>
              <a
                href={row?.remittancePayment?.transactionExplorerURL}
                style={{ color: "blue", textDecoration: "none" }}
                target="_blank"
              >
                {shortenAddress(row?.remittancePayment?.transactionHash)}
              </a>
              <img
                src="/assets/icons/Table/copy-icon-blue.svg"
                alt="copy"
                onClick={() =>
                  copyHandle(row?.remittancePayment?.transactionHash)
                }
                style={{ cursor: "pointer", width: "16px" }}
              />
            </>
          )}
        </div>
      ) : (
        "N/A"
      ),
      transactionHashToCSV: row?.remittancePayment?.transactionHash,
      address: row?.toAddress ? (
        <div style={{ display: "flex", gap: "5px" }}>
          <input
            type="text"
            value={
              showAddress && selectedRow === index
                ? row?.toAddress
                : shortenAddress(row?.toAddress)
            }
            style={{
              border: "none",
              outline: "none",
              width: "100px",
              color: "#52575C",
            }}
            onFocus={() => {
              setShowAddress(true);
              setSelectedRow(index);
            }}
            onBlur={() => setShowAddress(false)}
          />
          <img
            src="/assets/icons/Table/copy-icon-blue.svg"
            alt="copy"
            onClick={() => copyHandle(row?.toAddress)}
            style={{ cursor: "pointer", width: "16px" }}
          />
        </div>
      ) : (
        "N/A"
      ),
      addressToCSV: row?.toAddress,
      balanceToCSV: row?.remittancePayment?.amount,
      currencyToCSV: row?.currency?.symbol,
      balance: row?.currency ? (
        <CurrencyContainer style={{ gap: "10px" }}>
          {currencyList?.map((item, index) => (
            <>
              {row?.currency?.symbol === item?.name && (
                <Coin
                  key={index}
                  src={item?.Symbol}
                  alt={item?.name}
                  style={{ width: "20px" }}
                />
              )}
            </>
          ))}
          <CurrencyAndNetwork style={{ gap: "5px" }}>
            <CurrencyText style={{ fontSize: "14px" }}>
              {row?.currency?.symbol}
            </CurrencyText>
            <NetworkText>
              {row?.remittancePayment?.amount
                ? roundton(row?.remittancePayment?.amount, 5)
                : "N/A"}
            </NetworkText>
          </CurrencyAndNetwork>
        </CurrencyContainer>
      ) : (
        "N/A"
      ),
      network: row?.currency?.networkChain ? (
        <>{row?.currency?.networkChain}</>
      ) : (
        "N/A"
      ),
      networkToCSV: row?.currency?.networkChain,
      dateToCSV:
        moment(row?.transactionTimeStamp).format("MMM Do YYYY") +
        " " +
        moment(row?.transactionTimeStamp).format("LT"),
      date: row?.transactionTimeStamp ? (
        <CurrencyAndNetwork style={{ gap: "5px" }}>
          <CurrencyText style={{ fontSize: "14px" }}>
            {moment(row?.transactionTimeStamp).format("MMM Do YYYY")}
          </CurrencyText>
          <NetworkText>
            {moment(row?.transactionTimeStamp).format("LT")}
          </NetworkText>
        </CurrencyAndNetwork>
      ) : (
        "N/A"
      ),
      statusToCSV: row?.status,
      status: row?.status ? (
        <FailureConatainer
          className={`${
            row?.status === "Successful"
              ? "text-green"
              : row?.status === "Initiated"
              ? "text-yellow"
              : "text-red"
          }`}
        >
          {row?.status}
          {row?.status === "Failed" && (
            <>
              <BsInfoCircle
                onClick={() => {
                  setSelectedRow(index);
                }}
              />
              <ReasonConatainer className="popup">
                {row?.failureReason}
              </ReasonConatainer>
            </>
          )}
        </FailureConatainer>
      ) : (
        "N/A"
      ),
      failureReasonToCSV: row?.failureReason,
      callback: (
        <SqaureFilledbutton
          text="Retry"
          // icon="/assets/icons/Table/filter.svg"
          handleClick={() => {
            if (row?.transactionHash)
              checkHashAndRetry(row?.transactionHash, "");
            else checkReferenceAndRetry("", row?.reference);
            setSelectedRow(index);
          }}
          loading={selectedRow === index && loading}
        />
      ),
      equiry: (
        <SqaureFilledbutton
          text="Send"
          handleClick={() => {
            checkTransReceiptByHash(row?.remittancePayment?.transactionHash);
            setSelectedRow(index);
          }}
          loading={selectedRow === index && loading}
          disabled={
            (!row?.remittancePayment?.transactionHash &&
              row?.status === "Failed") ||
            row?.status === "Successful"
              ? true
              : false
          }
        />
      ),
    });
  });
  return rows;
};

export const getUnRemittanceRows = (
  walletsTransactions: any,
  showAddress?: boolean,
  setShowAddress?: any,
  selectedRow?: number,
  setSelectedRow?: any,
  loading?: boolean,
  gettingUnRemittanceDetails?: any
) => {
  let rows: any = [];
  walletsTransactions?.records?.map((row: any, index: number) => {
    rows.push({
      transactionHash: row?.remittancePayment?.transactionHash ? (
        <div style={{ display: "flex" }}>
          {row?.remittancePayment?.transactionHash && (
            <>
              <a
                href={row?.remittancePayment?.transactionExplorerURL}
                style={{ color: "blue", textDecoration: "none" }}
                target="_blank"
              >
                {shortenAddress(row?.remittancePayment?.transactionHash)}
              </a>
              <img
                src="/assets/icons/Table/copy-icon-blue.svg"
                alt="copy"
                onClick={() =>
                  copyHandle(row?.remittancePayment?.transactionHash)
                }
                style={{ cursor: "pointer", width: "16px" }}
              />
            </>
          )}
        </div>
      ) : (
        "N/A"
      ),
      transactionHashToCSV: row?.remittancePayment?.transactionHash,
      address: row?.walletAddress ? (
        <div style={{ display: "flex", gap: "5px", justifyContent: "center" }}>
          {/* <input
            type="text"
            value={
              showAddress && selectedRow === index
                ? row?.walletAddress
                : shortenAddress(row?.walletAddress)
            }
            style={{
              border: "none",
              outline: "none",
              width: "100px",
              color: "#52575C",
            }}
            onFocus={() => {
              setShowAddress(true);
              setSelectedRow(index);
            }}
            onBlur={() => setShowAddress(false)}
          /> */}
          <span>{shortenAddress(row?.walletAddress)}</span>
          <img
            src="/assets/icons/Table/copy-icon-blue.svg"
            alt="copy"
            onClick={() => copyHandle(row?.walletAddress)}
            style={{ cursor: "pointer", width: "16px" }}
          />
        </div>
      ) : (
        "N/A"
      ),
      addressToCSV: row?.walletAddress,
      balanceToCSV: row?.balance,
      currencyToCSV: row?.currency?.symbol,
      balance: row?.balance ? (
        <CurrencyContainer style={{ gap: "10px" }}>
          {currencyList?.map((item, index) => (
            <>
              {row?.currency?.symbol === item?.name && (
                <Coin
                  key={index}
                  src={item?.Symbol}
                  alt={item?.name}
                  style={{ width: "20px" }}
                />
              )}
            </>
          ))}
          <CurrencyAndNetwork style={{ gap: "5px" }}>
            <CurrencyText style={{ fontSize: "14px" }}>
              {row?.currency?.symbol}
            </CurrencyText>
            <NetworkText>
              {row?.balance ? roundton(row?.balance, 5) : "N/A"}
            </NetworkText>
          </CurrencyAndNetwork>
        </CurrencyContainer>
      ) : (
        "N/A"
      ),
      network: row?.currency?.networkChain ? (
        <>{row?.currency?.networkChain}</>
      ) : (
        "N/A"
      ),
      networkToCSV: row?.currency?.networkChain,
      dateToCSV:
        moment(row?.createdAt).format("MMM Do YYYY") +
        " " +
        moment(row?.createdAt).format("LT"),
      date: row?.createdAt ? (
        <CurrencyAndNetwork style={{ gap: "5px" }}>
          <CurrencyText style={{ fontSize: "14px" }}>
            {moment(row?.createdAt).format("MMM Do YYYY")}
          </CurrencyText>
          <NetworkText>{moment(row?.createdAt).format("LT")}</NetworkText>
        </CurrencyAndNetwork>
      ) : (
        "N/A"
      ),
      status: row?.status ? (
        <p
          className={`${
            row?.status === "Successful"
              ? "text-green"
              : row?.status === "Pending"
              ? "text-yellow"
              : "text-red"
          }`}
        >
          {row?.status}
        </p>
      ) : (
        "N/A"
      ),
      statusToCSV: row?.status,
      callback: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SqaureFilledbutton
            text="Retry"
            // icon="/assets/icons/Table/filter.svg"
            handleClick={() => {
              RetryRemittance(
                row?.walletAddress,
                row?.currency?.id,
                row?.currency?.symbol,
                row?.currency?.networkChain
              );
              setSelectedRow(index);
            }}
            loading={selectedRow === index && loading}
          />
        </div>
      ),
      view: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SqaureFilledbutton
            text="Details"
            handleClick={() => {
              setSelectedRow(index);
              getUnRemittanceDetails(row?.walletAddress, row?.currencyId);
            }}
            loading={selectedRow === index && gettingUnRemittanceDetails}
          />
        </div>
      ),
    });
  });
  return rows;
};

export const getUnRemittanceDetailsRows = (
  UnremitedTransDetails: any,
  showAddress?: boolean,
  setShowAddress?: any,
  selectedRow?: number,
  setSelectedRow?: any
) => {
  let rows: any = [];
  UnremitedTransDetails?.records?.map((row: any, index: number) => {
    rows.push({
      transactionHash: row?.transactionHash ? (
        <div style={{ display: "flex" }}>
          {row?.transactionHash && (
            <>
              <a
                href={row?.transactionExplorerURL}
                style={{ color: "blue", textDecoration: "none" }}
                target="_blank"
              >
                {shortenAddress(row?.transactionHash)}
              </a>
              <img
                src="/assets/icons/Table/copy-icon-blue.svg"
                alt="copy"
                onClick={() => copyHandle(row?.transactionHash)}
                style={{ cursor: "pointer", width: "16px" }}
              />
            </>
          )}
        </div>
      ) : (
        "N/A"
      ),
      transactionHashToCSV: row?.transactionHash,
      address: row?.walletAddress ? (
        <div style={{ display: "flex", gap: "5px", justifyContent: "center" }}>
          {shortenAddress(row?.walletAddress)}
          <img
            src="/assets/icons/Table/copy-icon-blue.svg"
            alt="copy"
            onClick={() => copyHandle(row?.walletAddress)}
            style={{ cursor: "pointer", width: "16px" }}
          />
        </div>
      ) : (
        "N/A"
      ),
      addressToCSV: row?.walletAddress,
      balance: row?.amount ? (
        <>{row?.amount && roundton(row?.amount, 5)}</>
      ) : (
        "N/A"
      ),
      balanceToCSV: row?.amount,
      network: row?.networkChain ? <>{row?.networkChain}</> : "N/A",
      networkToCSV: row?.networkChain,
      deposit: 3,
      currency: row?.currencyName
        ? currencyList?.map((item, index) => {
            return (
              <>
                {row?.currencyName === item?.name && (
                  <img
                    key={index}
                    src={item?.Symbol}
                    alt="xend"
                    style={{
                      marginRight: "10px",
                      width: "20px",
                      borderRadius: "50%",
                    }}
                  />
                )}
              </>
            );
          })
        : "N/A",
      currenyToCSV: row?.currencyName,
      date: moment(row?.createdAt).format("MMM Do YYYY"),
      time: moment(row?.createdAt).format("LT"),
      gasLimit: row?.gasLimit,
      gasUsed: row?.gasUsed,
      gasPrice: row?.gasPrice,
      type: row?.transactionType,
      amount: row?.amount,
    });
  });
  return rows;
};
