import moment from "moment";
import { FC } from "react";
import { DotLoader } from "react-spinners";
import styled from "styled-components";
import { numberWithCommaswithoutdecimals, roundton } from "./../../../Helpers/generals";
import {
  BorderLine,
  BottomContent,
  Container,
  Content,
  Date,
  DateRange,
  SubTitle,
  Title,
  TopContent,
} from "./styles";

interface ISummaryCard {
  remittanceSummaryData?: object[];
  gasSummaryData?: object[];
  titleOne?: string;
  unremittanceData?: object[];
  startdate?: number;
  endDate?: number;
  loading?: any;
}

const SummaryCard: FC<ISummaryCard> = ({
  remittanceSummaryData,
  gasSummaryData,
  titleOne,
  unremittanceData,
  startdate,
  endDate,
  loading,
}) => {
  return (
    <Container>
      <Title>Summary</Title>

      {!loading ? (
        <>
          {startdate && endDate && (
            <DateRange className="text-red">
              Date Range
              <Date>
                {startdate && endDate ? (
                  <>
                    {moment(endDate).format("MMM Do YYYY")} -{" "}
                    {moment(startdate).format("MMM Do YYYY")}
                  </>
                ) : (
                  "Overall"
                )}
              </Date>
            </DateRange>
          )}
          {!unremittanceData ? (
            <TopContent>
              <SubTitle>{titleOne || "Remittance"}</SubTitle>
              {remittanceSummaryData?.map((item: any, index) => (
                <div key={item + index}>
                  {item.network && (
                    <div
                      key={item + index}
                      style={{
                        marginBottom: "10px",
                        borderBottom: "1px solid #757575",
                        paddingBottom: "10px",
                      }}
                    >
                      <Content>
                        Amount: {roundton(item?.amount,5) || 0}{" "}
                        {item?.currency}
                      </Content>
                      <Content>Network: {item?.network}</Content>
                      <Content>
                        Fee: {Number(item?.fee).toFixed(7) || 0}{" "}
                        {item?.currency}
                      </Content>
                    </div>
                  )}
                </div>
              ))}
            </TopContent>
          ) : (
            <TopContent>
              <SubTitle>{titleOne || "Remittance"}</SubTitle>
              {unremittanceData?.map((item: any, index) => (
                <div key={item + index}>
                  {item?.network && (
                    <div
                      key={item + index}
                      style={{
                        marginBottom: "10px",
                        borderBottom: "1px solid #757575",
                        paddingBottom: "10px",
                      }}
                    >
                      <Content>
                        Amount: {roundton(item?.amount,5) || 0}{" "}
                        {item?.currency}
                      </Content>
                      <Content>
                        Amount In USD:{" "}
                        {roundton(item?.amountInUSD,5) || 0}{" "}
                      </Content>
                      <Content>Network: {item?.network}</Content>
                    </div>
                  )}
                </div>
              ))}
            </TopContent>
          )}
          {!unremittanceData && (
            <>
              {" "}
              <BorderLine />
              <BottomContent>
                <SubTitle>Gas Fee</SubTitle>
                {gasSummaryData?.map((item: any, index) => (
                  <div key={item + index}>
                    {item.network && (
                      <div
                        style={{
                          marginBottom: "10px",
                          borderBottom: "1px solid #757575",
                          paddingBottom: "10px",
                        }}
                      >
                        <Content>Network: {item?.network}</Content>
                        <Content>
                          Fee: {roundton(item?.fee,5) || 0}{" "}
                          {item?.currency}
                        </Content>
                      </div>
                    )}
                  </div>
                ))}
              </BottomContent>
            </>
          )}
        </>
      ) : (
        <div className="flex-center">
          <DotLoader color="#ff6600" size={10} />
        </div>
      )}
    </Container>
  );
};

export default SummaryCard;
