/* eslint-disable react/jsx-no-useless-fragment */
import { Routes, Route } from "react-router-dom";
import Header from "../components/NavBars/Header";
import Sidebar from "../components/NavBars/Sidebar";
import PagenotFound from "../components/PagenotFound";
import ScrollToTop from "../components/ScrollToTop";
import Analytics from "../pages/Analytics";
import Documentation from "../pages/Documentation";
import Overview from "../pages/Overview";
import Remittance from "../pages/Remitance";
import Sessions from "../pages/Sessions";
import Settings from "../pages/Setings";
import Transactions from "../pages/Transactions";
import Wallet from "../pages/Wallet";
import { AppBody, MainDashBoard } from "./styles";
import Settlement from "../pages/Settlement";

const PrivateRoutes = () => {
  return (
    <>
      <ScrollToTop />
      <MainDashBoard>
        <Sidebar />
        {/* common route components header & sidebar, this will show for every dashboard route */}
        <AppBody>
          <Header />
          <Routes>
            {/* dashboard routes */}
            <Route path="/" element={<Overview />} />
            <Route path="/wallet" element={<Wallet />} />
            <Route path="/transactions" element={<Transactions />} />
            <Route path="/remittance" element={<Remittance />} />
            <Route path="/settlement" element={<Settlement />} />
            <Route path="/analytics" element={<Analytics />} />
            <Route path="/Documentation" element={<Documentation />} />
            <Route path="/sessions" element={<Sessions />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="*" element={<PagenotFound />} />
          </Routes>
        </AppBody>
      </MainDashBoard>
    </>
  );
};

export default PrivateRoutes;
