import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { getWalletsTransactions } from "../../Apis/AnalyticsApiCalls/walletApis";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { status } from "../../utils/filterSelect";
import { walletCols, walletExportCols } from "../../utils/tablecols";
import { getRows } from "../../utils/TableRows/transactions";
import SearchOptions from "../Forms/SearchOptions";
import SqaureFilledbutton from "../Reusables/Buttons/SqaureFilledButton";
import CustomTable from "../Reusables/Tables/CustomTable";
import { ExportButton } from "../Reusables/Buttons/styles";

const AllTransactionsTable = () => {
  const [showAddress, setShowAddress] = useState(false);
  const [selectedRow, setSelectedRow] = useState(0);
  const { loading } = useAppSelector((state) => state.form);
  const { walletsTransactions, gettingWalletsTransactions } = useAppSelector(
    (state) => state.wallet
  );
  const {
    Currency,
    NetworkChain,
    Status,
    TransactionHash,
    WalletAddress,
    TransactionPage,
    StartTimeStamp,
    EndTimeStamp,
    TransactionType,
  } = useAppSelector((state) => state.form);
  const rows = getRows(
    walletsTransactions,
    showAddress,
    setShowAddress,
    selectedRow,
    setSelectedRow,
    loading
  );

  const remittanceTableRef = useRef();
  const data = {
    columns: walletCols,
    rows,
  };

  useEffect(() => {
    const params = {
      Currency,
      NetworkChain,
      Status,
      TransactionHash,
      WalletAddress,
      Page: TransactionPage,
      PageSize: 30,
      StartTimeStamp: StartTimeStamp && moment(StartTimeStamp).unix(),
      EndTimeStamp: EndTimeStamp && moment(EndTimeStamp).unix(),
      TransactionType,
    };

    getWalletsTransactions(params);
  }, [
    TransactionPage,
    Currency,
    NetworkChain,
    Status,
    TransactionHash,
    WalletAddress,
    TransactionPage,
    StartTimeStamp,
    EndTimeStamp,
    TransactionType,
  ]);

  const exportData = {
    headers: walletExportCols,
    data: rows,
  };

  return (
    <>
      <CustomTable
        data={data}
        pageCount={walletsTransactions?.totalPages}
        currentPage={walletsTransactions?.currentPage}
        loading={gettingWalletsTransactions}
        tableId="transactions-table"
        tableRef={remittanceTableRef}
        page={"TransactionPage"}
        searchOptions={
          <SearchOptions
            recordDateRange={walletsTransactions?.records}
            statusFilter={status}
            page={"transactions"}
          />
        }
        title="Wallet Transactions"
        filter={
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              gap: "10px",
            }}
          >
            <ExportButton
              data={exportData?.data}
              headers={exportData?.headers}
              filename={`Merchant-Transactions-page(${walletsTransactions?.currentPage})`}
            >
              Export CSV
            </ExportButton>
          </div>
        }
        noRecordText="Transaction history not available"
      />
    </>
  );
};

export default AllTransactionsTable;
