import {
  Input,
  InputContainer,
  Label,
  TextArea,
} from "../Generals/LoginStyles";
import { FlexBetween, FormContainer, Container } from "./styles";

const BusinessDetails = () => {
  const merchantDetails = JSON.parse(
    localStorage.getItem("merchantDetails") || "{}"
  );
  return (
    <Container>
      <FormContainer>
        <InputContainer>
          <Label>Business Name</Label>
          <Input value={merchantDetails?.businessName || "N/A"} />
        </InputContainer>
        <InputContainer>
          <Label>Business Email</Label>
          <Input value={merchantDetails?.businessEmail || "N/A"} />
        </InputContainer>
        <InputContainer>
          <Label>Business Website</Label>
          <Input value={merchantDetails?.businessWebsite || "N/A"} />
        </InputContainer>
        <InputContainer>
          <Label>Business PhoneNumber</Label>
          <Input value={merchantDetails?.phoneNumber || "N/A"} />
        </InputContainer>
        {/* <InputContainer>
          <Label>Business Info</Label>
          <TextArea />
        </InputContainer> */}
        <FlexBetween>
          <InputContainer>
            <Label>Business Location</Label>
            <Input value={merchantDetails?.country || "N/A"} />
          </InputContainer>
          {/* <InputContainer>
            <Label> |</Label>
            <Input value="chukwuebuka" />
          </InputContainer> */}
        </FlexBetween>
      </FormContainer>
    </Container>
  );
};

export default BusinessDetails;
