import { useAppDispatch, useAppSelector } from '../../Redux/app/hooks';
import { toggleGenerateForm } from '../../Redux/Reducers/navReducer';
import {
  Input,
  InputContainer,
  Label,
  Option,
  SelectContainer,
} from "../Generals/LoginStyles";
import SqaureFilledbutton from '../Reusables/Buttons/SqaureFilledButton';
import { Close, FilterForm, GenerateContainer } from './style';

const GenerateForm = () => {
	const dispatch = useAppDispatch();
	const { toggleGenerate } = useAppSelector((state) => state.nav);

	return (
		<GenerateContainer toggleGenerate={toggleGenerate}>
			<FilterForm>
				<Close onClick={() => dispatch(toggleGenerateForm(false))}>x</Close>

				<InputContainer>
					<Label>Type</Label>
					<SelectContainer defaultValue="Choose">
						<Option>option 1</Option>
						<Option>option 2</Option>
						<Option>option 3</Option>
						<Option>option 4</Option>
						<Option>option 5</Option>
					</SelectContainer>
				</InputContainer>
				<InputContainer>
					<Label>Start Date</Label>
					<Input type="date" />
				</InputContainer>
				<InputContainer>
					<Label>End Date</Label>
					<Input type="date" />
				</InputContainer>
				<InputContainer>
					<Label>Total Record</Label>
					<Input type="text" />
				</InputContainer>
				<div
					style={{
						display: 'flex',
						justifyContent: 'flex-end',
						margin: '20px',
					}}
				>
					<SqaureFilledbutton text="Generate" />
				</div>
			</FilterForm>
		</GenerateContainer>
	);
};

export default GenerateForm;
