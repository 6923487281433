import React, { useState } from "react";
import {
  ActionButton,
  OverviewBalanceTitle,
  OverviewBalanceValue,
  OverviewConainer,
  OverviewLeft,
  OverviewRight,
} from "./styles";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { useNavigate } from "react-router-dom";
import { handleModal } from "../../Redux/Reducers/navReducer";
import { DotLoader } from "react-spinners";
import { numberWithCommaswithoutdecimals } from "../../Helpers/generals";
import { clearWithdrawDeposit } from "../../Redux/Reducers/formReducer";

const WalletOverview = () => {
  const [viewBalance, setViewBalance] = useState(true);
  const { totalBalance, gettingMerchantBalances } = useAppSelector(
    (state) => state.overview
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <OverviewConainer>
      <OverviewLeft>
        <OverviewBalanceTitle>
          Total Balance{" "}
          <span style={{ cursor: "pointer" }}>
            {viewBalance ? (
              <AiOutlineEyeInvisible onClick={() => setViewBalance(false)} />
            ) : (
              <AiOutlineEye onClick={() => setViewBalance(true)} />
            )}
          </span>
        </OverviewBalanceTitle>
        <OverviewBalanceValue>
          {gettingMerchantBalances ? (
            <DotLoader color="#ff6600" size={10} />
          ) : (
            <>
              {viewBalance
                ? "$ " +
                  numberWithCommaswithoutdecimals(totalBalance.toFixed(2))
                : "*******"}
            </>
          )}
        </OverviewBalanceValue>
      </OverviewLeft>
      <OverviewRight>
        <ActionButton
          onClick={() => {
            dispatch(clearWithdrawDeposit());
            dispatch(handleModal(3));
          }}
          // disabled={totalBalance ? false : true}
        >
          Deposit
        </ActionButton>
        <ActionButton
          onClick={() => {
            dispatch(handleModal(4));
            dispatch(clearWithdrawDeposit());
          }}
          // disabled={totalBalance ? false : true}
        >
          Withdraw
        </ActionButton>
        <ActionButton onClick={() => navigate("/transactions")}>
          Transaction History
        </ActionButton>
      </OverviewRight>
    </OverviewConainer>
  );
};

export default WalletOverview;
