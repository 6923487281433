import Filledbutton from "../Reusables/Buttons/Filledbutton";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { useEffect, useState } from "react";
import {
  getAcceptedCoins,
  getCurrenciesByNetwork,
  getSupportedCoins,
  saveCurrencies,
} from "../../Apis/settings";
import {
  FlexBetween,
  FormContainer,
  PaymentTitle,
  CheckBoxContainer,
  Container,
  Networks,
  NetworksConatainer,
} from "./styles";
import { getSupportedNetworks } from "../../Apis/AnalyticsApiCalls/walletApis";
import { HorizontalDivider } from "../Overview/styles";
import { DotLoader } from "react-spinners";
import { getApiDetails } from "../../Apis/accountProcesing";

const AccountSetings = () => {
  const { loading } = useAppSelector((state) => state.form);
  const [acceptedCoins, setAcceptedCoins] = useState([]);
  const [gettingAcceptedCoins, setGettingAcceptedCoins] = useState(false);
  const [supportedCoins, setsupportedCoins] = useState([]);
  const [gettingSupportedCoins, setGettingSupportedCoins] = useState(false);
  const [currenciesToAccept, setCurrenciesToAccept] = useState<string[]>([]);
  const [currentNetwork, setCurrentNetwork] = useState(1);
  const [nvmType, setNvmType] = useState(1);
  const [networkList, setNetworkList] = useState([]);
  const [gettingNetworkList, setGettingNetworkList] = useState(false);
  const [merchantDetails, setMerchantDetails] = useState<any>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    getAcceptedCoins(setAcceptedCoins, setGettingAcceptedCoins);
    getSupportedCoins(setsupportedCoins, setGettingSupportedCoins);
    getSupportedNetworks(setNetworkList, true, setGettingNetworkList);
    // getSupportedCurrencies();
  }, [loading]);

  const findAccepted = (currencyToFind: any) => {
    const found = acceptedCoins.find(
      (item: any) =>
        item?.name === currencyToFind.name &&
        item?.networkChain === currencyToFind.networkChain
    );
    return found;
  };

  const handleRemove = (itemToRemove: any) => {
    const itemIndex = currenciesToAccept.findIndex(
      (item: any) => item === itemToRemove.id
    );
    setCurrenciesToAccept((currencies) =>
      currencies.filter((_, index) => index !== itemIndex)
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, coin: any) => {
    if (e.target.checked) {
      !findAccepted(coin) &&
        setCurrenciesToAccept([...currenciesToAccept, coin?.id]);
    } else {
      handleRemove(coin);
    }
  };

  useEffect(() => {
    getApiDetails(setMerchantDetails);
  }, []);


  return (
    <Container>
      <FormContainer style={{ gap: "50px" }}>
        <PaymentTitle>Payment</PaymentTitle>

        <FlexBetween style={{ alignItems: "flex-start" }}>
          <PaymentTitle style={{ flex: "0.5" }}>
            Accepted Currencies
          </PaymentTitle>
          <div style={{ flex: "0.5" }}>
            {gettingAcceptedCoins ? (
              <DotLoader color="#ff6600" size={20} />
            ) : (
              <>
                {acceptedCoins.length
                  ? acceptedCoins?.map((coin: any, index: number) => (
                      <CheckBoxContainer key={index}>
                        {coin?.networkChain === currentNetwork && (
                          <>
                            <span>{coin?.name}</span>
                          </>
                        )}
                      </CheckBoxContainer>
                    ))
                  : " N/A"}
              </>
            )}
          </div>
        </FlexBetween>

        <FlexBetween style={{ alignItems: "flex-start" }}>
          <PaymentTitle style={{ flex: "0.5" }}>
            Supported Currencies
          </PaymentTitle>
          <div style={{ flex: "0.5" }}>
            {gettingSupportedCoins ? (
              <DotLoader color="#ff6600" size={20} />
            ) : (
              <>
                {supportedCoins?.map((coin: any, index: number) => (
                  <CheckBoxContainer key={index}>
                    {coin?.networkChain === currentNetwork && (
                      <>
                        <input
                          type="checkbox"
                          onChange={(e) => handleChange(e, coin)}
                          checked={findAccepted(coin) && true}
                        />
                        <span>{coin?.name}</span>
                      </>
                    )}
                  </CheckBoxContainer>
                ))}
              </>
            )}
          </div>
        </FlexBetween>
        <NetworksConatainer>
          {gettingNetworkList ? (
            <DotLoader color="#ff6600" size={20} />
          ) : (
            <Networks>
              <p>EVM Networks</p>
              {networkList.length ? (
                <div>
                  {networkList.map((network: any) => (
                    <>
                      {network?.vmType === 1 && (
                        <span
                          onClick={() => {
                            setCurrentNetwork(network?.value);
                            getCurrenciesByNetwork(
                              network?.value,
                              setsupportedCoins
                            );
                            setNvmType(network?.nvmType);
                          }}
                          className={
                            currentNetwork === network?.value ? "active" : ""
                          }
                        >
                          {network?.label}
                        </span>
                      )}
                    </>
                  ))}
                </div>
              ) : (
                "N/A"
              )}
            </Networks>
          )}

          <HorizontalDivider />

          {gettingNetworkList ? (
            <DotLoader color="#ff6600" size={20} />
          ) : (
            <Networks>
              <p>TVM Networks</p>
              {networkList.length ? (
                <div>
                  {networkList.map((network: any) => (
                    <>
                      {network?.vmType === 2 && (
                        <span
                          onClick={() => {
                            setCurrentNetwork(network?.value);
                            getCurrenciesByNetwork(
                              network?.value,
                              setsupportedCoins
                            );
                            setNvmType(network?.nvmType);
                          }}
                          className={
                            currentNetwork === network?.value ? "active" : ""
                          }
                        >
                          {network?.label}
                        </span>
                      )}
                    </>
                  ))}
                </div>
              ) : (
                "N/A"
              )}
            </Networks>
          )}
        </NetworksConatainer>

        {currenciesToAccept.length ? (
          <Filledbutton
            text="Save"
            handleClick={() =>
              saveCurrencies(
                currenciesToAccept,
                setCurrenciesToAccept,
                currentNetwork,
                merchantDetails?.publicKey,
                nvmType
              )
            }
            loading={loading}
          />
        ) : (
          ""
        )}
      </FormContainer>
    </Container>
  );
};

export default AccountSetings;
