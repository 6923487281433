import Filteration from "../components/Forms/Filteration";
import moment from "moment";
import { useEffect } from "react";
import {
  getDepositBalance,
  getMerchantBalance,
  getWalletsBalance,
  getWithdrawalBalance,
} from "../Apis/overview";
import { RouteContainer } from "../components/Generals/RouteStyles";
import {
  useAppDispatch /* useAppSelector */,
  useAppSelector,
} from "../Redux/app/hooks";
import DepositModal from "../components/Modals/DepositModal";
import WithdrawModal from "../components/Modals/WithdrawModal";
import WalletOverview from "../components/Wallet/WalletOverview";
import WalletBalancesTable from "../components/Tables/WalletBalancesTable";
import { LoggedInCheck2FAEnabled } from "../Apis/accountProcesing";
import { useNavigate } from "react-router-dom";

const Wallet = () => {
  const { StartTimeStamp, EndTimeStamp, filterMode, GroupByPeriod } =
    useAppSelector((state) => state.form);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const merchantDetails = JSON.parse(
    localStorage.getItem("merchantDetails") || "{}"
  );

  useEffect(() => {
    LoggedInCheck2FAEnabled(merchantDetails?.businessEmail, navigate);
  }, []);

  useEffect(() => {
    const params = {
      StartTimeStamp: StartTimeStamp && moment(StartTimeStamp).unix(),
      EndTimeStamp: EndTimeStamp && moment(EndTimeStamp).unix(),
      GroupByPeriod,
    };
    getDepositBalance(params);
    getWithdrawalBalance(params);
    getWalletsBalance(params);
    getMerchantBalance();
  }, [filterMode]);

  return (
    <RouteContainer style={{ padding: "20px 30px" }}>
      <DepositModal />
      <WithdrawModal />
      <WalletOverview />
      <WalletBalancesTable />
      <Filteration />
    </RouteContainer>
  );
};
export default Wallet;
