import styled from "styled-components";
import { media } from "../../../respnsive";

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background: #ffffff;
  border-radius: 5px;
  overflow-x: auto;
  width: 100%;

  // ${media.tablet} {
  // 	width: 720px;
  // }
  // ${media.phone} {
  // 	width: 350px;
  // }
`;
export const TableTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 100%;
  line-height: 32px;
  color: #000000;
  width: 100%;

  ${media.tablet} {
    font-size: 16px;
  }
  ${media.phone} {
    font-size: 14px;
  }
`;
export const Table = styled.table`
  width: 100%;
`;

export const TableHeader = styled.thead`
  width: 100%;
`;
export const TableBody = styled.tbody`
  width: 100%;
`;
export const TableRow = styled.tr`
  width: 100%;
`;
export const TableHead = styled.th`
  font-style: normal;
  font-weight: 400;
  font-size: 80%;
  line-height: 21px;
  color: #000000;
  padding: 15px;
  text-align: center;
  border-bottom: 3px solid #ff6600;

  ${media.phone} {
    font-size: 14px;
  }
`;
export const TableData = styled.td`
  font-style: normal;
  font-weight: 400;
  font-size: 80%;
  line-height: 18px;
  color: #52575c;
  padding: 10px;
  border-bottom: 2px solid #2042b81c;
  text-align: center;

  ${media.phone} {
    font-size: 12px;
  }

  // img{
  //   width: 5%;
  // }
`;
