/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

export interface userState {
  currentUser: any;
  token: string;
  is2FAEnabled: boolean;
  QrObject: any;
  depositAddress: string;
  isActive: boolean;
  isIdle: boolean;
  kycStatusMessage: string;
}

const initialState: userState = {
  currentUser: null,
  token: "",
  is2FAEnabled: false,
  QrObject: null,
  depositAddress: "",
  isActive: false,
  isIdle: false,
  kycStatusMessage: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      localStorage.setItem(
        "currentUser",
        JSON.stringify(action?.payload?.data)
      );
      state.currentUser = action?.payload?.data;
    },
    restoreUser: (state, action) => {
      localStorage.setItem("currentUser", JSON.stringify(action?.payload));
      state.currentUser = action.payload.token ? action.payload : null;
    },

    Logout: (state) => {
      state.currentUser = null;
      localStorage.clear();
    },

    setToken: (state, action) => {
      state.token = action.payload.token;
    },
    setKycStatusMessage: (state, action) => {
      state.kycStatusMessage = action.payload;
    },
    setIs2FAEnabled: (state, action) => {
      state.is2FAEnabled = action.payload.isEnabled;
    },
    setQrObject: (state, action) => {
      state.QrObject = action.payload;
    },
    setDepositAddress: (state, action) => {
      state.depositAddress = action.payload;
    },
  },
});

export const {
  setUser,
  Logout,
  restoreUser,
  setToken,
  setIs2FAEnabled,
  setQrObject,
  setDepositAddress,
  setKycStatusMessage,
} = userSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectToggle = (state: RootState) => state.user;

export default userSlice.reducer;
