import styled from "styled-components";
import { media } from "../../respnsive";
import { theme } from "../../theme";

// General stylehacks

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
`;

export const SectionTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  color: #25282b;

  ${media.phone} {
    font-size: 18px;
  }
`;
export const SectionDesc = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;

  ${media.phone} {
    font-size: 12px;
  }
`;

export const LabelDesc = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #a7a7a7;
`;

export const FlexBetween = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;
// profile styles

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  background: #fff;
  padding: 30px;
  border-radius: 10px;
  gap: 10px;

  ${media.tablet} {
    width: 70%;
  }
  ${media.phone} {
    width: 100%;
  }
`;

export const ProfilePic = styled.label`
  width: 211px;
  height: 194px;
  background: rgba(32, 66, 184, 0.03);
  border: 1px solid rgba(32, 66, 184, 0.11);
  border-radius: 10px;
  padding: 10px;
  object-fit: contain;
  cursor: pointer;
  margin-bottom: 20px;

  input {
    display: none;
  }

  img {
    width: 100%;
    border-radius: 10px;
    height: 100%;
  }
`;

// account Settings
export const PaymentTitle = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 120%;
  line-height: 31px;
  color: #25282b;

  ${media.phone} {
    font-size: 100%;
  }
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  input {
    background: rgba(32, 66, 184, 0.03);
    border: 1px solid rgba(32, 66, 184, 0.11);
    width: 20px;
    height: 20px;

    ${media.phone} {
      width: 18px;
      height: 18px;
    }
  }

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 100%;
    line-height: 31px;
    color: #52575c;
    ${media.phone} {
      font-size: 14px;
    }
  }
`;

export const NetworksConatainer = styled.div`
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-align: center;
  width: 100%;
  gap: 10px;
  // padding: 0 100px;
`;

export const Networks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  // margin: 20px 0px 10px 0;
  font-size: 16px;
  color: #2042b8;

  div {
    display: flex;
    align-items: center;
    gap: 10px;
    ${media.phone} {
      font-size: 14px;
      flex-wrap: wrap;
      gap: 5px;
    }
  }
  p {
    font-size: 18px;
    color: ${theme.colors.textGrey};
  }

  span {
    cursor: pointer;
  }

  span.active {
    color: #ff6600;
  }
`;
