import React, { useState, useEffect } from "react";
import { FormContainer } from "./styles";
import { PaymentTitle } from "./styles";
import { InputContainer, Label } from "../Generals/LoginStyles";
import { FlexBetween } from "./styles";
import { Input } from "antd";
import SqaureFilledbutton from "../Reusables/Buttons/SqaureFilledButton";
import { Container } from "./styles";
import { getApiDetails } from "../../Apis/accountProcesing";
import { UpdateWebhook } from "../../Apis/settings";
import { useAppSelector } from "../../Redux/app/hooks";

const IPWhitelisting = () => {
  const [newIpAddress, setNewIpAddress] = useState("");
  const [ipAdsress, setIPAddress] = useState<any>("");
  const { loading } = useAppSelector((state) => state.form);

  useEffect(() => {
    getApiDetails(setIPAddress);
  }, []);

  useEffect(() => {
    setNewIpAddress(ipAdsress?.callbackUrls?.ips);
  }, [ipAdsress]);

  return (
    <Container>
      <FormContainer>
        <PaymentTitle>Accepted IP Address</PaymentTitle>
        <InputContainer>
          {/* <Label>Add IP Address</Label> */}
          <FlexBetween>
            <Input
              type="text"
              name="ipAddress"
              value={newIpAddress}
              onChange={(e: any) => {
                setNewIpAddress(e.target.value);
              }}
            />
            <SqaureFilledbutton
              text="Update"
              handleClick={() => {
                UpdateWebhook("", "", "", newIpAddress);
              }}
              loading={loading}
            />
          </FlexBetween>
        </InputContainer>
      </FormContainer>
    </Container>
  );
};

export default IPWhitelisting;
