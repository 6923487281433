import { useEffect, useState } from "react";
import { getDepositBalance } from "../../Apis/overview";
import { useAppSelector } from "../../Redux/app/hooks";
import { getTotalBalanceSeries } from "../../utils/chatSeries";
import { getMyBalanceChartOptions } from "../../utils/data";
import AreaChart from "./AreaChart";
import { useAppDispatch } from "./../../Redux/app/hooks";
import ChartFilter from "../Forms/ChartFilter";
import { getSupportedCurrencies } from "../../Apis/AnalyticsApiCalls/walletApis";

const DepositBalanceChart = () => {
  const { depositBalance, gettingDepositBalance } = useAppSelector(
    (state) => state.overview
  );
  const [currenices, setCurrencies] = useState();
  const [showFilter, setShowfilters] = useState(false);
  const { StartTimeStamp, EndTimeStamp, GroupByPeriod } = useAppSelector(
    (state) => state.form
  );
  const handleFilterClick = () => {
    setShowfilters(!showFilter);
  };
  const depositBalanceChartOptions = getMyBalanceChartOptions(
    depositBalance,
    GroupByPeriod,
    "",
    "Deposit",
    handleFilterClick
  );

  const depositBalanaceChatSeries = getTotalBalanceSeries(
    depositBalance,
    GroupByPeriod,
    currenices
  );

  useEffect(() => {
    const params = {
      StartTimeStamp,
      EndTimeStamp,
      GroupByPeriod,
    };
    getDepositBalance(params);
  }, [GroupByPeriod, EndTimeStamp]);

  useEffect(() => {
    getSupportedCurrencies(setCurrencies, true);
  }, []);

  return (
    <AreaChart
      series={depositBalanaceChatSeries}
      options={depositBalanceChartOptions}
      // title={"Deposit Balance"}
      loading={gettingDepositBalance}
      filterOptions={showFilter && <ChartFilter />}
    />
  );
};

export default DepositBalanceChart;
