import { Form } from "../Generals/LoginStyles";
import UnRemittedDetailsTable from "../Tables/UnremittanceDetailsTable";
import CustomModal from "./CustomModal";

const UnremittedDetailsModal = () => {
  return (
    <CustomModal
      id={5}
      content={
        <Form
          style={{
            width: "80%",
            height: "80%",
            overflowY: "auto",
            justifyContent: "flex-start",
          }}
        >
          <UnRemittedDetailsTable />
        </Form>
      }
    />
  );
};

export default UnremittedDetailsModal;
