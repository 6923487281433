import GradientCard from "../Reusables/Cards/GradientCard";
import { DotLoader } from "react-spinners";
import { useAppSelector } from "../../Redux/app/hooks";
import {
  AccountContainer,
  Body,
  CardTitle,
  CountContainer,
  DirectionButton,
  HorizontalDivider,
  TCount,
  TCountTitle,
} from "./styles";

import { useNavigate } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";

const AccountCard = () => {
  const { totalBalance, gettingMerchantBalances } = useAppSelector(
    (state) => state.overview
  );
  const { walletsTransactions, gettingWalletsTransactions } = useAppSelector(
    (state) => state.wallet
  );
  const { remittanceTransactions, gettingRemittanceTransactions } =
    useAppSelector((state) => state.wallet);
  const navigate = useNavigate();

  return (
    <AccountContainer>
      <CardTitle>Account</CardTitle>
      <Body>
        <GradientCard
          title="Total Balance"
          loading={gettingMerchantBalances}
          balance={totalBalance.toFixed(2) + " " + "USD"}
          fullSize={true}
          handleClick={() => navigate("wallet")}
        />
        <HorizontalDivider />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            justifyContent: "flex-end",
          }}
        >
          <CountContainer>
            {gettingWalletsTransactions ? (
              <DotLoader color="#ff6600" size={10} />
            ) : (
              <TCount>{walletsTransactions?.totalRecords}</TCount>
            )}

            <TCountTitle>Transaction</TCountTitle>
          </CountContainer>
          <CountContainer>
            {gettingRemittanceTransactions ? (
              <DotLoader color="#ff6600" size={10} />
            ) : (
              <TCount>{remittanceTransactions?.totalRecords}</TCount>
            )}
            <TCountTitle>Remittance</TCountTitle>
          </CountContainer>
        </div>
      </Body>
      <DirectionButton onClick={() => navigate("wallet")}>
        <BsArrowRight />
      </DirectionButton>
    </AccountContainer>
  );
};

export default AccountCard;
