import styled, { css } from "styled-components";
import { media } from "../../respnsive";

export const ModalBlock = styled.div`
  align-items: center;
  bottom: 0;
  justify-content: center;
  left: 0;
  overflow: hidden;
  padding: 0.4rem;
  position: fixed;
  right: 0;
  top: 0;
  display: flex;
  opacity: 1;
  z-index: 400;
`;

export const ModalOverlay = styled.a`
  background: rgba(247, 248, 249, 0.75);
  bottom: 0;
  cursor: default;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

export const ModalRevoke = styled.a`
  float: right !important;
  text-decoration: none !important;
  cursor: pointer;
  font-size: 1rem;
`;

export const ModalCancel = styled.a`
  float: right !important;
  text-decoration: none !important;
  cursor: pointer;
  font-size: 1rem;
`;

export const ModalContainer = styled.div`
  background: #ffffff;
  border-radius: 0rem;
  display: flex;
  flex-direction: column;
  max-height: 75vh;
  max-width: 450px;
  padding: 40px;
  width: 100%;
  animation: slide-down 0.2s ease 1;
  z-index: 1;
  box-shadow: 0 0.2rem 0.5rem rgba(48, 55, 66, 0.3);
`;

export const ModalBody = styled.div`
  overflow-y: auto;
  padding: 30px 10px;
  position: relative;
`;

export const BodySubTitle = styled.h3`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #25282b;
  margin-bottom: 20px;
`;

export const BodyTitle = styled.h2`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #25282b;
`;

export const BodyDesc = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #52575c;
`;

export const BodyLocation = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #25282b;
  margin-top: 20px;
`;

export const ModalHeader = styled.div`
  flex-direction: row;
  color: #303742;
  padding: 20px 5px 10px 5px;
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(141.55deg, #ff6600 -3.79%, #2042b8 82.82%);
  border-image-slice: 1;
`;

export const ModalImage = styled.img`
  margin: auto;
`;

export const ModalTitle = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #25282b;
  margin: auto;
  display: flex;
  justify-content: center;
`;

export const ModalDesc = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #52575c;
  display: flex;
  justify-content: center;
  margin: 15px 0px;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: ${(props: { content: string }) => props.content};
  margin: 10px;
  gap: 20px;
`;

export const ModalButton = styled.button`
  font-size: 1em;
  padding: 8px 30px;
  border: 1px solid #2042b8;
  border-radius: 5px;
  cursor: pointer;
  background: ${(props: { background: string }) => props.background};
  color: ${(props: any) => props.color};
`;

export const Addresscontainer = styled.span`
  font-size: 14px;
  ${media.phone} {
    font-size: 10px;
  }
`;
