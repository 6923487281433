import styled from "styled-components";

export const SuperContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;
  height: 253px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 80%;
  height: 100%;
  background: rgba(255, 255, 255, 0.15);
  border: 0.5px solid #eeeeee;
  box-shadow: inset 0px 4px 20px rgba(32, 66, 184, 0.1);
  border-radius: 20px;
  padding: 15px 10px;
  gap: 10px;
`;

export const ProfileTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: 0.29031px;

  color: #757575;
`;

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  //   justify-content: space-between;
  gap: 20px;
`;

export const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

export const CurrentEnvironment = styled.span`
  width: 81px;
  height: 23px;
  background: #2042b8;
  box-shadow: -3px 0px 4px rgba(32, 66, 184, 0.46);
  border-radius: 41px;
  font-style: normal;
  font-weight: 700;
  font-size: 9.82164px;
  line-height: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.142566px;
  color: #ffffff;
`;

export const NextEnvironment = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 9.82164px;
  line-height: 13px;
  display: flex;
  align-items: center;
  letter-spacing: 0.142566px;
  color: rgba(32, 66, 184, 0.41);
`;

export const LightBorderLine = styled.div`
  width: 70%;
  height: 2px;
  background: #ebebeb;
`;

export const SwitchContainer = styled.a`
  display: flex;
  align-items: center;
  width: auto;
  padding: 10px;
  background: rgba(32, 66, 184, 0.11);
  border-radius: 41px;
  text-decoration: none;
  width: 168px;
  cursor: pointer;
`;

export const SwitchTitle = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: 0.194428px;
  color: #cdcdcd;
`;