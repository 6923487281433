/* eslint-disable no-nested-ternary */
import React from "react";
import { RouteContainer } from "../components/Generals/RouteStyles";
import MainNav from "../components/NavBars/MainNav";
import AccountSetings from "../components/Settings/AccountSetings";
import ApisWebhook from "../components/Settings/ApisWebhook";
import BusinessDetails from "../components/Settings/BusinessDetails";
import Profile from "../components/Settings/Profile";
import Security from "../components/Settings/Security";
import { useAppDispatch, useAppSelector } from "../Redux/app/hooks";
import { settingSubnavs } from "../utils/subNavs";
import { useEffect } from "react";
import { handleMainnav } from "../Redux/Reducers/navReducer";
import IPWhitelisting from "../components/Settings/IPWhitelisting";
import { useNavigate } from "react-router-dom";
import { LoggedInCheck2FAEnabled } from "../Apis/accountProcesing";

const Settings = () => {
  const { currentMainBodyNav } = useAppSelector((state) => state.nav);
  const navigate = useNavigate();
  const merchantDetails = JSON.parse(
    localStorage.getItem("merchantDetails") || "{}"
  );

  useEffect(() => {
    LoggedInCheck2FAEnabled(merchantDetails?.businessEmail, navigate);
  }, []);
  // useEffect(() => {
  //   dispatch(handleMainnav(1));
  // }, []);
  return (
    <>
      <MainNav listItem={settingSubnavs} />
      <RouteContainer>
        {currentMainBodyNav === 1 ? (
          <Profile />
        ) : currentMainBodyNav === 2 ? (
          <BusinessDetails />
        ) : currentMainBodyNav === 3 ? (
          <Security />
        ) : currentMainBodyNav === 4 ? (
          <ApisWebhook />
        ) : currentMainBodyNav === 5 ? (
          <AccountSetings />
        ) : (
          <IPWhitelisting />
        )}
      </RouteContainer>
    </>
  );
};

export default Settings;
