import styled from "styled-components";

export const ChartFilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40%;
  padding: 0 10px;
  position: absolute;
  top: 2%;
  left: 12%;
  z-index: 100000;
  background: #fff;
`;

export const DateContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
