import { getSupportedCurrencies } from "../../Apis/AnalyticsApiCalls/walletApis";
import { useAppSelector } from "../../Redux/app/hooks";
import { getTotalBalanceSeries } from "../../utils/chatSeries";
import { getMyBalanceChartOptions } from "../../utils/data";
import AreaChart from "./AreaChart";
import { useEffect, useState } from "react";

const CurrencyChart = () => {
  const { depositCurrencyBalance } = useAppSelector((state) => state.wallet);
  const { GroupByPeriod } = useAppSelector((state) => state.form);
  const [currenices, setCurrencies] = useState();
  const depositBalanceChartOptions = getMyBalanceChartOptions(
    depositCurrencyBalance,
    GroupByPeriod,
    "",
    "Wallet Deposit (Currency)"
  );
  const depositBalanaceChatSeries = getTotalBalanceSeries(
    depositCurrencyBalance,
    GroupByPeriod,
    currenices
  );

  useEffect(() => {
    getSupportedCurrencies(setCurrencies,true);
  }, []);

  return (
    <AreaChart
      series={depositBalanaceChatSeries}
      options={depositBalanceChartOptions}
      // title="Wallet Deposit (Currency)"
    />
  );
};

export default CurrencyChart;
