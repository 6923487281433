import styled from "styled-components";
import { media } from "../../../respnsive";
import { theme } from "../../../theme";
import { CSVLink } from "react-csv";

interface bgColor {
  bg?: string;
  loading?: boolean;
  isDisabled?: boolean;
}

export const FilledButton = styled.button<bgColor>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 220px;
  height: 55.17px;
  background: ${(props) => (props.bg ? props.bg : theme.colors.primary)};
  border-radius: 34.9206px;
  outline: none;
  border: none;
  margin-bottom: 10px;
  color: ${(props) =>
    props.bg === "#fff" ? theme.colors.textBackground : theme.colors.textWhite};
  font-size: ${theme.fontSize.md};
  font-weight: ${theme.fontWeight.bold};
  cursor: ${(props) => (props.loading ? "not-allowed" : "pointer")};

  &:hover {
    background: ${theme.colors.bgGradient};
  }

  ${media.tablet} {
    width: 131px;
    height: 48px;
    font-size: ${theme.fontSize.xs};
    font-weight: ${theme.fontWeight.semibold};
  }
  ${media.phone} {
    width: 104px;
    height: 32px;
    font-size: 10px;
    font-weight: ${theme.fontWeight.semibold};
  }
`;
export const UnFilledButton = styled.button<bgColor>`
  width: 220px;
  height: 55.17px;
  background: #fff;
  border-radius: 34.9206px;
  outline: none;
  border: 2px solid ${(props) => (props.bg ? props.bg : theme.colors.primary)};
  color: ${(props) =>
    props.bg === "#fff" ? theme.colors.textBackground : theme.colors.primary};
  font-size: ${theme.fontSize.md};
  font-weight: ${theme.fontWeight.bold};
  cursor: ${(props) => (props.loading ? "not-allowed" : "pointer")};

  ${media.tablet} {
    width: 131px;
    height: 48px;
    font-size: ${theme.fontSize.xs};
    font-weight: ${theme.fontWeight.semibold};
  }
  ${media.phone} {
    width: 104px;
    height: 32px;
    font-size: 10px;
    font-weight: ${theme.fontWeight.semibold};
  }
`;

export const OutlinedButton = styled.button`
  position: relative;
  width: 218px;
  height: 55.17px;
  background: #fff;
  font-size: ${theme.fontSize.md};
  font-weight: ${theme.fontWeight.bold};
  border-radius: 34.9206px;
  border: none;
  outline: none;
  cursor: pointer;

  span {
    background: ${theme.colors.textBackground};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    border-radius: inherit;
    top: -2px;
    bottom: -2px;
    right: -2px;
    left: -2px;
    z-index: -1;
    background: linear-gradient(141.55deg, #ff6600 -3.79%, #2042b8 82.82%);
  }

  ${media.tablet} {
    width: 129px;
    height: 48px;
    font-size: ${theme.fontSize.xs};
    font-weight: ${theme.fontWeight.semibold};
  }
  ${media.phone} {
    width: 104px;
    height: 32px;
    font-size: 10px;
    font-weight: ${theme.fontWeight.semibold};
  }
`;

export const RoundedButton = styled.button`
  position: relative;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: ${theme.colors.bgGradient};
  background-repeat: no-repeat;
  object-fit: contain;
  border: none;
  outline: none;
  border-radius: 50%;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.562636px;

  ${media.tablet} {
    width: 24.04px;
    height: 24.04px;
    font-size: ${theme.fontSize.xs};
    font-weight: ${theme.fontWeight.semibold};
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    border-radius: inherit;
    top: -5px;
    bottom: -5px;
    right: -5px;
    left: -5px;
    z-index: -1;
    background: linear-gradient(
      141.55deg,
      rgba(255, 102, 0, 0.4) -3.79%,
      rgba(32, 66, 184, 0.4) 82.82%
    );
  }
`;
export const RoundedOutlineBtn = styled.button`
  position: relative;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  object-fit: contain;
  border: none;
  outline: none;
  border-radius: 50%;
  background: #fff;
  color: #000;
  font-size: 24px;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.562636px;

  ${media.tablet} {
    width: 24.04px;
    height: 24.04px;
    font-size: ${theme.fontSize.xs};
    font-weight: ${theme.fontWeight.semibold};
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    border-radius: inherit;
    top: -5px;
    bottom: -5px;
    right: -5px;
    left: -5px;
    z-index: -1;
    border: 2px solid transparent;
    background: linear-gradient(141.55deg, #ff6600 -3.79%, #2042b8 82.82%);
  }
`;

export const SquareFilledButton = styled.button<bgColor>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  height: 35px;
  padding: 0 10px;
  background: ${(props) => (props.bg ? props.bg : theme.colors.primary)};
  border-radius: 12px;
  opacity: ${(props) => (props.isDisabled ? "0.5" : "1")};
  outline: none;
  border: none;
  color: ${(props) =>
    props.bg === "#fff" ? theme.colors.textBackground : theme.colors.textWhite};
  font-size: 90%;
  font-weight: 400;
  cursor: pointer;

  &:hover {
    background: ${theme.colors.bgGradient};
  }

  ${media.tablet} {
    font-size: ${theme.fontSize.xs};
    font-weight: ${theme.fontWeight.semibold};
  }
  ${media.phone} {
    font-size: 10px;
    font-weight: ${theme.fontWeight.semibold};
    height: 35px;

    img {
      width: 10px;
    }
  }
  img {
    width: 10px;
  }
`;

export const ExportButton = styled(CSVLink)<bgColor>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 200px;
  text-decoration: none;
  height: 35px;
  padding: 0 10px;
  background: ${(props) => (props.bg ? props.bg : theme.colors.primary)};
  border-radius: 12px;
  opacity: ${(props) => (props.isDisabled ? "0.5" : "1")};
  outline: none;
  border: none;
  color: ${(props) =>
    props.bg === "#fff" ? theme.colors.textBackground : theme.colors.textWhite};
  font-size: 90%;
  font-weight: 400;
  cursor: pointer;

  &:hover {
    background: ${theme.colors.bgGradient};
  }

  ${media.tablet} {
    font-size: ${theme.fontSize.xs};
    font-weight: ${theme.fontWeight.semibold};
    width: 100px;
  }
  ${media.phone} {
    font-size: 10px;
    font-weight: ${theme.fontWeight.semibold};
    height: 35px;
    width: 70px;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto !important;
  }
`;

export const Download = styled.a<bgColor>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 240px;
  height: 55.17px;
  background: ${(props) => (props.bg ? props.bg : theme.colors.primary)};
  border-radius: 10px;
  outline: none;
  padding: 0px 10px;
  border: none;
  color: ${(props) =>
    props.bg === "#fff" ? theme.colors.textBackground : theme.colors.textWhite};
  font-size: ${theme.fontSize.md};
  font-weight: ${theme.fontWeight.bold};
  cursor: ${(props) => (props.loading ? "not-allowed" : "pointer")};
  margin-bottom: 10px;
  text-decoration: none;
  &:hover {
    background: ${theme.colors.bgGradient};
  }

  ${media.tablet} {
    width: 131px;
    height: 48px;
    font-size: ${theme.fontSize.xs};
    font-weight: ${theme.fontWeight.semibold};
  }
  ${media.phone} {
    width: 120px;
    height: 32px;
    font-size: 10px;
    font-weight: ${theme.fontWeight.semibold};
  }
`;

export const Upload = styled.label<bgColor>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 220px;
  height: 55.17px;
  background: ${(props) => (props.bg ? props.bg : theme.colors.primary)};
  border-radius: 34.9206px;
  outline: none;
  border: none;
  color: ${(props) =>
    props.bg === "#fff" ? theme.colors.textBackground : theme.colors.textWhite};
  font-size: ${theme.fontSize.md};
  font-weight: ${theme.fontWeight.bold};
  cursor: ${(props) => (props.loading ? "not-allowed" : "pointer")};

  &:hover {
    background: ${theme.colors.bgGradient};
  }

  ${media.tablet} {
    width: 131px;
    height: 48px;
    font-size: ${theme.fontSize.xs};
    font-weight: ${theme.fontWeight.semibold};
  }
  ${media.phone} {
    width: 104px;
    height: 32px;
    font-size: 10px;
    font-weight: ${theme.fontWeight.semibold};
  }
`;
