import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RouteContainer, SeeMore } from "../components/Generals/RouteStyles";
import UnremittedDetailsModal from "../components/Modals/UnremittedDetailsModal";
import MainNav from "../components/NavBars/MainNav";
import { useAppDispatch, useAppSelector } from "../Redux/app/hooks";
import {
  handleMainnav,
  toggleInnerSidebar,
} from "../Redux/Reducers/navReducer";
import { settlementTabs } from "../utils/subNavs";
import { LoggedInCheck2FAEnabled } from "../Apis/accountProcesing";
import StallSettlements from "../components/Settlement/StallSettlements";
import StallToSettle from "../components/Settlement/StallToSettle";

const Settlement = () => {
  const { currentMainBodyNav } = useAppSelector((state) => state.nav);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const merchantDetails = JSON.parse(
    localStorage.getItem("merchantDetails") || "{}"
  );

  useEffect(() => {
    LoggedInCheck2FAEnabled(merchantDetails?.businessEmail, navigate);
  }, []);

  useEffect(() => {
    dispatch(toggleInnerSidebar(false));
    dispatch(handleMainnav(1));
  }, []);

  return (
    <>
      <MainNav listItem={settlementTabs} />
      <RouteContainer>
        {currentMainBodyNav === 1 ? <StallSettlements /> : <StallToSettle />}
        <UnremittedDetailsModal />
      </RouteContainer>
    </>
  );
};
export default Settlement;
