import React from 'react'
import { RouteContainer } from '../../components/Generals/RouteStyles';
import {
    ModalBlock,
    ModalContainer,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    ModalImage,
    ModalTitle,
    ModalDesc,
    ModalBody,
    BodySubTitle,
    BodyTitle,
    BodyDesc,
    BodyLocation
} from "./ModalStyles";

function Terminate({ image, title, desc, bodySubTitle, bodyTitle, bodyLocation, location, button, bodyDesc, open, hideModal }: any) {
    return (
        <RouteContainer>
            {open && (
                <ModalBlock>
                    <ModalOverlay onClick={() => hideModal()}></ModalOverlay>
                    <ModalContainer>
                        <ModalImage src={image} />
                        <ModalHeader>
                            <ModalTitle>{title}</ModalTitle>
                            <ModalDesc>{desc}</ModalDesc>
                        </ModalHeader>
                        <ModalBody>
                            <BodySubTitle>{bodySubTitle}</BodySubTitle>
                            <BodyTitle>{bodyTitle}</BodyTitle>
                            <BodyDesc>{bodyDesc}</BodyDesc>
                            <BodyLocation>{bodyLocation}</BodyLocation>
                            <BodyDesc>{location}</BodyDesc>
                        </ModalBody>
                        <ModalFooter content="end">{button}</ModalFooter>
                    </ModalContainer>
                </ModalBlock>
            )}
        </RouteContainer>
    )
}

export default Terminate;