import { useEffect, useState } from "react";
import { useAppSelector } from "../../Redux/app/hooks";
import { getTotalBalanceSeries } from "../../utils/chatSeries";
import { getMyBalanceChartOptions } from "../../utils/data";
import AreaChart from "./AreaChart";
import { useAppDispatch } from "./../../Redux/app/hooks";
import { getWithdrawalBalance } from "../../Apis/overview";
import ChartFilter from "../Forms/ChartFilter";
import { getSupportedCurrencies } from "./../../Apis/AnalyticsApiCalls/walletApis";

const WithdrawalBalanceChart = () => {
  const { withdrawalBalance, gettingWithdrawalBalance } = useAppSelector(
    (state) => state.overview
  );
  const { StartTimeStamp, EndTimeStamp, GroupByPeriod } = useAppSelector(
    (state) => state.form
  );
  const [currencies, setCurrencies] = useState();
  const [showFilter, setShowfilters] = useState(false);

  const handleFilterClick = () => {
    setShowfilters(!showFilter);
  };
  const withdrawBalanceChartOptions = getMyBalanceChartOptions(
    withdrawalBalance,
    GroupByPeriod,
    "",
    "Withdrawal",
    handleFilterClick
  );
  const WithdrawalChatSeries = getTotalBalanceSeries(
    withdrawalBalance,
    GroupByPeriod,
    currencies
  );

  useEffect(() => {
    const params = {
      StartTimeStamp,
      EndTimeStamp,
      GroupByPeriod,
    };
    getWithdrawalBalance(params);
  }, [EndTimeStamp, GroupByPeriod]);

  useEffect(() => {
    getSupportedCurrencies(setCurrencies, true);
  }, []);

  return (
    <AreaChart
      series={WithdrawalChatSeries}
      options={withdrawBalanceChartOptions}
      // title={"Withdrawal Balance"}
      loading={gettingWithdrawalBalance}
      filterOptions={showFilter && <ChartFilter />}
    />
  );
};

export default WithdrawalBalanceChart;
