export const sessions = [
    {
        id: 1,
        title: 'Samsung Galaxy Note 20 Ultra 5G',
        description: '192.345.67:3748 - Lagos, Nigeria',
        image: "./assets/images/Sessions/andriod.png"
    },
    {
        id: 2,
        title: 'Window Pc',
        description: '192.345.67:3748 - Lagos, Nigeria',
        image: "./assets/images/Sessions/windows.png"
    },
    {
        id: 3,
        title: 'iPhone 13',
        description: '192.345.67:3748 - Lagos, Nigeria',
        image: "./assets/images/Sessions/apple.png"
    },
];