/* eslint-disable react/jsx-no-useless-fragment */
import { Routes, Route } from "react-router-dom";
import PagenotFound from "../components/PagenotFound";
import ScrollToTop from "../components/ScrollToTop";
import ChangePassword from "../pages/ChangePassword";
import ConfirmOTP from "../pages/ConfirmOTP";
import ConfirmResetOtp from "../pages/ConfirmResetOtp";
import CreateAccount from "../pages/CreateAccount";
import Login from "../pages/Login";
import ResetPassword from "../pages/ResetPasword";
import SignUp from "../pages/SignUp";
import SuccessPage from "../pages/SuccessPage";
import UploadPage from "../pages/UploadDocuments";
import FAAuth from "./../pages/2FAAuth";
import Confirm2FAOtp from "./../pages/Confirm-2FA-otp";

const AuthRoutes = () => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        {/* auth Routes */}
        <Route path="/" element={<FAAuth />} />
        <Route path="/login" element={<Login />} />
        {/* <Route path="/upload-doc" element={<UploadPage />} /> */}
        <Route path="/signup" element={<SignUp />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/create-account" element={<CreateAccount />} />
        <Route path="/confirm-otp" element={<ConfirmOTP />} />
        <Route path="/confirm-reset-otp" element={<ConfirmResetOtp />} />
        <Route path="/confirm-2FA-otp" element={<Confirm2FAOtp />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="*" element={<PagenotFound />} />
      </Routes>
    </>
  );
};

export default AuthRoutes;
