/* eslint-disable no-unused-vars */
import React from "react";
import { useNavigate } from "react-router-dom";
import { Title } from "../components/styles";
import Filledbutton from "../components/Reusables/Buttons/Filledbutton";
import {
  AuthPageBanner,
  AuthPageContainer,
  FormLink,
  Form,
  FormContainer,
  Input,
  InputContainer,
  Label,
  FormFooter,
  FooterText,
  FormLeftContainer,
} from "../components/Generals/LoginStyles";
import { Desc } from "../components/Reusables/Cards/IconCard";
import { useAppDispatch, useAppSelector } from "../Redux/app/hooks";
import {
  errorMessage,
  getFormDetails,
  sending,
} from "../Redux/Reducers/formReducer";
import { validateemail } from "../Helpers/generals";
import { sendCreateAcoountEmail } from "../Apis/accountProcesing";

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { email, error, loading } = useAppSelector((state) => state.form);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    dispatch(errorMessage(""));
    const { name, value } = e.target;
    dispatch(getFormDetails({ name, value }));
  };

  const handleSubmit = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!email || !validateemail(email)) {
      dispatch(errorMessage("Please fill in a valid email"));
    } else {
      sendCreateAcoountEmail(email, navigate);
    }
  };

  return (
    <AuthPageContainer>
      <FormLeftContainer href="https://switchwallet.io">
        <AuthPageBanner src="/assets/images/Forms/Login-banner.png" />
      </FormLeftContainer>
      <FormContainer>
        <Form>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "30px",
            }}
          >
            <Title>Get Started</Title>
            <Desc>
              Create an account to start accepting crypto payments from
              customers globally
            </Desc>
          </div>

          <InputContainer>
            <Label>Email Address</Label>
            <Input
              type="email"
              placeholder="Enter email"
              onChange={handleChange}
              name="email"
            />
          </InputContainer>

          <div style={{ margin: "20px" }}>
            <Filledbutton
              text="Send"
              handleClick={handleSubmit}
              loading={loading}
            />
          </div>
          <FormFooter>
            <FooterText>Already have an account?</FooterText>
            <FormLink to="/">Log in</FormLink>
          </FormFooter>
          <div style={{ marginTop: "20px" }} className="text-red">
            {error}
          </div>
        </Form>
      </FormContainer>
    </AuthPageContainer>
  );
};

export default SignUp;
