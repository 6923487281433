import { ChartFilterContainer } from "../Charts/styles";
import { DateRangePicker } from "./style";
import { getFormDetails } from "../../Redux/Reducers/formReducer";
import { useAppDispatch } from "../../Redux/app/hooks";

const ChartFilter = () => {
  const handleDateChange = (date: any) => {
    dispatch(getFormDetails({ name: "StartTimeStamp", value: date[0].$d }));
    dispatch(getFormDetails({ name: "EndTimeStamp", value: date[1].$d }));
  };
  const dispatch = useAppDispatch();

  return (
    <ChartFilterContainer>
      <DateRangePicker
        onChange={(values: any) => {
          handleDateChange(values);
        }}
        style={{ width: "100%" }}
        width="100%"
      />
      {/* <DateContainer>
        <SqaureFilledbutton
          text="D"
          handleClick={() => {
            dispatch(getFormDetails({ name: "GroupByPeriod", value: 1 }));
          }}
        />
        <SqaureFilledbutton
          text="M"
          handleClick={() => {
            dispatch(getFormDetails({ name: "GroupByPeriod", value: 2 }));
          }}
        />
        <SqaureFilledbutton
          text="Y"
          handleClick={() => {
            dispatch(getFormDetails({ name: "GroupByPeriod", value: 3 }));
          }}
        />
      </DateContainer> */}
    </ChartFilterContainer>
  );
};

export default ChartFilter;
