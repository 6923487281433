import { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import styled from "styled-components";
import { handleModal } from "../../Redux/Reducers/navReducer";
import { clearWithdrawDeposit } from "../../Redux/Reducers/formReducer";
// import { bounce } from "react-animations";
// import Radium, { StyleRoot } from "radium";

const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(32, 66, 184, 0.25);
  z-index: 1000000;
  flex-direction: column;
  overflow-y: auto;
`;

const Close = styled.div`
  text-align: align;
  font-size: 14px;
  border: 4px solid #ff6600;
  border-radius: 50%;
  padding: 5px 10px;
  cursor: pointer;
  margin-bottom: 15px;
  color: #ff6600;
  font-weight: 600;
`;

// const styles = {
//   bounce: {
//     animation: "x 1s",
//     animationName: Radium.keyframes(bounce, "bounce"),
//   },
// };

interface IModal {
  content: any;
  id: number;
  handleClose?: any;
}

const CustomModal: FC<IModal> = ({ content, id, handleClose }) => {
  const { modal } = useAppSelector((state) => state.nav);
  const dispatch = useAppDispatch();
  return (
    <>
      {id === modal && (
        <ModalContainer>
          <Close
            onClick={() => {
              dispatch(handleModal(0));
              dispatch(clearWithdrawDeposit());
              if (handleClose) {
                handleClose();
              }
            }}
          >
            X
          </Close>
          {/* <StyleRoot>
            <div className="test" style={styles.bounce}> */}
          {content}
          {/* </div>
          </StyleRoot> */}
        </ModalContainer>
      )}
    </>
  );
};
export default CustomModal;
