import React from "react";
import SqaureFilledbutton from "../Reusables/Buttons/SqaureFilledButton";
import { Close, FilterContainer, FilterForm } from "./style";
import { currencies, networks, status } from "../../utils/filterSelect";
import {
  colourStyles,
  DatePick,
  Input,
  InputContainer,
  Label,
  SelectBar,
} from "../Generals/LoginStyles";
import { toggleFilterBar } from "../../Redux/Reducers/navReducer";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  // clearFilterForm,
  // clearTableFilterForm,
  filter,
  getFormDetails,
} from "../../Redux/Reducers/formReducer";
import "react-datepicker/dist/react-datepicker.css";

const Filteration = () => {
  const {
    Currency,
    NetworkChain,
    Status,
    TransactionHash,
    WalletAddress,
    StartTimeStamp,
    EndTimeStamp,
  } = useAppSelector((state) => state.form);
  const dispatch = useAppDispatch();
  const { toggleFilter } = useAppSelector((state) => state.nav);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getFormDetails({ name, value }));
  };
  const handleDateChange = (date: any, name: string) => {
    dispatch(getFormDetails({ name, value: date }));
  };
  const handleSelectChange = (option: any, name: string) => {
    if (option.value === "--") dispatch(getFormDetails({ name }));
    else dispatch(getFormDetails({ name, value: option.value }));
  };

  const handleSubmit = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    dispatch(filter(true));
  };

  return (
    <FilterContainer toggleFilter={toggleFilter}>
      <FilterForm>
        <Close onClick={() => dispatch(toggleFilterBar(false))}>x</Close>

        <InputContainer>
          <Label>Transaction Hash</Label>
          <Input
            type="text"
            name="TransactionHash"
            onChange={handleInputChange}
            value={TransactionHash}
          />
        </InputContainer>
        <InputContainer>
          <Label>Wallet Address</Label>
          <Input
            type="text"
            name="WalletAddress"
            onChange={handleInputChange}
            value={WalletAddress}
          />
        </InputContainer>
        <InputContainer>
          <Label>Status</Label>
          <SelectBar
            defaultValue={Status}
            onChange={(data: any) => handleSelectChange(data, "Status")}
            options={status}
            styles={colourStyles}
          />
        </InputContainer>
        <InputContainer>
          <Label>Currency</Label>
          <SelectBar
            defaultValue={Currency}
            onChange={(data: any) => handleSelectChange(data, "Currency")}
            options={currencies}
            styles={colourStyles}
          />
        </InputContainer>
        <InputContainer>
          <Label>Network</Label>
          <SelectBar
            defaultValue={NetworkChain}
            onChange={(data: any) => handleSelectChange(data, "NetworkChain")}
            options={networks}
            styles={colourStyles}
          />
        </InputContainer>
        <InputContainer>
          <Label>Start Date</Label>
          <DatePick
            name="StartTimeStamp"
            dateFormat="dd/MM/yyyy"
            onChange={(date: Date) => handleDateChange(date, "StartTimeStamp")}
            onSelect={(date: Date) => handleDateChange(date, "StartTimeStamp")}
            selected={StartTimeStamp}
          />
        </InputContainer>
        <InputContainer>
          <Label>End Date</Label>
          <DatePick
            name="EndTimeStamp"
            dateFormat="dd/MM/yyyy"
            onChange={(date: Date) => handleDateChange(date, "EndTimeStamp")}
            onSelect={(date: Date) => handleDateChange(date, "EndTimeStamp")}
            selected={EndTimeStamp}
          />
        </InputContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "30px",
          }}
        >
          <SqaureFilledbutton text="Search" handleClick={handleSubmit} />
        </div>
      </FilterForm>
    </FilterContainer>
  );
};

export default Filteration;
