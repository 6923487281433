import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { walletBalancesCols, walletCols } from "../../utils/tablecols";
import { getWalletBalanceRows } from "../../utils/tableRows";
import CustomTable from "../Reusables/Tables/CustomTable";
import { useEffect, useState } from "react";
import {
  getSupportedCurrencies,
  getSupportedNetworks,
} from "../../Apis/AnalyticsApiCalls/walletApis";

const WalletBalancesTable = () => {
  const dispatch = useAppDispatch();
  const { merchantBalances, gettingMerchantBalances } = useAppSelector(
    (state) => state.overview
  );
  const [currencies, setCurrencies] = useState();
  const [networks, setNetworks] = useState();

  const rows = getWalletBalanceRows(
    merchantBalances,
    dispatch,
    currencies,
    networks
  );
  const data = {
    columns: walletBalancesCols,
    rows,
  };

  useEffect(() => {
    getSupportedCurrencies(setCurrencies);
    getSupportedNetworks(setNetworks);
  }, []);

  return (
    <>
      <CustomTable
        data={data}
        loading={gettingMerchantBalances}
        page={"WalletPage"}
        title="Wallet Balances"
        noRecordText="Wallet Balances not available"
      />
    </>
  );
};

export default WalletBalancesTable;
