/* eslint-disable no-nested-ternary */
import React, { useEffect } from "react";
import { getMerchantBalance } from "../Apis/overview";
import Remittance from "../components/Analytics/Remittance";
import Transaction from "../components/Analytics/Transaction";
import Wallet from "../components/Analytics/Wallet";
import { RouteContainer } from "../components/Generals/RouteStyles";
import { useAppDispatch, useAppSelector } from "../Redux/app/hooks";
import { handleSubnav } from "../Redux/Reducers/navReducer";
import { useNavigate } from "react-router-dom";
import { LoggedInCheck2FAEnabled } from "../Apis/accountProcesing";

const Analytics = () => {
  const { currentSubnav } = useAppSelector((state) => state.nav);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const merchantDetails = JSON.parse(
    localStorage.getItem("merchantDetails") || "{}"
  );

  useEffect(() => {
    LoggedInCheck2FAEnabled(merchantDetails?.businessEmail, navigate);
  }, []);
  const Current = currentSubnav;

  useEffect(() => {
    dispatch(handleSubnav(Current));
    getMerchantBalance();
  }, []);
  return (
    <RouteContainer>
      {currentSubnav === 1 ? (
        <Wallet />
      ) : currentSubnav === 2 ? (
        <Transaction />
      ) : (
        <Remittance />
      )}
    </RouteContainer>
  );
};

export default Analytics;
