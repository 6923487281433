import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import navReducer from "../Reducers/navReducer";
import userReducer from "../Reducers/userReducer";
import formReducer from "../Reducers/formReducer";
import overviewReducer from "../Reducers/overviewReducer";
import analyticsReducer from "../Reducers/analyticsReducer";
import walletReducer from "../Reducers/WalletReducer";
import settlementReducer from "../Reducers/settlementReducer";

export const store = configureStore({
  reducer: {
    nav: navReducer,
    user: userReducer,
    form: formReducer,
    overview: overviewReducer,
    analytics: analyticsReducer,
    wallet: walletReducer,
    settlement: settlementReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
