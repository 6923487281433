import { useEffect } from "react";
import api from "../../Apis/baseURL";
import Filledbutton from "../Reusables/Buttons/Filledbutton";
import SqaureFilledbutton from "../Reusables/Buttons/SqaureFilledButton";
import { Container, FlexBetween, FormContainer, SectionTitle } from "./styles";
import { copyHandle } from "../../Helpers/generals";
import {
  errorMessage,
  getFormDetails,
  sending,
} from "../../Redux/Reducers/formReducer";
import { getApiDetails } from "../../Apis/accountProcesing";
import { Input, InputContainer, Label } from "../Generals/LoginStyles";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { useState } from "react";
import { toast } from "react-toastify";
import { SectionDesc } from "./styles";
import { ResetSecretKey, UpdateWebhook } from "../../Apis/settings";

const ApisWebhook = () => {
  const user = JSON.parse(localStorage.getItem("currentUser") || "{}");
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.form);
  const [apiDetails, setApiDetails] = useState<any>("");
  const [InitialApiDetails, setInitialApiDetails] = useState<any>("");
  const [verifyPassword, setVerifyPassword] = useState(false);
  const [viewAll, setViewAll] = useState(true);
  const [verified, setVerified] = useState(false);
  const [password, setPassword] = useState("");
  const [webHookUrl, setWebhookUrl] = useState("");
  const [verificationUrl, setVerificationUrl] = useState("");
  const [publicKey, setPublicKey] = useState("");
  const [env, setEnv] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    dispatch(errorMessage(""));
    const { name, value } = e.target;
    dispatch(getFormDetails({ name, value }));
  };

  const handleView = () => {
    dispatch(sending(true));
    const params = { password };
    api
      .post("v2/auth/password-Check", null, {
        params,
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res: any) => {
        dispatch(sending(false));
        toast.success(res?.data.message);
        getApiDetails(setApiDetails);
        setVerifyPassword(false);
        setVerified(true);
        setViewAll(false);
      })
      .catch((err: any) => {
        if (err?.response) toast.error(err?.response?.data?.message);
        if (err?.message === "Network Error") toast.error(err?.message);
        dispatch(sending(false));
      });
  };

  useEffect(() => {
    getApiDetails(setInitialApiDetails);
  }, []);

  useEffect(() => {
    setWebhookUrl(InitialApiDetails?.callbackUrls?.callbackWebHookUrl);
    setVerificationUrl(InitialApiDetails?.callbackUrls?.verificationUrl);
    setPublicKey(InitialApiDetails?.publicKey);
  }, [InitialApiDetails]);

  useEffect(() => {
    process.env.REACT_APP_API_URL === "https://testnet.switchwallet.io/api/" ||
    process.env.REACT_APP_API_URL === "https://development.switchwallet.io/api/"
      ? setEnv("Testnet")
      : setEnv("Mainnet");
  }, []);

  return (
    <Container>
      <FormContainer>
        {process.env.REACT_APP_API_URL ===
          "https://testnet.switchwallet.io/api/" ||
        process.env.REACT_APP_API_URL ===
          "https://development.switchwallet.io/api/" ? (
          <>
            <FlexBetween style={{ marginBottom: "30px", marginTop: "30px" }}>
              <div>
                <SectionTitle>{env} API Configuration</SectionTitle>
              </div>
              {/* <Filledbutton text="Regenrate" /> */}
            </FlexBetween>
          </>
        ) : (
          <FlexBetween style={{ marginBottom: "30px" }}>
            <div>
              <SectionTitle>Mainnet API Configuration</SectionTitle>
            </div>
            {/* <Filledbutton text="Regenrate" /> */}
          </FlexBetween>
        )}

        <div style={{ width: "70%" }}>
          {/* {viewAll && (
            <SqaureFilledbutton
              text="View All"
              // icon="/assets/icons/copy.svg"
              handleClick={() => setVerifyPassword(true)}
            />
          )} */}

          {verifyPassword && (
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Input
                type="text"
                placeholder="Verify Password"
                name="password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <SqaureFilledbutton
                text="Verify"
                // icon="/assets/icons/copy.svg"
                handleClick={handleView}
                loading={loading}
              />
            </div>
          )}
        </div>
        <SectionDesc className="text-red">
          You can only view your secret key once, so copy it to somewhere safe.
          if you forget it, you have to reset by clicking the reset button
        </SectionDesc>
        <InputContainer>
          <Label>Secret Key - {env} </Label>
          <FlexBetween>
            {/* <Input
              type="text"
              value={
                verified
                  ? apiDetails?.privateKey || "N/A"
                  : "********************************"
              }
              name="secretKey"
              onChange={handleChange}
            /> */}
            {verified
              ? apiDetails?.privateKey || "N/A"
              : "********************************"}
            {apiDetails?.privateKey && (
              <SqaureFilledbutton
                text="copy"
                icon="/assets/icons/copy.svg"
                handleClick={() => copyHandle(apiDetails?.privateKey)}
              />
            )}
            <SqaureFilledbutton
              text="Generate New Secret Key"
              // icon="/assets/icons/copy.svg"
              handleClick={() => ResetSecretKey(setApiDetails, setVerified)}
              loading={loading}
            />
          </FlexBetween>
        </InputContainer>
        <InputContainer>
          <Label>Public Key - {env}</Label>
          <FlexBetween>
            <Input
              type="text"
              value={apiDetails?.publicKey || publicKey || "N/A"}
              name="publicKey"
              onChange={handleChange}
            />
            {(apiDetails?.publicKey || publicKey) && (
              <SqaureFilledbutton
                text="copy"
                icon="/assets/icons/copy.svg"
                handleClick={() =>
                  copyHandle(apiDetails?.publicKey || publicKey)
                }
              />
            )}
          </FlexBetween>
        </InputContainer>
        <InputContainer>
          <Label>Webhook URL - {env}</Label>
          <FlexBetween>
            <Input
              type="text"
              value={webHookUrl}
              name="webHookUrl"
              onChange={(e: any) => {
                e.preventDefault();
                setWebhookUrl(e.target.value);
              }}
            />
            <SqaureFilledbutton
              text="Update"
              // icon="/assets/icons/copy.svg"
              handleClick={() => UpdateWebhook(webHookUrl)}
              loading={loading}
            />
          </FlexBetween>
        </InputContainer>
        <InputContainer>
          <Label>Verification URL - {env}</Label>
          <FlexBetween>
            <Input
              type="text"
              value={verificationUrl}
              name="webHookUrl"
              onChange={(e: any) => {
                e.preventDefault();
                setVerificationUrl(e.target.value);
              }}
            />
            <SqaureFilledbutton
              text="Update"
              // icon="/assets/icons/copy.svg"
              handleClick={() => UpdateWebhook("", verificationUrl)}
              loading={loading}
            />
          </FlexBetween>
        </InputContainer>
        {/* <Filledbutton text="Update" handleClick={() => UpdateWebhook()} /> */}
      </FormContainer>
    </Container>
  );
};

export default ApisWebhook;
