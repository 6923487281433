import styled from "styled-components";
import { media } from "../../respnsive";
import { theme } from "./../../theme";

export const AccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 28px;
  padding: 20px;
  overflow: hidden;

  img {
    cursor: pointer;
  }

  ${media.tablet} {
    width: 100%;
  }
  ${media.phone} {
    width: 100%;
    gap: 5%;
  }
  ${media.phone} {
    width: 100%;
    gap: 5%;
  }
  ${media.phone} {
    width: 100%;
    gap: 5%;
  }
`;

export const TotalBalance = styled.div`
  display: flex;
  flex-direction: column;
  align-itens: center;
  gap: 10px;

  p {
    font-size: 120%;
    font-weight: bold;
  }
`;

export const TotalTitle = styled.div`
  font-size: 140%;
  font-weight: 600;
`;

// export const TotalBalance = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-itens: center;
//   gap: 10px;

//   p {
//     font-size: 120%;
//     font-weight: bold;
//   }
// `;

// export const TotalTitle = styled.div`
//   font-size: 140%;
//   font-weight: 600;
// `;

export const Body = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  // ${media.phone} {
  //   margin-top: 0%;
  // }
`;

export const DirectionButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(32, 66, 184, 0.11);
  cursor: pointer;
  transition: all 0.5s linear;

  &:hover {
    background: ${theme.colors.bgGradient};
    color: #ffffff;

    svg {
      color: #ffffff;
    }
  }
`;

export const CountContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const CardTitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  // margin: 20px 15px;

  ${media.phone} {
    margin: 10px;
  }
`;

interface TransformProp {
  translateX?: string;
}

export const SlideContainer = styled.div<TransformProp>`
  display: flex;
  gap: 10px;
  width: auto;
  align-items: center;
  height: 100%;
  transform: ${(props) => `translateX(${props.translateX})`};
  transition: transform 0.5s ease-in-out;
`;

export const BalanceCardContainer = styled.div`
  width: 480px;
  height: 100%;
  display: flex;
  overflow-x: scroll;
  gap: 10px;

  ${media.tablet} {
    width: 320px;
  }
  ${media.phone} {
    width: 320px;
  }
`;

interface BalanceCardProp {
  width?: string;
  height?: string;
}

export const BalanceCard = styled.div<BalanceCardProp>`
  position: relative;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin-bottom: 10px;
  // cursor: pointer;

  background: linear-gradient(
    141.55deg,
    rgba(255, 102, 0, 0.85) -3.79%,
    rgba(32, 66, 184, 0.85) 82.82%
  );
  border-radius: 26px;
  padding: 10px 20px;

  img.bg-logo {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 1;
  }

  svg {
    z-index: 99;
    cursor: pointer;
  }
`;

export const HorizontalDivider = styled.div`
  width: 2px;
  height: 80px;
  left: 407px;
  top: 130px;
  background: rgba(32, 66, 184, 0.11);
`;

export const BalanceCardHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  img {
    z-index: 99;
  }
`;

export const BalanceCardHeaderTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  width: 100%;

  &.hide {
    position: relative;
    width: 5px;
  }

  &.hide::before {
    content: "";
    width: 2px;
    height: 120px;
    transform: rotate(45deg);
    // position: absolute;
    background: "#fff";
  }
`;
export const BalanceCardHeaderType = styled.div`
  font-weight: 500;
  font-size: 8.11292px;
  line-height: 11px;
  color: rgba(255, 255, 255, 0.39);
  width: 100%;
`;

export const Balance = styled.h1<BalanceCardProp>`
  font-style: normal;
  font-weight: 500;
  font-size: 150%;
  line-height: 33px;
  color: #ffffff;
  width: 100%;
`;

export const HideIcon = styled.div`
  position: relative;
  display: flex;
  cursor: pointer;

  span {
    background-color: white;
    position: absolute;
    width: 1px;
    height: 20px;
    transform: rotate(45deg);
    left: 40%;
    top: -2px;
  }
`;

export const TCountTitle = styled.div`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #52575c;
`;
export const TCount = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #25282b;
`;
