import moment from "moment";
import React, { useEffect, useState } from "react";
import { getUnRemittanceTransactions } from "../../Apis/AnalyticsApiCalls/walletApis";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { unRemittedCols, unRemittedExportCols } from "../../utils/tablecols";
import { getUnRemittanceRows } from "../../utils/TableRows/RemittanceRows";
import UnremittedFilter from "../Forms/UnremittedFilter";
import CustomTable from "../Reusables/Tables/CustomTable";
import { ExportButton } from "../Reusables/Buttons/styles";

const UnRemittanceTable = () => {
  const {
    StartTimeStamp,
    EndTimeStamp,
    unRemittedCurrency,
    unRemittedNetworkChain,
    unRemittedStatus,
    unRemittedTransactionHash,
    unRemittedWalletAddress,
    unRemittedPage,
    unRemittedPageSize,
    unRemittedStartTimeStamp,
    unRemittedEndTimeStamp,
  } = useAppSelector((state) => state.form);
  const [showAddress, setShowAddress] = useState(false);
  const [selectedRow, setSelectedRow] = useState(0);
  const { loading } = useAppSelector((state) => state.form);
  const { unRemittanceTransactions, gettingUnRemittanceTransactions } =
    useAppSelector((state) => state.wallet);
  const { gettingUnRemittanceDetails } = useAppSelector(
    (state) => state.analytics
  );
  const rows = getUnRemittanceRows(
    unRemittanceTransactions,
    showAddress,
    setShowAddress,
    selectedRow,
    setSelectedRow,
    loading,
    gettingUnRemittanceDetails
  );

  const unRemittancedata = {
    columns: unRemittedCols,
    rows,
  };
  useEffect(() => {
    const params = {
      Currency: unRemittedCurrency,
      NetworkChain: unRemittedNetworkChain,
      WalletAddress: unRemittedWalletAddress,
      Page: unRemittedPage,
      PageSize: unRemittedPageSize,
      StartTimeStamp: StartTimeStamp && moment(unRemittedStartTimeStamp).unix(),
      EndTimeStamp: EndTimeStamp && moment(unRemittedEndTimeStamp).unix(),
    };
    getUnRemittanceTransactions(params);
  }, [
    unRemittedCurrency,
    unRemittedNetworkChain,
    unRemittedStatus,
    unRemittedTransactionHash,
    unRemittedWalletAddress,
    unRemittedPage,
    unRemittedPageSize,
    unRemittedStartTimeStamp,
    unRemittedEndTimeStamp,
  ]);

  const exportData = {
    headers: unRemittedExportCols,
    data: rows,
  };

  return (
    <>
      <CustomTable
        data={unRemittancedata}
        pageCount={unRemittanceTransactions?.totalPages}
        currentPage={unRemittanceTransactions?.currentPage}
        loading={gettingUnRemittanceTransactions}
        page="unRemittedPage"
        title="Unremitted Transactions"
        searchOptions={<UnremittedFilter />}
        noRecordText="Unremitted Records not available"
        filter={
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              gap: "10px",
            }}
          >
            <ExportButton
              data={exportData?.data}
              headers={exportData?.headers}
              filename={`Unremitted-Transactions-page(${unRemittanceTransactions?.currentPage})`}
            >
              Export CSV
            </ExportButton>
          </div>
        }
      />
    </>
  );
};

export default UnRemittanceTable;
