import styled from "styled-components";
import { media } from "../../respnsive";

export const ContentTitle = styled.div`
  position: relative;
  width: 340px;
  height: 120px;
  left: 0px;
  top: 1px;
  background: #ffffff;
  border-radius: 10px 0px 0px 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  align-items: center;
  letter-spacing: 0.29031px;
  color: #2042b8;
  padding: 30px;

  &::before {
    content: "";
    position: absolute;
    width: 11px;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #2042b8;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    ${media.phone} {
      width: 5px;
    }
    ${media.tablet} {
      width: 7px;
    }
  }

  ${media.tablet} {
    font-size: 12px;
    width: 150px;
    line-height: 14px;
    padding: 10px;
    height: 50px;
  }
  ${media.phone} {
    font-size: 10px;
    width: 100px;
    line-height: 14px;
    padding: 10px;
    height: 50px;
  }
`;

export const ContentTitlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 120px);
  width: 340px;
  gap: 10px;
  background: transparent;
`;

export const SingleTitleContainer = styled.div`
  width: 312px;
  height: 120px;
  left: 0px;
  top: 135px;
  background: rgba(32, 66, 184, 0.05);
  border-radius: 10px;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  align-items: center;
  letter-spacing: 0.29031px;
  padding: 20px;
  color: #52575c;
  cursor: pointer;

  ${media.tablet} {
    font-size: 10px;
    width: 120px;
    line-height: 14px;
    padding: 10px;
    height: 50px;
    border-radius: 5px;
  }
  ${media.phone} {
    font-size: 10px;
    width: 100px;
    line-height: 14px;
    padding: 10px;
    height: 50px;
    border-radius: 5px;
  }
`;

export const ContentDescContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 692px;
  height: 790px;
  background: #ffffff;
  box-shadow: 0px 6px 6px rgba(32, 66, 184, 0.21);
  padding: 30px;

  ${media.tablet} {
    font-size: 10px;
    width: 600px;
    line-height: 14px;
    padding: 10px;
    border-radius: 5px;
  }
  ${media.phone} {
    font-size: 10px;
    width: 270px;
    line-height: 14px;
    padding: 10px;
    border-radius: 5px;
  }
`;

export const ContentDesc = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 30px;
  width: 588px;
  height: 691px;
  background: rgba(32, 66, 184, 0.05);
  border-radius: 10px;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.29031px;
  color: #000000;
  overflow-y: auto;

  ${media.phone} {
    font-size: 10px;
    line-height: 14px;
    padding: 10px;
  }
`;
