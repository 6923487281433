import React from "react";
import { DotLoader } from "react-spinners";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 20px;
  color: #252525;
`;

const Loader = () => {
  return (
    <Container>
      <DotLoader color="#ff6600" size={30} />
    </Container>
  );
};

export default Loader;
