import React from 'react'
import { RouteContainer } from '../../components/Generals/RouteStyles';
import {
    ModalBlock,
    ModalContainer,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    ModalImage,
    ModalTitle,
    ModalDesc
} from "./ModalStyles";

function Revoke({ image, title, desc, cancel, revoke, active, hideModal }: any) {
    return (
        <RouteContainer>
            {active && (
                <ModalBlock>
                    <ModalOverlay onClick={() => hideModal()}></ModalOverlay>
                    <ModalContainer>
                        <ModalImage src={image} />
                        {/* <ModalHeader> */}
                        <ModalTitle>{title}</ModalTitle>
                        {/* </ModalHeader> */}
                        <ModalDesc>{desc}</ModalDesc>
                        <ModalFooter content='center'>
                            {revoke}
                            {cancel}
                        </ModalFooter>
                    </ModalContainer>
                </ModalBlock>
            )}
        </RouteContainer>
    )
}

export default Revoke;