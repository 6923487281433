import { toast } from "react-toastify";
import {
  setGettingRemittanceCurrencyTimeMetrics,
  setGettingUnRemittanceDetails,
  setGettingRemittanceFeeMetrics,
  setGettingRemittanceNetworkTimeMetrics,
  setGettingRemittanceTimeMetrics,
  setGettingUnRemittanceTrans,
  setRemittanceCurrencyTimeMetrics,
  setUnRemittanceDetails,
  setRemittanceFeeMetrics,
  setRemittanceNetworkTimeMetrics,
  setRemittanceTimeMetrics,
  setUnRemittanceTrans,
  setGettingMerchantUnremitedBal,
  setMerchantUnremitedBal,
} from "../../Redux/Reducers/analyticsReducer";
import { filter, sending } from "../../Redux/Reducers/formReducer";
import { handleModal } from "../../Redux/Reducers/navReducer";
import api from "../baseURL";
import { store } from "../../Redux/app/store";

export const getWalletsGenerated = async () => {
  return async () => {
    await api
      .get("v2/analytics/wallets-generation-time-series-metrics", {})
      .then((res) => {
        const { data } = res?.data;
        if (data) {
          // store.dispatch(setMerchantBalances(data));
        }
      });
  };
};

export const getRemitanceTimeMetrics = async (
  parameters?: any
) => {
  store.dispatch(setGettingRemittanceTimeMetrics(true));

  const params = parameters;
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get(`v2/analytics/remittance-time-series-metrics`, {
      params,
    })
    .then((res) => {
      const { data } = res?.data;
      if (data) {
        store.dispatch(setRemittanceTimeMetrics(data));
        store.dispatch(setGettingRemittanceTimeMetrics(false));
      }
      store.dispatch(filter(false));
    })
    .catch(() => {
      store.dispatch(filter(false));
      store.dispatch(setGettingRemittanceTimeMetrics(false));
    });
};

export const getRemitanceNetworkTimeMetrics = async (
  parameters?: any
) => {
  store.dispatch(setGettingRemittanceNetworkTimeMetrics(true));

  const params = parameters;
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get(`v2/analytics/remittance-time-series-metrics`, {
      params,
    })
    .then((res) => {
      const { data } = res?.data;
      if (data) {
        store.dispatch(setRemittanceNetworkTimeMetrics(data));
        store.dispatch(setGettingRemittanceNetworkTimeMetrics(false));
      }
      store.dispatch(filter(false));
    })
    .catch(() => {
      store.dispatch(filter(false));
      store.dispatch(setGettingRemittanceNetworkTimeMetrics(false));
    });
};

export const getRemitanceCurrencyTimeMetrics = async (
  parameters?: any
) => {
  store.dispatch(setGettingRemittanceCurrencyTimeMetrics(true));

  const params = parameters;
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get(`v2/analytics/remittance-time-series-metrics`, {
      params,
    })
    .then((res) => {
      const { data } = res?.data;
      if (data) {
        store.dispatch(setRemittanceCurrencyTimeMetrics(data));
        store.dispatch(setGettingRemittanceCurrencyTimeMetrics(false));
      }
      store.dispatch(filter(false));
    })
    .catch(() => {
      store.dispatch(filter(false));
      store.dispatch(setGettingRemittanceCurrencyTimeMetrics(false));
    });
};

export const getRemitanceFeeMetrics = async (
  parameters?: any
) => {
  store.dispatch(setGettingRemittanceFeeMetrics(true));

  const params = parameters;
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get(`v2/analytics/remittance-fee-metrics`, {
      params,
    })
    .then((res) => {
      const { data } = res?.data;
      if (data) {
        store.dispatch(setRemittanceFeeMetrics(data));
        store.dispatch(setGettingRemittanceFeeMetrics(false));
      }
      store.dispatch(filter(false));
    })
    .catch(() => {
      store.dispatch(filter(false));
      store.dispatch(setGettingRemittanceFeeMetrics(false));
    });
};

export const getUnRemittanceTrans = async (
  parameters?: any
) => {
  store.dispatch(setGettingUnRemittanceTrans(true));

  const params = parameters;
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get(`v2/analytics/unremitted-transaction`, {
      params,
    })
    .then((res) => {
      const { data } = res?.data;
      if (data) {
        store.dispatch(setUnRemittanceTrans(data));
        store.dispatch(setGettingUnRemittanceTrans(false));
      }
      store.dispatch(filter(false));
    })
    .catch(() => {
      store.dispatch(filter(false));
      store.dispatch(setGettingUnRemittanceTrans(false));
    });
};

export const getUnRemittanceDetails = async (
  walletAddress: string,
  currencyId: string
) => {
  store.dispatch(setGettingUnRemittanceDetails(true));

  const params: any = { currencyId, walletAddress };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get(`v2/analytics/deposit-remittance-detail`, {
      params,
    })
    .then((res) => {
      if (res?.data?.remitanceDetail?.records.length > 0) {
        store.dispatch(setUnRemittanceDetails(res?.data?.remitanceDetail));
        store.dispatch(setGettingUnRemittanceDetails(false));
        store.dispatch(handleModal(5));
      } else {
        toast.error("No Records");
        store.dispatch(setGettingUnRemittanceDetails(false));
      }
      store.dispatch(filter(false));
    })
    .catch(() => {
      store.dispatch(filter(false));
      store.dispatch(setGettingUnRemittanceDetails(false));
    });
};

export const getMerchatnUnremittedBalance = async () => {
  store.dispatch(setGettingMerchantUnremitedBal(true));
  await api
    .get(`v1/walletBalance/merchant-Unremitted-balances`, {})
    .then((res) => {
      const { data } = res?.data;
      if (data) {
        store.dispatch(setMerchantUnremitedBal(data));
        store.dispatch(setGettingMerchantUnremitedBal(false));
      }
      store.dispatch(filter(false));
    })
    .catch(() => {
      store.dispatch(filter(false));
      store.dispatch(setGettingMerchantUnremitedBal(false));
    });
};

export const checkTransReceiptByHash = async (
  transactionHah: string,
) => {
  store.dispatch(sending(true));
  const params = { transactionHah };
  await api
    .get("v2/analytics/remittance/CheckTransactionRecieptByHash", { params })
    .then((res) => {
      toast.success("Successful");
      store.dispatch(sending(false));
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    })
    .catch((err) => {
      toast.error(err?.data?.message);
      store.dispatch(sending(false));
    });
};
