import { useEffect } from "react";
import GradientCard from "../Reusables/Cards/GradientCard";
import { SectionTitle, SectionContainer, CardContainer } from "./styles";
import { FlexContainer } from "../Generals/RouteStyles";
import { LeftContent, RightContent } from "../styles";
import ScrollToTop from "../ScrollToTop";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  getDepositCurrencyBalance,
  getDepositNetworkBalance,
  getWalletsGenerated,
} from "../../Apis/AnalyticsApiCalls/walletApis";
import CurrencyChart from "../Charts/CurrencyChart";
import NetworkChart from "../Charts/NetworkChart";
import WalletGeneratedChart from "../Charts/WalletGeneraedChart";
import { clearFilterForm, filter } from "../../Redux/Reducers/formReducer";
import UnRemittanceChart from "../Charts/UnremittanceChart";
import { getUnRemittanceTrans } from "../../Apis/AnalyticsApiCalls/remittanceApis";
import { getMerchantBalance } from "./../../Apis/overview";

const Wallet = () => {
  const { totalBalance, gettingMerchantBalances } = useAppSelector(
    (state) => state.overview
  );
  const { filterMode } = useAppSelector((state) => state.form);
  const { StartTimeStamp, EndTimeStamp, GroupByPeriod } = useAppSelector(
    (state) => state.form
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    const params = {
      StartTimeStamp,
      EndTimeStamp,
      GroupByPeriod,
    };
    const networkParams = {
      StartTimeStamp,
      EndTimeStamp,
      GroupByPeriod,
      CurrencyOrNetwork: 2,
    };
    getWalletsGenerated(params);
    getDepositCurrencyBalance(params);
    getDepositNetworkBalance(networkParams);
    getUnRemittanceTrans(params);
  }, [filterMode]);

  useEffect(() => {
    dispatch(clearFilterForm());
    dispatch(filter(true));
    getMerchantBalance();
  }, []);

  return (
    <div>
      <ScrollToTop />
      <SectionContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <SectionTitle>Wallet</SectionTitle>
          {/* <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <SqaureFilledbutton
              text="filter"
              icon="/assets/icons/Table/filter.svg"
              handleClick={() => dispatch(toggleFilterChartBar(true))}
            />
          </div> */}
        </div>

        <CardContainer>
          <GradientCard
            title="Total Balance"
            // subTitle="PREMIUM ACCOUNT"
            balance={totalBalance.toFixed(2) + " " + "USD"}
            icon=""
            fullSize={true}
            loading={gettingMerchantBalances}
          />
        </CardContainer>

        <FlexContainer>
          <LeftContent padding="0 0">
            <WalletGeneratedChart />
          </LeftContent>
          <RightContent padding="0 0">
            <CurrencyChart />
          </RightContent>
        </FlexContainer>
        <FlexContainer>
          <LeftContent padding="0 0">
            <NetworkChart />
          </LeftContent>
          <RightContent padding="0 0">
            <UnRemittanceChart />
          </RightContent>
        </FlexContainer>
      </SectionContainer>
    </div>
  );
};

export default Wallet;
