import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ResetUserPassword } from "../../Apis/accountProcesing";
import { Check2FAEnabled, Enabled2FA } from "../../Apis/settings";
import { validatePassword } from "../../Helpers/generals";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { errorMessage, getFormDetails } from "../../Redux/Reducers/formReducer";
import { Input, InputContainer, Label } from "../Generals/LoginStyles";
import ConfirmOTPModal from "../Modals/ConfirmOTPModal";
import QRCodeSetupModal from "../Modals/QRCodeSetupModal";
import Filledbutton from "../Reusables/Buttons/Filledbutton";
import OnAndOff from "../Reusables/Inputs/OnAndOff";
// import OnAndOff from "../Reusables/Inputs/OnAndOff";
import {
  Container,
  FlexBetween,
  // FlexBetween,
  FormContainer,
  LabelDesc,
  // LabelDesc,
  SectionDesc,
  SectionTitle,
} from "./styles";

const Security = () => {
  const dispatch = useAppDispatch();
  const { error, password, confirmPassword, loading } = useAppSelector(
    (state) => state.form
  );
  const user = JSON.parse(localStorage.getItem("currentUser") || "{}");
  const navigate = useNavigate();
  const { is2FAEnabled } = useAppSelector((state) => state.user);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    dispatch(errorMessage(""));
    const { name, value } = e.target;
    dispatch(getFormDetails({ name, value }));
  };

  const handleSubmit = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    dispatch(errorMessage(""));
    if (!password || !confirmPassword) {
      dispatch(errorMessage("Please fill all fields"));
    } else if (password !== confirmPassword) {
      dispatch(errorMessage("passwords doesn't match"));
    } else if (!validatePassword(password)) {
      dispatch(
        errorMessage(
          "* min 8 letter password, with at least a symbol, upper and lower case letters and a number"
        )
      );
    } else {
      dispatch(errorMessage(""));
      ResetUserPassword(user?.email, navigate, "security");
    }
  };

  useEffect(() => {
    Check2FAEnabled(user?.email);
  }, []);

  return (
    <Container>
      <FormContainer>
        <div style={{ marginBottom: "30px" }}>
          <SectionTitle>Two-factor authentication</SectionTitle>
          {/* <SectionDesc>
            We’ll ask for a login code in addition to your password when you log
            in a device we don’t recognize.
          </SectionDesc> */}
        </div>
        <FlexBetween>
          <InputContainer>
            <Label>Authentication App</Label>
            <LabelDesc>Google auth app</LabelDesc>
          </InputContainer>
          <OnAndOff
            active={is2FAEnabled}
            onClick={() => !is2FAEnabled && Enabled2FA(user.email)}
          />
        </FlexBetween>
        {/* <FlexBetween>
				<InputContainer>
					<Label>Another Email</Label>
					<LabelDesc>Email to send verification link</LabelDesc>
				</InputContainer>
				<OnAndOff />
			</FlexBetween>
			<FlexBetween>
				<InputContainer>
					<Label>SMS Recovery</Label>
					<LabelDesc>We’ll send a login code to your phone number</LabelDesc>
				</InputContainer>
				<OnAndOff />
			</FlexBetween> */}
        <div style={{ marginBottom: "30px" }}>
          <SectionTitle>Password</SectionTitle>
          <SectionDesc>
            We’ll ask for a login code in addition to your password when you log
            in a device we don’t recognize.
          </SectionDesc>
        </div>
        <InputContainer>
          <Label>Current Password</Label>
          <Input type="password" />
        </InputContainer>
        <InputContainer>
          <Label>New Password</Label>
          <Input type="password" name="password" onChange={handleChange} />
        </InputContainer>
        <InputContainer>
          <Label>Confirm Pew password</Label>
          <Input
            type="password"
            name="confirmPassword"
            onChange={handleChange}
          />
        </InputContainer>
        <Filledbutton
          text="save"
          handleClick={handleSubmit}
          loading={loading}
        />
        <div className="text-red" style={{ marginTop: "10px" }}>
          {error}
        </div>
      </FormContainer>
      <ConfirmOTPModal />
      <QRCodeSetupModal />
    </Container>
  );
};

export default Security;
