export const documentations = [
  {
    id: 1,
    title: "Lorem ipsum dolor sit amet consectetur.",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad, dolore repellendus sit natus, ducimus molestias quae quisquam harum earum fugiat consequatur laborum sunt dolores excepturi id voluptatem suscipit sapiente consequuntur aperiam deleniti quas porro rem! Porro quia amet debitis ut voluptatum sint, ipsum obcaecati iste at consequuntur distinctio facilis tempore.",
  },
  {
    id: 1,
    title: "Lorem ipsum dolor ",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad, dolore repellendus sit natus, ducimus molestias quae quisquam harum earum fugiat consequatur laborum sunt dolores excepturi id voluptatem suscipit sapiente consequuntur aperiam deleniti quas porro rem! Porro quia amet debitis ut voluptatum sint, ipsum obcaecati iste at consequuntur distinctio facilis tempore.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad, dolore repellendus sit natus, ducimus molestias quae quisquam harum earum fugiat consequatur laborum sunt dolores excepturi id voluptatem suscipit sapiente consequuntur aperiam deleniti quas porro rem! Porro quia amet debitis ut voluptatum sint, ipsum obcaecati iste at consequuntur distinctio facilis tempore.",
  },
  {
    id: 1,
    title: "Lorem ipsum dolor sit amet consectetur.",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad, dolore repellendus sit natus, ducimus molestias quae quisquam harum earum fugiat consequatur laborum sunt dolores excepturi id voluptatem suscipit sapiente consequuntur aperiam deleniti quas porro rem! Porro quia amet debitis ut voluptatum sint, ipsum obcaecati iste at consequuntur distinctio facilis tempore.",
  },
  {
    id: 1,
    title: "Lorem ipsum yooo",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad, dolore repellendus sit natus, ducimus molestias quae quisquam harum earum fugiat consequatur laborum sunt dolores excepturi id voluptatem suscipit sapiente consequuntur aperiam deleniti quas porro rem! Porro quia amet debitis ut voluptatum sint, ipsum obcaecati iste at consequuntur distinctio facilis tempore.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad, dolore repellendus sit natus, ducimus molestias quae quisquam harum earum fugiat consequatur laborum sunt dolores excepturi id voluptatem suscipit sapiente consequuntur aperiam deleniti quas porro rem! Porro quia amet debitis ut voluptatum sint, ipsum obcaecati iste at consequuntur distinctio facilis tempore.",
  },
  {
    id: 1,
    title: "Lorem ipsum dolor sit amet consectetur.",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad, dolore repellendus sit natus, ducimus molestias quae quisquam harum earum fugiat consequatur laborum sunt dolores excepturi id voluptatem suscipit sapiente consequuntur aperiam deleniti quas porro rem! Porro quia amet debitis ut voluptatum sint, ipsum obcaecati iste at consequuntur distinctio facilis tempore.",
  },
];
