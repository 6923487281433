import {
  colourStyles,
  Form,
  Input,
  InputContainer,
  SelectBar,
} from "../Generals/LoginStyles";
import CustomModal from "./CustomModal";
import { Label } from "./../Generals/LoginStyles";
import { useAppDispatch, useAppSelector } from "./../../Redux/app/hooks";
import { getFormDetails } from "../../Redux/Reducers/formReducer";
import SqaureFilledbutton from "../Reusables/Buttons/SqaureFilledButton";
import { InitiateWithdrawal } from "./../../Apis/transactions";
import { useState, useEffect } from "react";
import api from "../../Apis/baseURL";
import { getSupportedNetworks } from "../../Apis/AnalyticsApiCalls/walletApis";

const WithdrawModal = () => {
  const dispatch = useAppDispatch();
  const {
    destinationAddress,
    withdrawAmount,
    withdrawCurrency,
    withdrawNetwork,
    loading,
  } = useAppSelector((state) => state.form);
  const [currencies, setCurrencies] = useState([{ label: "--", value: "--" }]);
  const [networks, setNetworks] = useState([]);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getFormDetails({ name, value }));
  };

  const handleSelectChange = async (option: any, name: string) => {
    if (name === "withdrawNetwork") {
      await api
        .get(
          `v1/merchant/Get/CurrencyByNetwork?networkChain=${option.value}`,
          {}
        )
        .then((res: any) => {
          const result = res?.data?.data.map((item: any) => {
            return {
              label: item?.name,
              value: item?.symbol,
            };
          });
          setCurrencies(result);
        });
    }
    if (option.value === "--") dispatch(getFormDetails({ name }));
    else dispatch(
      getFormDetails({
        name,
        value: { label: option?.label, value: option.value },
      })
    );
  };

  const handleSubmit = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (
      destinationAddress &&
      withdrawAmount &&
      withdrawCurrency &&
      withdrawNetwork
    ) {
      InitiateWithdrawal(
        destinationAddress,
        Number(withdrawAmount),
        withdrawCurrency?.value,
        withdrawNetwork?.value,
      );
    }
  };

  useEffect(() => {
    getSupportedNetworks(setNetworks);
  }, []);
  return (
    <CustomModal
      id={4}
      content={
        <Form>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              //   marginBottom: "30px",
              width: "100%",
            }}
          >
            <InputContainer>
              <Label>Destination</Label>
              <Input
                type="text"
                name="destinationAddress"
                onChange={handleInputChange}
                defaultValue={destinationAddress}
              />
            </InputContainer>
            <InputContainer>
              <Label>Amount</Label>
              <Input
                type="number"
                name="withdrawAmount"
                onChange={handleInputChange}
                min="1"
                defaultValue={withdrawAmount}
              />
            </InputContainer>
            <InputContainer>
              <Label>Network</Label>
              <SelectBar
                defaultValue={withdrawNetwork}
                onChange={(data: any) =>
                  handleSelectChange(data, "withdrawNetwork")
                }
                options={networks}
                styles={colourStyles}
              />
            </InputContainer>
            <InputContainer>
              <Label>Currency</Label>
              <SelectBar
                defaultValue={withdrawCurrency}
                onChange={(data: any) =>
                  handleSelectChange(data, "withdrawCurrency")
                }
                options={currencies}
                styles={colourStyles}
              />
            </InputContainer>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <SqaureFilledbutton
              text="Withdraw"
              handleClick={handleSubmit}
              loading={loading}
            />
          </div>
        </Form>
      }
    />
  );
};

export default WithdrawModal;
