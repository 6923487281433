import ScrollToTop from "../ScrollToTop";
import { SectionContainer, SectionTitle } from "./styles";
import { FlexContainer } from "../Generals/RouteStyles";
import { LeftContent, RightContent } from "../styles";
import CustomTable from "../Reusables/Tables/CustomTable";
import { CurrencytrasactionAnalysis } from "../../utils/tablecols";
import SmallSummaryCard from "../Reusables/Cards/SmallSummaryCard";
import CurrencyChart from "../Charts/CurrencyChart";
import NetworkChart from "../Charts/NetworkChart";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { useEffect } from "react";
import {
  getDepositCurrencyBalance,
  getDepositNetworkBalance,
  getWalletsGenerated,
} from "../../Apis/AnalyticsApiCalls/walletApis";
import { getTransactionMetrics } from "../../Apis/AnalyticsApiCalls/transactionsApis";
import Filteration from "../Forms/Filteration";
import {
  getDepositTransactionRows,
  getWithdrawalsTransactionRows,
} from "../../utils/tableRows";
import { clearFilterForm, filter } from "../../Redux/Reducers/formReducer";

const Transaction = () => {
  const dispatch = useAppDispatch();
  const { filterMode } = useAppSelector((state) => state.form);
  const {
    gettingTransactionMetrics,
    transactionMetrics,
    totalDepositVolumeInUSD,
    totalWithdrawalVolumeInUSD,
  } = useAppSelector((state) => state.analytics);
  const { StartTimeStamp, EndTimeStamp, GroupByPeriod } = useAppSelector(
    (state) => state.form
  );
  const depositRows = getDepositTransactionRows(transactionMetrics);
  const withdrawalRows = getWithdrawalsTransactionRows(transactionMetrics);

  const depositData = {
    columns: CurrencytrasactionAnalysis,
    rows: depositRows,
  };

  const withdrawalData = {
    columns: CurrencytrasactionAnalysis,
    rows: withdrawalRows,
  };
  useEffect(() => {
    const params = {
      StartTimeStamp,
      EndTimeStamp,
      GroupByPeriod,
    };
    const networkParams = {
      StartTimeStamp,
      EndTimeStamp,
      GroupByPeriod,
      CurrencyOrNetwork: 2,
    };
    getWalletsGenerated(params);
    getDepositCurrencyBalance(params);
    getDepositNetworkBalance(networkParams);
    getTransactionMetrics(params);
  }, [filterMode]);

  useEffect(() => {
    dispatch(clearFilterForm());
    dispatch(filter(true));
  }, []);

  return (
    <div>
      <ScrollToTop />

      <SectionContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <SectionTitle>Transaction</SectionTitle>
          {/* <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: " 0 20px",
            }}
          >
            <SqaureFilledbutton
              text="filter"
              icon="/assets/icons/Table/filter.svg"
              handleClick={() => dispatch(toggleFilterChartBar(true))}
            />
          </div> */}
        </div>

        <div
          style={{
            display: "flex",
            gap: "20px",
            marginBottom: "20px",
            width: "50%",
          }}
        >
          <SmallSummaryCard
            title="Deposit"
            loading={gettingTransactionMetrics}
            vol={totalDepositVolumeInUSD}
            count={transactionMetrics?.deposits?.count}
            currency="$"
          />
          <SmallSummaryCard
            title="Withdrawal"
            loading={gettingTransactionMetrics}
            vol={totalWithdrawalVolumeInUSD}
            count={transactionMetrics?.withdrawals?.count}
            currency="$"
          />
        </div>
        <FlexContainer style={{ marginBottom: "20px", height: "auto" }}>
          <CustomTable
            data={depositData}
            title="Deposits"
            loading={gettingTransactionMetrics}
            noRecordText="No deposit records at the moment"
          />
        </FlexContainer>
        <FlexContainer style={{ marginBottom: "20px", height: "auto" }}>
          <CustomTable
            data={withdrawalData}
            title="Withdrawals"
            loading={gettingTransactionMetrics}
            noRecordText="No Withdrawal records at the moment"
          />
        </FlexContainer>

        <FlexContainer>
          <LeftContent>
            <CurrencyChart />
          </LeftContent>
          <RightContent>
            <NetworkChart />
          </RightContent>
        </FlexContainer>
        <Filteration />
      </SectionContainer>
    </div>
  );
};

export default Transaction;
