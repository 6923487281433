import React from "react";
import SettledStallsTable from "../Tables/SettledStallsTable";

const StallSettlements = () => {
  return (
    <div>
      <SettledStallsTable />
    </div>
  );
};

export default StallSettlements;
