import styled from "styled-components";
import { Link } from "react-router-dom";
import { media } from "../../respnsive";
import { theme } from "../../theme";

// sidebars styles
interface SidebarProp {
  toggle: boolean;
}

export const SideBarContainer = styled.div<SidebarProp>`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100vh;
  flex: 0.2;
  background: #fff;
  box-shadow: 10px 0px 9px rgba(32, 66, 184, 0.1);
  backdrop-filter: blur(10px);
  z-index: 100;
  overflow-y: auto;
  overflow-x: hidden;

  ${media.tablet} {
    position: fixed;
    left: ${(props) => (props.toggle ? "0" : "-100%")};
    transition: all 0.5s ease-in-out;
    bottom: 0;
    width: 50%;
    z-index: 100;
  }
`;

export const SidebarHeader = styled.div`
  padding: 40px 20px;

  p {
    color: #6d6d6d;
    font-weight: 700;
    font-size: 15.9375px;
    line-height: 19px;
    margin-left: 30px;
  }

  ${media.tablet} {
    img {
      width: 150px;
    }
  }
`;
export const SidebarNav = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;
export const NavItem = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.29031px;
  color: #a6a6a6;
  text-decoration: none;
  border-radius: 12px;
  width: 250px;
  height: 40px;
  transition: all 0.2s linear;
  margin-left: 30px;
  padding-left: 20px;
  transition: all 0.5s ease-in-out;

  &.active {
    background: #e1e8ff;
    backdrop-filter: blur(2px);
    color: ${theme.colors.primary};

    // &:hover {
    //   background: ${theme.colors.bgGradient};
    // }
  }

  &.active::before {
    content: "";
    position: absolute;
    top: 0;
    left: -30px;
    background: #2042b8;
    border-radius: 12px;
    width: 12px;
    height: 100%;

    ${media.phone} {
      width: 8px;
      left: -15px;
    }
  }

  ${media.phone} {
    width: 160px;
    height: 40px;
    font-size: 14px;
    margin-left: 15px;
    padding-left: 10px;
  }
`;
export const ExternalLink = styled.a`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 20px;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.29031px;
  color: #a6a6a6;
  text-decoration: none;
  border-radius: 12px;
  width: 231px;
  height: 50px;
  transition: all 0.2s linear;
  margin-left: 30px;
  padding-left: 20px;

  &.active {
    background: #e1e8ff;
    backdrop-filter: blur(2px);
    color: #fff;
  }

  &.active::before {
    content: "";
    position: absolute;
    top: 0;
    left: -30px;
    background: #2042b8;
    border-radius: 12px;
    width: 12px;
    height: 100%;

    ${media.phone} {
      width: 8px;
      left: -15px;
    }
  }

  ${media.phone} {
    width: 160px;
    height: 40px;
    font-size: 14px;
    margin-left: 15px;
    padding-left: 10px;
  }
`;

export const SubNavContainer = styled.div`
  width: 100%;
  display: flex;
  // align-items: center;
  justify-content: center;
  gap: 10px;
  flex-direction: column;
  padding-left: 50%;
  padding-top: 10px;

  ${media.phone} {
    padding-left: 30%;
    gap: 5px;
  }
`;
export const SubNavItem = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  letter-spacing: 0.29031px;
  color: rgba(32, 66, 184, 0.65);
  cursor: pointer;
  transition: color 0.5s linear;

  &.active {
    color: #2042b8;
  }

  ${media.phone} {
    width: 160px;
    height: 40px;
    font-size: 14px;
    margin-left: 15px;
    padding-left: 10px;
  }
`;
export const SidebarFooter = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.29031px;
  color: #a6a6a6;
  text-decoration: none;
  border-radius: 12px;
  width: 100%;
  height: 50px;
  cursor: pointer;
  margin-left: 20px;
  padding-left: 30px;

  svg {
    font-size: 20px;
  }

  ${media.phone} {
    font-size: 14px;

    svg {
      font-size: 14px;
    }
  }
`;

// headers

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 4px 4px rgba(32, 66, 184, 0.11);
  position: sticky;
  width: 100%;
  height: 10%;
  top: 0px;
  background: #fefefe;
  z-index: 100;
  padding: 10px;
  border-left: 2px solid rgba(32, 66, 184, 0.11);
  ${media.phone} {
    height: 70px;
  }

  &.view {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  }
`;

export const HeaderTitle = styled.p`
  font-weight: 600;
  font-size: 150%;
  line-height: 43px;
  background: linear-gradient(141.55deg, #ff6600 -3.79%, #2042b8 82.82%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  width: 250px;
  text-transform: capitalize;
  margin-left: 10px;

  ${media.phone} {
    font-size: 24px;
    width: 200px;
  }
`;

export const WelcomeMessage = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.6;
  ${media.phone} {
    flex: 0.6;
  }
`;

export const Username = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 90%;
  line-height: 29px;
  color: #000000;

  ${media.phone} {
    font-size: 14px;
  }
`;
export const Message = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 80%;
  line-height: 21px;
  color: #b0b0b0;
  ${media.phone} {
    font-size: 10px;
    line-height: 10px;
  }
`;

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 50px;

  img {
    cursor: pointer;
  }
  img:last-child {
    display: none;

    ${media.tablet} {
      display: flex;
    }
  }

  ${media.phone} {
    gap: 30px;
  }
`;

// mainnav styles
export const MainNavContainer = styled.div`
  position: sticky;
  top: 10%;
  left: 0;
  display: flex;
  // align-items: center;
  width: 100%;
  padding: 0 10px;
  z-index: 100;
  background: #fff;
  border-left: 2px solid rgba(32, 66, 184, 0.11);
  box-shadow: 0px 4px 4px rgba(32, 66, 184, 0.11);

  ${media.tablet} {
    overflow-x: auto;
    padding: 0 30px;
  }
  ${media.phone} {
    overflow-x: auto;
    padding: 0 10px;
    top: 70px;
  }
`;

export const MainNavItem = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 90%;
  line-height: 23px;
  color: #000000;
  opacity: 0.5;
  padding: 20px;
  cursor: pointer;

  ${media.tablet} {
    font-size: 12px;
    padding: 10px;
  }
  ${media.phone} {
    display: flex;
    // align-items: center;
    // justify-content: center;
    font-size: 10px;
  }

  &.active {
    position: relative;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    background: linear-gradient(141.55deg, #ff6600 -3.79%, #2042b8 82.82%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    ${media.tablet} {
      font-size: 12px;
    }
    ${media.phone} {
      font-size: 10px;
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 7px;
      left: 0;
      bottom: 0;
      background: linear-gradient(141.55deg, #ff6600 -3.79%, #2042b8 82.82%);

      ${media.tablet} {
        height: 5px;
      }
      ${media.phone} {
        height: 2px;
      }
    }
  }
`;
